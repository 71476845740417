import React from 'react'

import MainOfferDir from '../../Components/MainOffer/MainOfferDir'

import FormDirector from '../../Components/FormDirector/FormDirector'
import Kickstart from '../../Components/Kickstart/Kickstart'
import Sponsors from '../../Components/Sponsors/Sponsors'
import Actors from '../../Components/Actors/Actors'
import MoreInfo from '../../Components/MoreInfo/MoreInfo'

import CastingInfoRight from '../../Components/СastingInfoRight/CastingInfoRight'
import CastingInfoLeft from '../../Components/СastingInfoLeft/CastingInfoLeft'
import CastingInfoFirst from '../../Components/СastingInfoFirst/CastingInfoFirst'
// import PopupDemo from '../../Components/PopupDemo/PopupDemo'
import './CastingDirector.css'

function CastingDirector() {

  // const [isOpen, setIsOpen] = useState(false);
  // const handleOpenModal = () => {
  //   setIsOpen(true);
  // }

  // const handleCloseModal = () => {
  //   setIsOpen(false);
  // }

  let width = window.innerWidth;

  return (
    <div>

      <MainOfferDir/>
      <CastingInfoFirst/>
      <CastingInfoLeft
        title="Get Suggestions"
        number="2"
        text="CastingForm technical algorithm will choose actors meeting your criteria. We will send the PDF selection to your email."
      />
      <CastingInfoRight
        title="Make your Choice"
        number="3"
        text="Choose the most fitting candidates and let us know. CastingForm personal manager will contact them and request selftapes."
      />
      <CastingInfoLeft
        title="Receive Selftapes"
        number="4"
        text="Watch the selftape videos and give us your feedback. Perhaps the actor you chose needs to shoot another take - let us know. If everything is great, it's time for the final step."
      />
      <CastingInfoRight
        title="Make an offer"
        number="5"
        text="Check the selftapes. Make an offer to the candidates who meet your requirements."
      />
      <MoreInfo />
      <Sponsors background>
        {width > 1024 ? <>
        <div className="work__wood_top"/>
        <div className="work__wood_bottom"/>
        </> : null}
      </Sponsors>
      <Actors />
      <Sponsors >
        {width > 1024 ? <>
        <div className="sponsors__wood_top"/>
        <div className="sponsors__wood_bottom"/>
        </> : null}
      </Sponsors>
      <Kickstart >
        {width > 1024 ? <>
        <div className="kickstart__wood_top"/>
        <div className="kickstart__wood_bottom-left"/>
        <div className="kickstart__wood_bottom-right"/>
        </> : null}
      </Kickstart>
      <FormDirector />
      {/* <PopupDemo isOpen={isOpen} onClose={handleCloseModal} /> */}

    </div>
  )
}

export default CastingDirector
