const heightConverter = (
  value,          // string
  targetMeasure,  // cm | inch
) => {
  if (targetMeasure === 'cm') {
    const inchRegex = /^(\d{1,2})[\']?((\d)|([0-1][0-2]))?[\"]?$/g;
    const inchesResult = inchRegex.exec(value);
    const feet = inchesResult[1];
    const inches = inchesResult[2];
    const height = Math.round(((parseInt(feet) || 0) * 12 + (parseInt(inches) || 0)) / 0.39370);

    return height.toString();
  } else {
    const convertedValue = (value * 0.39370 / 12).toString().split('.');
    const feets = convertedValue[0];
    const preparedInches = convertedValue[1].slice(0, 1) + '.' + convertedValue[1].slice(2);
    const inches = Math.round(Number(preparedInches));

    return `${feets}'${inches}"`;
  }
};

export default heightConverter;