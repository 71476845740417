import React, {useCallback, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import loadingGif from '../../../../images/landing-loading.gif';
import {nftApi} from '../../../../utils/NftApi';
import ReactPlayer from 'react-player';
import logoCasting from '../../../../images/castingform-logo.svg';
import imgNftFrameLine from '../../../../images/nft-frame-line.svg';
import imgNftFrameRoundedLine from '../../../../images/nft-frame-roundline.svg';
import imgNftLights from '../../../../images/nft-lights.png';


const formatDate = (date) => {
  const pad = n => n.toString().padStart(2, '0');
  const d = new Date(date);
  return d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate());
}

const formatUrl = (userInfo) => {
  const urlString = userInfo.actor.photos?.[0]?.url ?? '';
  return urlString.startsWith('http') ? urlString : `http://${urlString}`;
}

const parseUserInfo = userInfo => {
  return {
    'original_photo_url': formatUrl(userInfo),
    'other_data': '',
    'imdb': '',// TODO
    'date_of_birth': formatDate(userInfo.actor.dob),
    'height_unit': 'sm',
    'occupation': 'Actor',
    'special_needs': userInfo.actor.disability ? [userInfo.actor.disability] : [],
    // 'citizenship': userInfo.actor.citizenships.join(','), TODO
    'citizenship': userInfo.actor.mainLocation.country,
    // 'work_permit': userInfo.actor.workingAuthorization.join(','), TODO
    'work_permit': 'European Union',// TODO
    'first_name': userInfo.actor.firstName,
    'last_name': userInfo.actor.lastName,
    'height_value': userInfo.actor.height.toString(),
    'gender': userInfo.actor.gender,
    'country': userInfo.actor.mainLocation.country,
    'state': userInfo.actor.mainLocation.state,
    'city': userInfo.actor.mainLocation.city,
    'instagram': userInfo.actor.socials.instagram,
    'facebook': userInfo.actor.socials.facebook,
  }
}

const NftGenerator = ({ userInfo, changeIsNftLayout }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [nft, setNft] = useState(null);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const onNftResponse = response => {
    if (response.nft_preview) {
      setNft(response);
      setIsLoading(false);
      changeIsNftLayout(true);
    } else {
      setTimeout(() => {
        fetchNft();
      }, 10000);
    }
  }

  const fetchNft = () => {
    nftApi.getNftByUserId(userInfo.id)
      .then((response) => {
        onNftResponse(response);
      })
      .catch((error) => {
        console.error(error);
        nftApi.setNftByUser(userInfo.id, parseUserInfo(userInfo)).then((response) => {
          setNft(response);
          onNftResponse(response);
        }).catch(error => {
          console.error(error);
        });
      })
  }

  useEffect(() => {
    fetchNft();
  }, []);

  const onShareClick = useCallback(() => {
    window.navigator.clipboard
      .writeText(nft.nft_preview)
      .then(() => {
        setIsLinkCopied(true);
        setTimeout(() => {
          setIsLinkCopied(false);
        }, 5000);
      })
      .catch(error => {
        console.error('error', error);
      })
  }, [nft]);

  if (isLoading) {
    return (
      <GeneratingContainer>
        <Loading src={loadingGif} alt='loading...' />
        <GeneratingTitle>
          Generating NFT
        </GeneratingTitle>
        <GeneratingDescription>
          Wait a little bit...
        </GeneratingDescription>
        <GeneratingCancelLink to='/'>
          I’ll do it later
        </GeneratingCancelLink>
      </GeneratingContainer>
    )
  } else {
    let media;
    if (nft.nft_media_url) {
      media = (<NftCardMedia url={nft.nft_media_url}
                                loop={true}
                                playing={true}
                                controls={false}
                                volume={0}
                                muted={true}
                                width={250}
                                height={370}
      />);
    } else {
      const actorName = `${nft.occupation} ${nft.first_name} ${nft.last_name}`;
      media =(
        <NFTCardImage>
          <img src={nft.nft_preview} alt={actorName} className="imageFullWidth"/>
        </NFTCardImage>
      );
    }
    return (
      <NftBackground backgroundImage={nft.nft_background_url}>
        <NftCard>
          <NftFrame backgroundImage={nft.nft_frame_url}/>
          {media}
          <NftFrameLine />
          <NftFrameRoundedLine />
          <NftFrameRoundedLineRotated />
          <NftLights />
          <NftLightsRotated />
          <NftCardTitle>
            {userInfo.actor.firstName}
          </NftCardTitle>
          <NftCardType>
            Actor
          </NftCardType>
          <NftCardFooter>
            <NftUserId>#{userInfo.id}</NftUserId>
            <Logo src={logoCasting} alt='CastingForm' />
          </NftCardFooter>
        </NftCard>
        <Footer>
          <ShareButton onClick={onShareClick}>
            Share NFT’s preview
            {isLinkCopied &&
              <Hint>Link copied to clipboard</Hint>
            }
          </ShareButton>
          <OpenseaButton href={nft.nft_opensea_url} target='_blank'>
            Open NFT at Open Sea
          </OpenseaButton>
          <FooterDescription>
            Be available in 24 hours
          </FooterDescription>
        </Footer>
      </NftBackground>
    )
  }
}

export default NftGenerator;

const GeneratingContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GeneratingTitle = styled.h3`
  font-family: 'Druk-Wide';
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
`;
const GeneratingDescription = styled.span`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #888888;
`;
const GeneratingCancelLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  padding: 12px 24px;
  width: 140px;
  height: 44px;
  left: 1089px;
  top: 541px;
  background: #1C1C1E;
  border-radius: 12px;
  margin-top: 21px;
  cursor: pointer;
  text-decoration: none;
  transition: .2s;

  &&:hover {
    background: #fff;
    color: #1C1C1E;
  }
`;

const NftBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 875px;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: ${({backgroundImage}) => `url(${backgroundImage})`};
    background-repeat: no-repeat;
    background-position: center calc(50% - 38px);
    background-size: 590px 900px;
    position: absolute;
    z-index: 1;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 50%;
    left: 0;
    top: 50%;
    background: #360486;
    z-index: 0;
    position: absolute;
  }
`;
const Footer = styled.div`
  margin-top: 119px;
  position: relative;
  z-index: 1;
`;
const ShareButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 14px 75px;
  width: 336px;
  height: 52px;
  border-radius: 12px;
  color: #FFFFFF;
  background: #C2A56D;
  border: 1px solid #C2A56D;
  transition: .2s;
  cursor: pointer;
  position: relative;

  &&:hover {
    background: #FFFFFF;
    border-color: #FFFFFF;
    color: #1C1C1E;
  }
`;
const Hint = styled.div`
  position: absolute;
  display: block;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.345098);
  cursor: default;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 0.4px;
  height: 42px;
  line-height: 42px;
  z-index: 99;
  background: #FFFFFF;
  border-color: #FFFFFF;
  color: #1C1C1E;
  font-weight: 700;
  font-size: 16px;
  border-radius: 9px;
  bottom: calc(100% + 24px);
  width: 234px;
`;

const OpenseaButton = styled.a`
  padding: 14px 75px;
  width: 336px;
  height: 52px;
  background: #1C1C1E;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #888888;
  margin-top: 12px;
  border: 1px solid #1C1C1E;
  transition: .2s;
  cursor: pointer;
  text-decoration: none;

  &&:hover {
    background: #fff;
    border-color: #FFFFFF;
    color: #1C1C1E;
  }
`;
const FooterDescription = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 11px;
`;
const Logo = styled.img`
  width: 94px;
`;
const NftCard = styled.div`
  padding: 36px 0 0;
  backdrop-filter: blur(10px);
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-width: 228px;
  height: 342px;
  position: relative;
  z-index: 1;
`;
const NftFrameLine = styled.div`
  position: absolute;
  width: 163px;
  height: 206px;
  left: 50%;
  transform: translateX(-50%);
  top: 26px;
  background: url(${imgNftFrameLine}) no-repeat center;
  background-size: 163px 206px;
`;

const NftFrameRoundedLine = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(38deg);
  top: 26px;
  background: url(${imgNftFrameRoundedLine}) no-repeat center;
  width: 161px;
  height: 205px;
  background-size: 161px 205px;
`;

const NftFrameRoundedLineRotated = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 26px;
  background: url(${imgNftFrameRoundedLine}) no-repeat center;
  width: 161px;
  height: 205px;
  background-size: 161px 205px;
`;

const NftLights = styled.div`
  position: absolute;
  background: url(${imgNftLights}) no-repeat center;
  left: 16px;
  top: 6px;
  width: 77px;
  height: 83px;
  background-size: 77px 83px;
}
`;

const NftLightsRotated = styled.div`
  position: absolute;
  background: url(${imgNftLights}) no-repeat center;
  width: 77px;
  height: 83px;
  background-size: 77px 83px;
  left: 140px;
  top: 161px;
  transform: rotate(135deg);
`;

const NftFrame = styled.div`
  background-image: ${({backgroundImage}) => `url(${backgroundImage})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  left: -39px;
  top: -228px;
  width: 306px;
  height: 0;
  padding-bottom: 910px;
}
`;

const NftCardTitle = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: center;
  font-family: 'Druk-Wide';
  padding: 21px 24px 0;
`;
const NftCardType = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  text-align: center;
  padding: 0 24px;

`;
const NftCardMedia = styled(ReactPlayer)`
  border-radius: 16px;
  position: relative;
  margin: 0 auto;
  max-width: 130px;
  max-height: 183px;
  overflow: hidden;
`;
const NFTCardImage = styled.div`
  border-radius: 16px;
  position: relative;
  margin: 0 auto;
  max-width: 130px;
  max-height: 183px;
  overflow: hidden;

  .imageFullWidth {
    width: 100%;
  }
`;
const NftCardFooter = styled.div`
  display: flex;
  flex-display: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 26px;
  opacity: 0.6;
  padding: 0 24px 20px;

  && span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
  }
`;

const NftUserId = styled.span`
  overflow: hidden;
  max-width: 66px;
  text-overflow: ellipsis;
`;

const Loading = styled.img`
  width: 260px;
  margin-bottom: 50px;
`;
