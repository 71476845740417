import styled from 'styled-components';

export const FooterBlock = styled.footer`
    margin-top: 3em;
`;
export const Navigation = styled.nav``;
export const Block = styled.div``;

export const FooterSection = styled.div`
    border-top: 1px solid hsl(0, 0%, 37%);
`;

export const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Container = styled.div`
    width: 40%;
    display: flex;
    justify-content: space-between;
`;

export const List = styled.ul`
    margin: 0;
    padding: 0;

    list-style: none;
`;

export const ListTitle = styled.h3`
    margin: 0;
    margin-bottom: 20px;
    font-family: 'Formular';
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
`;

export const ListItem = styled.li`
    margin-bottom: 16px;
    color: hsl(0, 0%, 53%);
`;

export const LinkStyled = styled.a`
    font-family: 'Formular';
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: hsl(0, 0%, 53%);
`;

export const FlexContainerCentr = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
`;

export const FlexContainerBottom = styled.div`
    display: flex;
    justify-content:space-between;
    padding-top: 40px;
    padding-bottom: 40px;
`;

export const LogoBlock = styled.div`
    position: relative;
    width: 12.125em;
    height: 1em;
`;

export const BlockBottomLeft = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 32px;
`;

export const Text = styled.p`
    margin: 0;
    font-family: 'Formular';

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: hsl(0, 0%, 53%);
`;

export const LinkWithIconStyled = styled.a`
    font-family: 'Formular';

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: hsl(0, 0%, 53%);
`;

export const Socials = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    align-self: flex-end;
    gap: 24px;

    list-style: none;
`
export const SocialItem = styled.li``;

export const SocialLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    background-color: hsl(240, 3%, 11%);

    border-radius: 12px;
`;

export const InscribedBlock = styled.div`
  max-width: 74em;
  margin: 0 auto;
`;
