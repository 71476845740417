import React from 'react';
// import background from '../../../../../images/slider/star.png';
import cdavatar from '../../../../../images/slider/CDavatar.png';
import cannesLogo from '../../../../../images/slider/cannesLogo.png';
import varietyLogo from '../../../../../images/slider/variety.png'
import {
  SlideCardWrapper,
  SlideCard,
} from './styles';
import styled from 'styled-components';

const CastingSlide = () => {
  return (
    <SlideCardWrapper>
      <FlexSlideCard>
        <SlideAvatarImage src={cdavatar}/>
        <SlideHeader>
          <SlideHeading>Carol Dudley</SlideHeading>
          <SlideCaption>President, CSA European Chapter Board Of Governors, Casting Society Of America</SlideCaption>
        </SlideHeader>
        <SlideCannesLogo src={cannesLogo}/>
        <SlideReview>
          “We deal with actors who deserve a shot at international success but have neither agents nor ways of meeting them. They are not connected, not networked, CSA European Chapter Board of Governors President Carol Dudley said. “Even if everyone recognizes them on the street in their own country, they fly to the US and they can’t get an appointment with an agent or a casting director. I think things will progress slowly, but what you’re providing is filling an absolute hole that you’ve recognized in the marketplace, where you don’t have a conduit from the talent to the casting people, and the US in general,” she said of CastingForm.
        </SlideReview>
        <SlideVarietyLogo src={varietyLogo}/>
      </FlexSlideCard>
    </SlideCardWrapper>
  )
};

export default CastingSlide;

const FlexSlideCard = styled(SlideCard)`
  display: flex;
  flex-wrap: wrap;
`;

const SlideAvatarImage = styled.img`
  width: 84px;
  height: 84px;
  margin: 24px 16px 22px 24px;
`;

const SlideHeader = styled.div`
  max-width: 245px
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 34px 94px 0 0;
`;

  const SlideHeading = styled.div`
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    font-family: 'Druk-Wide';
  `;

  const SlideCaption = styled.div`
    max-width: 245px;
    color: hsla(0, 0%, 53%, 1);
    font-size: 12px;
    line-height: 14px;
  `;

const SlideCannesLogo = styled.img`
  width: 91px;
  height: 59px;
  margin-top: 34px;
`;

const SlideReview = styled.div`
  max-width: 530px;
  max-height: 153px;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  margin-left: 24px;
`;

const SlideVarietyLogo = styled.img`
  width: 47px;
  height: 13px;
  margin: 16px 0 26px 24px;
`;
