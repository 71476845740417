import React from 'react'
import './Headshots.css'

import { Swiper, SwiperSlide } from "swiper/react";
import "../MainOffer/MySwiper.css";
import 'swiper/css';

import SwiperCore, {
  EffectCoverflow,Pagination
} from 'swiper';

SwiperCore.use([EffectCoverflow,Pagination]);

function Headshots() {
  let width = window.innerWidth;
  return (
    <section className='headshots'>

      <div className='headshots_content'>

      {width > 1024 ?
      <>
      <div className="headshots__print_big_top"/>
      <div className="headshots__print_small_top"/>
      </> : null}

        <div className='headshots__info'>
          <p className='headshots__info_number'>1.</p>
          <h2 className='headshots__info_heading'>Headshots</h2>
          <p className='headshots__info_caption'>
          Add 1 to 5 headshots. Photos should be natural-looking, colored, well-lit.
          You should face the camera.
          </p>
        </div>

        <div className='headshots__photos'>
        <Swiper effect={'coverflow'}
          spaceBetween={width > 1024 ? 250 : 0}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={'2'}
          coverflowEffect={width > 1024 ?
          {
            "rotate": 0,
            "stretch": 87.5,
            "depth": 190,
            "modifier": 1,
            "slideShadows": true
          } :
          {
            "rotate": 0,
            "stretch": 44.5,
            "depth": 390,
            "modifier": 1,
            "slideShadows": true
          }}
          pagination={false}
          initialSlide={"2"}
        >
            <SwiperSlide className='swiper-slide_headshots'>
              <div className='headshots__photo headshots__photo_1'></div>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide_headshots'>
              <div className='headshots__photo headshots__photo_2'></div>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide_headshots'>
              <div className='headshots__photo headshots__photo_3'></div>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide_headshots'>
              <div className='headshots__photo headshots__photo_4'></div>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide_headshots'>
              <div className='headshots__photo headshots__photo_5'></div>
            </SwiperSlide>
          </Swiper>
        </div>

        {width > 1024 ?
        <>
        <div className="headshots__print_big_bottom"/>
        <div className="headshots__print_small_bottom"/>
        </> : null}

      </div>
    </section>
  )
}

export default Headshots
