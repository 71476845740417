import React, { useState, useRef } from 'react';
// import { useHistory, useLocation } from 'react-router-dom';
import { api } from '../../utils/Api';

import './Steps.css';
import './Plug.css';
import Header from '../../Components/HeaderReg/HeaderReg';

import RegSlide1 from '../../images/registration/reg3.png';
import RegSlide2 from '../../images/registration/reg4.png';



import { Swiper, SwiperSlide } from "swiper/react";
import '../../Components/TableTile/TableTileSwiper.css';
import 'swiper/css';
import SwiperCore, {Navigation} from 'swiper';
SwiperCore.use([Navigation]);



function PostBreakdown({registration, currentUser, onSignOut, setModalBreakdownActive}) {

  // const history = useHistory();
  // const location = useLocation();

  const form = useRef(null);

  const [files, setFiles] = useState([]);

  const text = useRef();
  const roleName = useRef();


  const handleUpload = (event)=> {
    setFiles(event.target.files);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = form.current;


    api.postDirectorForm(data)
    .then(() => {
      setFiles([]);
    })
    .catch((err) => console.log(err))
  }


  function autoClosePopupBreakdown() {
    setModalBreakdownActive(false)
  }
  setTimeout(autoClosePopupBreakdown, 2500)

  return (
    <div className='steps'>
      <div className='steps__main'>
        <Header/>
        <div className='steps__main_slider'>
        <Swiper
              spaceBetween={16}
              slidesPerView={'auto'}
              direction={'horizontal'}
              speed={1900}
              freeMode={true}
              centeredSlides={false}
              navigation={true}
              autoplay={{
                "delay": 3000,
              }}
              className='steps_swiper'
            >
              <SwiperSlide style={{backgroundColor: '#0D0D0E'}}>
                <img src={RegSlide1} alt={RegSlide1} className='steps_slide_img'/>
              </SwiperSlide>
              <SwiperSlide style={{backgroundColor: '#0D0D0E'}}>
                <img src={RegSlide2} alt={RegSlide2} className='steps_slide_img'/>
              </SwiperSlide>
          </Swiper>
        </div>
      </div>


      <div className='steps__choose_occupation'>

        <div className='steps__choose_occupation_block'>
        <button onClick={onSignOut} className='breakdown-page_sign-out_btn' type="button">Sign out</button>
          <p className='steps__choose_occupation_heading'>Congratulation</p>

          <p className='steps__plug_info_caption'>
            Your CastingForm has been successfully created.
          </p>

          <p className='steps__plug_info_caption'>
            Once the CastingForm destop version is released you will be immediately notified
            at {currentUser?.email || registration?.email}
          </p>

          <p className='steps__plug_info_caption' style={{margin: '0'}}>
            Please share your breakdown and we will get back to you with our suggestions as soon as possible.
            Use the CastingFom mobile app to see actors available for selftape.
          </p>

          <form ref={form} onSubmit={handleSubmit}>
          <p className='steps__choose_occupation_heading'>Post a Breakdown</p>
          <input name="name" id="name" type="text" value={`${registration?.firstName} ${registration?.lastName}` || `${currentUser?.filmMaker.firstName} ${currentUser?.filmMaker.lastName}`} style={{opacity: '0', visibility: 'hidden'}} />
          <input name="email" id="email" type="text" value={`${registration?.email}` || `${currentUser?.email}`} style={{opacity: '0', visibility: 'hidden'}} />
          <div className='steps__input_section'>
            <span className='steps__input_title'>Role name<sup style={{color: 'red'}}>*</sup></span>

            <input
              className='steps__input'

              name="roleName"
              ref={roleName}
            ></input>
          </div>

          <div className='steps__input_section'>
            <span className='steps__input_title'>Character Description</span>
            <textarea
              type="text"
              row="6"
              className='steps__textarea'
              ref={text}
              name="characterDescription"

            ></textarea>
          </div>

          <div className='steps__input_section'>
            <span className='steps__input_title'>Upload pdf<sup style={{color: 'red'}}>*</sup></span>
            <label htmlFor="files" className="plug-page_file_label">
              { files && files[0] && <span type="button" className="plug-page_file_button">
              <p style={{margin: '0 auto 0 10px'}} className='plug-page_file_name-caption'>{files[0].name}</p>

              </span> }
              { files && files[1] && <span type="button" className="plug-page_file_button">
              <p style={{margin: '0 auto 0 10px'}} className='plug-page_file_name-caption'>{files[1].name}</p>

              </span> }
              { files && files[2] && <span type="button" className="plug-page_file_button">
              <p style={{margin: '0 auto 0 10px'}} className='plug-page_file_name-caption'>{files[2].name}</p>

              </span> }
              <span className="plug-page__button_create"/>
            </label>
            <input
              accept='.pdf, .zip, .rar'
              type="file" name="files" id="files" className="plug-page_input_file"
              multiple onChange={handleUpload}
            />
          </div>


          <div className='steps__button_block'>
            <button disabled={roleName === '' || files.length < 1} type='submit' className='steps__choose_occupation_submit' style={{backgroundColor: '#D0A653', margin: '0 0 0 auto'}}>
              {/* <Link to="/registration/plug" className="steps__link" > */}
                Send
              {/* </Link> */}
            </button>
          </div>
          </form>



        </div>
      </div>

    </div>
  )
}
export default PostBreakdown
