import React, { useState, useEffect } from 'react';
import { useHistory, useLocation  } from 'react-router-dom';

import './Steps.css';
import './Plug.css';
import Header from '../../Components/HeaderReg/HeaderReg';

// import iconDelete from '../../images/icons/trash.svg'

import RegSlide1 from '../../images/registration/reg3.png';
import RegSlide2 from '../../images/registration/reg4.png';



import { Swiper, SwiperSlide } from "swiper/react";
import '../../Components/TableTile/TableTileSwiper.css';
import 'swiper/css';
import SwiperCore, {Navigation} from 'swiper';
SwiperCore.use([Navigation]);

function PlugPage({registration, currentUser, onSignOut}) {

  const [role, setRole] = useState('')

  const history = useHistory();
  const location = useLocation()

  function postBreakdown() {
    history.push("/registration/breakdown");
  }

  function sendForm() {
    history.push("/send-form");
  }

useEffect(() => {
  console.log(currentUser)
  if (currentUser) {
    currentUser.roles[0] === 'FilmMaker' ? setRole('filmmaker') : currentUser.roles[0] === 'Actor' ?
    setRole('actor') :  currentUser.roles[0] === 'TalentRep' ? setRole('talentrep') : setRole('')
  }
}, [currentUser])


  return (
    <div className='steps'>
      <div className='steps__main'>
        <Header/>
        <div className='steps__main_slider'>
        <Swiper
              spaceBetween={16}
              slidesPerView={'auto'}
              direction={'horizontal'}
              speed={1900}
              freeMode={true}
              centeredSlides={false}
              navigation={true}
              autoplay={{
                "delay": 3000,
              }}
              className='steps_swiper'
            >
              <SwiperSlide style={{backgroundColor: '#0D0D0E'}}>
                <img src={RegSlide1} alt={RegSlide1} className='steps_slide_img'/>
              </SwiperSlide>
              <SwiperSlide style={{backgroundColor: '#0D0D0E'}}>
                <img src={RegSlide2} alt={RegSlide2} className='steps_slide_img'/>
              </SwiperSlide>
          </Swiper>
        </div>
      </div>


      { location.pathname === '/registration/plug' ? <div className='steps__choose_occupation'>

        <div className='steps__choose_occupation_block'>

        <button onClick={onSignOut} className={registration.Occupation === 'Filmmaker' ? 'plug-page_sign-out_btn_filmmaker' : 'plug-page_sign-out_btn'} type="button">Sign out</button>


          <p className='steps__choose_occupation_heading'>Congratulation</p>

          <p className='steps__plug_info_caption'>
            Your CastingForm has been successfully created.
          </p>

          <p className='steps__plug_info_caption'>
            Once the CastingForm destop version is released you will be immediately notified
            at {registration.email}
          </p>

          {registration.Occupation === 'Filmmaker'  ?
          <p className='steps__plug_info_caption'>
            Please share your breakdown and we will get back to you with our suggestions as soon as possible.
            Use the CastingFom mobile app to see actors available for selftape.
          </p>
          :
          registration.Occupation === 'Talentrep' ?
          <p className='steps__plug_info_caption'>
            With CastingForm you don’t need to submit your actors for roles. Once your actors have got a selftape request you will be notified immediately through the CastingForm mobile app. Download it now.
          </p>
          :
          <p className='steps__plug_info_caption'>
            With CastingForm you don’t need to submit for roles. Once you get a self tape request you will be notified immediately through the CastingForm mobile app. Download it now.
          </p> }

          {registration.Occupation === 'Filmmaker' ?
          <button className='plug-page__share_brakedown_btn' type="button" onClick={postBreakdown}>Post a Breakdown</button>
          : null}

          <div className='steps__plug_mobile_ios'>
            <div className='steps__plug_mobile_ios_phone'/>
            <div className='plug-page_download_links'>
              <div className='plug-page_qr'/>
              <a href="http://onelink.to/CastingForm" className='steps__plug_mobile_android_link'>
                <div className='steps__plug_mobile_android_link_btn'/>
              </a>
              <a href="http://onelink.to/CastingForm" className='steps__plug_mobile_ios_link'>
                <div className='steps__plug_mobile_ios_link_btn'/>
              </a>
            </div>
          </div>

        </div>

      </div> :
      location.pathname === '/welcome' && currentUser ?


      <div className='steps__choose_occupation'>

        <div className='steps__choose_occupation_block'>

        <button onClick={onSignOut} className={role === 'filmmaker' ? 'plug-page_sign-out_btn_filmmaker' : 'plug-page_sign-out_btn'} type="button">Sign out</button>


          <p className='steps__choose_occupation_heading'>Congratulation</p>

          <p className='steps__plug_info_caption'>
            Your CastingForm has been successfully created.
          </p>

          <p className='steps__plug_info_caption'>
            Once the CastingForm destop version is released you will be immediately notified
            at {currentUser.email}
          </p>

          {role === 'filmmaker'  ?
          <p className='steps__plug_info_caption'>
            Please share your breakdown and we will get back to you with our suggestions as soon as possible.
            Use the CastingFom mobile app to see actors available for selftape.
          </p>
          :
          role === 'talentrep' ?
          <p className='steps__plug_info_caption'>
            With CastingForm you don’t need to submit your actors for roles. Once your actors have got a selftape request you will be notified immediately through the CastingForm mobile app. Download it now.
          </p>
          :
          <p className='steps__plug_info_caption'>
            With CastingForm you don’t need to submit for roles. Once you get a self tape request you will be notified immediately through the CastingForm mobile app. Download it now.
          </p> }

          {role === 'filmmaker' ?
          <button className='plug-page__share_brakedown_btn' type="button" onClick={sendForm}>Post a Breakdown</button>
          : <div>pppppp</div>}

          <div className='steps__plug_mobile_ios'>
            <div className='steps__plug_mobile_ios_phone'/>
            <div className='plug-page_download_links'>
              <div className='plug-page_qr'/>
              <a href="http://onelink.to/CastingForm" className='steps__plug_mobile_android_link'>
                <div className='steps__plug_mobile_android_link_btn'/>
              </a>
              <a href="http://onelink.to/CastingForm" className='steps__plug_mobile_ios_link'>
                <div className='steps__plug_mobile_ios_link_btn'/>
              </a>
            </div>
          </div>

        </div>

      </div>
      : <></>


      }

    </div>
  )
}
export default PlugPage
