import React from 'react';
import { Link } from 'react-router-dom';

import logoCasting from '../../images/castingform-logo.svg';
import emailIcon from '../../images/icons/email-icon.svg';
import whatsupIcon from '../../images/icons/whatsup.svg';
import googleImage from './img/app-google.png';
import appleImage from './img/app-apple.png';
import qrImage from './img/qr.png';
import woodImage from './img/wood.png';
import mobilePoster from './posters/mobilePoster2x.png';
import tabletPoster from './posters/tabletPoster2x.png';
import desktopPoster from './posters/desktopPoster2x.png';

import styled from 'styled-components';

// const speakerList = [
//   { name: 'Raf Green', description: 'an Emmy-nominated Producer and Writer' },
//   { name: 'Carol Dudley', description: 'President, CSA European Chapter' },
//   { name: 'Dasha Timbush', description: 'Founder, CastingForm' },
//   { name: 'Zachary Shadrin', description: 'Actor, Ambassador, CastingForm' },
// ];

const CannesPoster = () => {
  return (
    <Container>
      <Header>
        <LogoBlock>
          <Link to="/">
            <Logo src={logoCasting} alt="CastingForm" />
          </Link>
        </LogoBlock>
        <ContactBlock>
          <ContactLink href="https://wa.me/message/XFF4NDK6T677B1">
            <ContactImage src={whatsupIcon} />
          </ContactLink>
          <ContactLink href="mailto:cannes@castingform.com?body=Hello, I would like to confirm my attendance at the following event. %0D%0AName and Last Name: %0D%0ACompany: %0D%0AJob Title: %0D%0APhone: %0D%0AEmail: %0D%0AThe Future Of CastingForm And Talent Representation by CastingForm %0D%0AWhere: Marché Du Film, International Village, The UK Pavilion %0D%0AWhen: Wednesday, May 25, 2022. 14:00&subject=RSVP: CASTINGFORM, The Future Of CastingForm And Talent Representation" target="_blank">
            <ContactImage src={emailIcon} />
          </ContactLink>
        </ContactBlock>
      </Header>
      <Poster>
        <PosterImageWrapper>
          <MobilePosterImage src={mobilePoster} />
          <TabletPosterImage src={tabletPoster} />
          <DesktopPosterImage src={desktopPoster} />
        </PosterImageWrapper>
      </Poster>
      <Content>
        <Title>
          The Future Of Casting<br /> And Talent Representation
        </Title>
        <Description>
          CastingForm is Back To Cannes!<br />
          Join this panel presented by CastingForm to hear, see, and experience how technology is shaping the future of talent representation and casting to empower equal opportunities for global actors in the age of Web3.
        </Description>
        <SpeakersBlock>

        <SpeakerItem>
            <b>Raf Green, </b><CustomBr /><SpeakerDescription><StringPart>an </StringPart>Emmy-nominated Producer and Writer</SpeakerDescription>
          </SpeakerItem>
          <SpeakerItem>
            <b>Carol Dudley, </b><CustomBr /><SpeakerDescription>President, CSA European Chapter</SpeakerDescription>
          </SpeakerItem>
          <SpeakerItem>
            <b>Dasha Timbush, </b><CustomBr /><SpeakerDescription>Founder, CastingForm</SpeakerDescription>
          </SpeakerItem>
          <SpeakerItem>
            <b>Zachary Shadrin, </b><CustomBr /><SpeakerDescription>Actor, Ambassador, CastingForm</SpeakerDescription>
          </SpeakerItem>
        </SpeakersBlock>

        <Place>
          Marché Du Film<br />
          International Village<br />
          The UK Pavilion<br />
          <b>Wednesday, May 25, 14:00</b>
        </Place>
        <Notification>
          Space is limited. Please scan the code to RSVP<br />
          or confirm your attendance at cannes@castingform.com
        </Notification>
        <AppBlock>
          <StoreBlock>
            <ContactLink href="http://onelink.to/CastingForm" target="_blank">
              <CommonImage src={appleImage} />
            </ContactLink>
            <ContactLink href="http://onelink.to/CastingForm" target="_blank">
              <CommonImage src={googleImage} />
            </ContactLink>
          </StoreBlock>
          <ContactLink href="mailto:cannes@castingform.com?body=Hello, I would like to confirm my attendance at the following event. %0D%0AName and Last Name: %0D%0ACompany: %0D%0AJob Title: %0D%0APhone: %0D%0AEmail: %0D%0AThe Future Of CastingForm And Talent Representation by CastingForm %0D%0AWhere: Marché Du Film, International Village, The UK Pavilion %0D%0AWhen: Wednesday, May 25, 2022. 14:00&subject=RSVP: CASTINGFORM, The Future Of CastingForm And Talent Representation" target="_blank">
            <CommonImage src={qrImage} />
          </ContactLink>
        </AppBlock>
        <BackgroundContentImage src={woodImage} />
      </Content>
    </Container>
  )
};

export default CannesPoster;

const Container = styled.main`
  display: grid;
  margin: 0 auto;
  min-height: 100vh;

  grid-template-columns: 1fr;
  grid-template-rows: 92px auto auto;
  grid-template-areas:
    "header"
    "poster"
    "content";

  align-items: stretch;
  color: #fff;

  @media (min-width: 800px) {
    width: 800px;
    grid-template-columns: 1fr 400px;
    grid-template-rows: 92px auto;
    grid-template-areas:
      "header header"
      "poster content";
  };
  @media (min-width: 1024px) {
    width: 1024px;
  };
  @media (min-width: 1100px) {
    width: 100%;
    max-width: 1200px;
  };
`;
const Header = styled.header`
  grid-area: header;
  display: flex;
  height: 92px;
  justify-content: space-between;
  align-items: center;

  border-bottom: solid 1px #211F1F;
`;

const LogoBlock = styled.div`
  padding-left: 32px;

  @media (min-width: 1100px) {
    padding: 64px;
  };
`;
const Logo = styled.img``;
const ContactBlock = styled.div`
  display: flex;
  padding-right: 32px;
  justify-content: flex-end;

  @media (min-width: 800px) {
    width: 400px;
  };
`;
const ContactLink = styled.a``;
const ContactImage = styled.img`
  height: 20px;
  margin-left: 16px;
`;

const Poster = styled.section`
  grid-area: poster;
  overflow: hidden;
`;
const PosterImageWrapper = styled.div`
  margin: 0 auto;
  width: 320px;
  @media (min-width: 425px) {
    width: 387px;
  };
  @media (min-width: 1024px) {
    width: 637px;
  };
  @media (min-width: 1100px) {
    width: 680px;
  };
`;

const MobilePosterImage = styled.img`
  display: block;
  width: 320px;
  @media (min-width: 425px) {
    width: 387px;
  };
  @media (min-width: 1024px) {
    display: none;
  };
`;
const TabletPosterImage = styled.img`
  display: none;
  width: 597px;
  @media (min-width: 1024px) {
    display: block;
    margin-top: 4px;
  };
  @media (min-width: 1100px) {
    display: none;
  };
`;
const DesktopPosterImage = styled.img`
  display: none;
  width: 690px;
  @media (min-width: 1100px) {
    display: block;
    margin-top: 25px;
  };
`;

const Content = styled.section`
  position: relative;
  grid-area: content;
  padding: 32px 16px;
  margin: 0 auto;
  width: 352px;
  max-width: 425px;

  @media (min-width: 425px) {
    width: 419px;
  };
  @media (min-width: 800px) {
    width: 100%;
    padding: 38px 16px 38px 16px;
  };

  overflow: hidden;
`;

const BackgroundContentImage = styled.img`
  position: absolute;
  right: -16px;
  top: 275px;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  font-family: 'Druk-Wide';
`;
const Description = styled.p`
  margin: 32px 0;
  line-height: 20px;
`;
const SpeakersBlock = styled.div`
  font-size: 14px;
  line-height: 17px;
`;
const SpeakerItem = styled.div``;
const SpeakerDescription = styled.span`
  opacity: 0.6;
`;
const Place = styled.p`
  margin: 42px 0;
  line-height: 20px;
`;
const Notification = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  opacity: 0.6;
`;
const AppBlock = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: start;
  gap: 20px;
`;
const StoreBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
`;
const CommonImage = styled.img``;

const StringPart = styled.span`
  display: none;
  @media (min-width: 426px) {
    display: inline;
  };
`;
const CustomBr = styled.br`
  @media (min-width: 426px) {
    display: none;
   };
 `;
