import React, { useState, useEffect, useCallback } from 'react';
import { Link, useHistory  } from 'react-router-dom';
import { api } from '../../../../../utils/Api';
import { InputComponent } from '../../../components/inputs/Input';
import { StyledPrimaryButton } from '../../../components/buttons';
import { Checkbox } from '../../../components/checkBox';
import {
  Container,
  StepContent,
  StepCounter,
  Caption,
  TitleWithSpace,
  InputWrapper,
  ActionBlock,
  BackLink,
} from '../../../components/commonStyles';
import { StepsCounter } from '../../../components/steps';
import styled from 'styled-components';

const CommonSocialPage = (props) => {
  const { userInfo, setUserInfo, occupation, setUserData } = props;
  const isActor = occupation === 'actor';
  const history = useHistory();

  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [IMDb, setIMDb] = useState('');

  const [instagramValidation, setInstagramValidation] = useState('');
  const [facebookValidation, setFacebookValidation] = useState('');
  const [IMDbValidation, setIMDbValidation] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    api.getCurrentUser()
    .then(() => {
      console.log('active user');
    })
    .catch((error) => {
      console.error(error);
      history.push('/auth/sign-in');
    })
  }, [history]);

  const handleCheckClick = useCallback(() => {
    setIsChecked(!isChecked)
  }, [isChecked]);

  const onInstagramChange = useCallback((e) => {
    setInstagramValidation('');
    setUserInfo({
      socials: {
        instagram: e.target.value,
        facebook,
        IMDb,
      }
    });
    setInstagram(e.target.value);
  }, [facebook, IMDb, setUserInfo]);

  const onFacebookChange = useCallback((e) => {
    setFacebookValidation('');
    setUserInfo({
      socials: {
        instagram,
        facebook: e.target.value,
        IMDb,
      }
    });
    setFacebook(e.target.value);
  }, [instagram, IMDb, setUserInfo]);

  const onIMDbChange = useCallback((e) => {
    setIMDbValidation('');
    setUserInfo({
      socials: {
        instagram,
        facebook,
        IMDb: e.target.value,
      }
    });
    setIMDb(e.target.value);
  }, [instagram, facebook, setUserInfo]);

  const checkInstagram = useCallback((instagram) => {
    if ((!instagram.includes('instagram') && instagram[0] !== '@') && instagram !== '') setInstagramValidation('Incorrect instagram link');
  }, []);

  const checkFacebook = useCallback((facebook) => {
    if (!facebook.includes('facebook') && facebook !== '') setFacebookValidation('Incorrect facebook link');
  }, []);

  const checkIMDb = useCallback((IMDb) => {
    if (!IMDb.includes('imdb') && IMDb !== '') setIMDbValidation('Incorrect IMDb link');
  }, []);

  const onRedirect = useCallback((instagram, facebook, IMDb) => {
    if (isActor) {
      history.push(`/auth/sign-up/actor/headshot`);
    } else {
      setUserData(occupation);
    }
  }, [isActor, occupation, history, setUserData]);

  return (
    <Container>
      <StepContent>
        <Caption>Sign Up</Caption>
        <TitleWithSpace>Verify you are a real person</TitleWithSpace>

        <InputWrapper>
          <InputComponent
            label="Instagram"
            value={instagram}
            onChange={onInstagramChange}
            onBlur={() => checkInstagram(instagram)}
            error={instagramValidation}
          />
        </InputWrapper>

        <InputWrapper>
          <InputComponent
            label="Facebook"
            value={facebook}
            onChange={onFacebookChange}
            onBlur={() => checkFacebook(facebook)}
            error={facebookValidation}
          />
        </InputWrapper>

        <InputWrapper>
          <InputComponent
            label="IMDb"
            value={IMDb}
            onChange={onIMDbChange}
            onBlur={() => checkIMDb(IMDb)}
            error={IMDbValidation}
          />
        </InputWrapper>

        {!isActor &&
        <AcceptContainer>
          <Checkbox onChange={handleCheckClick} />
          <Text>I accept <StyledLink to='/terms'>Terms of Service</StyledLink> and <StyledLink to='/privacy'>Privacy Police</StyledLink></Text>
        </AcceptContainer> }

        <ActionBlock>
          <BackLink
            to={ isActor
              ? '/auth/sign-up/actor/based'
              : `/auth/sign-up/${occupation}/information`
            }>
            Back
          </BackLink>
          <StyledPrimaryButton
            disabled={(instagram === '' && facebook === '' && IMDb === '') || instagramValidation || facebookValidation || IMDbValidation || (!isActor && !isChecked) }
            onClick={() => onRedirect(instagram, facebook, IMDb)}
          >
            { isActor ? 'Next' : 'Create' }
          </StyledPrimaryButton>
        </ActionBlock>
      </StepContent>
      <StepCounter>
        <StepsCounter currentStep={isActor ? 5 : 4} length={isActor ? 6 : 4} />
      </StepCounter>
    </Container>
  )
}

export default CommonSocialPage;

const AcceptContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  gap: 10px;
`;
const Text = styled.p`
  margin: 0;

  font-family: 'Formular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: hsl(0, 0%, 53%);
`;

const StyledLink = styled(Link)`
  font-family: 'Formular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({white}) => white ? 'white' : 'hsla(0, 0%, 53%, 1)'};
`;
