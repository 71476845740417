import React from 'react';
import mainImage from '../../../../../images/slider/projects.png';
import ellipseImage from '../../../../../images/slider/ellipse.png';
import { SlideCardWrapper, SlideCard, SlideText } from './styles';
import styled from 'styled-components';

const SudiosSlide = () => {
  return (
    <SlideCardWrapper>
      <SlideCard>
        <SlideText width="250px">
          Global studios looking for you
        </SlideText>
        <TemplateImageWrapper>
          <TemplateImage src={ellipseImage} />
          <TemplateImage src={ellipseImage} />
        </TemplateImageWrapper>
        <MainImage src={mainImage} />
      </SlideCard>
    </SlideCardWrapper>
  )
};

export default SudiosSlide;

const TemplateImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  buttom: 0;
  height: 338px;
  width: 381px;
  overflow: hidden;
`;
const TemplateImage = styled.img`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
`;
const MainImage = styled.img`
  position: absolute;
  top: 0;
  right: 24px;
`;