import React, { useState, useCallback } from 'react';
import { Link, useHistory  } from 'react-router-dom';
import { InputComponent } from '../../components/inputs/Input';
import { api } from '../../../../utils/Api';
import { StyledPrimaryButton } from '../../components/buttons';
import styled from 'styled-components';

const validateEmail = (email) => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

const EmailPage = (props) => {
  const { setCurrentUser } = props;
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const [emailValidation, setEmailValidation] = useState('');
  const [passwordValidation, setPasswordValidation] = useState('');
  const [repeatedPasswordValidation, setRepeatedPasswordValidation] = useState('');

  const checkEmail = useCallback((e) => {
    const email = e.target.value;
    if (!validateEmail(email)) {
      setEmailValidation('Invalid email format.');
      return;
    };
    api.query('auth/check_email', { email })
    .then(response => {
      if (!response) setEmailValidation(`An account with Email ${email} already exists.`);
    });
  }, []);


  const checkPassword = useCallback((pass) => {
    // Your password is not strong enough.
    if (pass.length < 8) setPasswordValidation('The password must be at least 8 characters long.');
  }, []);

  const checkRepeatedPassword = useCallback((password, repPassword) => {
    if (password !== repPassword) {
      setRepeatedPasswordValidation('Passwords do not match.');
      return false;
    } else {
      return true;
    }
  }, []);


  const onEmailChange = useCallback((e) => {
    setEmailValidation('');
    setEmail(e.target.value);
  }, []);

  const onPasswordChange = useCallback((e) => {
    setPasswordValidation('');
    setPassword(e.target.value);
    checkPassword(e.target.value);
  }, [checkPassword]);

  const onRepPasswordChange = useCallback((e) => {
    setRepeatedPasswordValidation('');
    setRepeatedPassword(e.target.value);
  }, []);

  const signup = useCallback((email, password, repeatedPassword) => {
    if (!checkRepeatedPassword(password, repeatedPassword)) return;

    api.signupEmail(email, password)
    .then(res => {
      console.log(res);
      setCurrentUser(res);
      localStorage.setItem('token', res.token);
      history.push('/auth/sign-up/occupation');
    })
  }, [checkRepeatedPassword, setCurrentUser, history]);

  return (
    <Container>
      <Caption>Sign Up</Caption>
      <Header>Welcome to CastingForm</Header>
      <Description>The #1 platform to discover and book actors globally</Description>
      <Title>Enter contact details</Title>

      <InputWrapperWithSpace>
        <InputComponent label="Email" type="email" value={email} placeholder="Your email" onChange={onEmailChange} onBlur={checkEmail} error={emailValidation} />
      </InputWrapperWithSpace>

      <InputWrapper>
        <InputComponent label="Password" type="password" value={password} placeholder="Your password" onChange={onPasswordChange} error={passwordValidation} />
      </InputWrapper>

      <InputWrapper>
        <InputComponent label="Password" type="password" value={repeatedPassword} placeholder="Repeat password" onChange={onRepPasswordChange} error={repeatedPasswordValidation} />
      </InputWrapper>

      <NotificationText>
        We just want to make sure you a real person. We take privacy very seriously and will never share your private data with anyone.
      </NotificationText>
      <ActionBlock>
        <SignUpBlock>
          Already have an account?
          <SignUpLink to="/auth/sign-in">Sign In</SignUpLink>
        </SignUpBlock>
        <StyledPrimaryButton
          disabled={email === '' || password === '' || repeatedPassword === '' || !!emailValidation || !!passwordValidation || !!repeatedPasswordValidation }
          onClick={() => signup(email, password, repeatedPassword)}
        >
          Next
        </StyledPrimaryButton>
      </ActionBlock>
    </Container>
  )
}

export default EmailPage;

const Container = styled.div`

`;

const Caption = styled.p`
  margin-bottom: 8px;
  line-height: 1;
  font-size: 14px;
  color: #787878;
`;
const Header = styled.h2`
  line-height: 36px;
  font-size: 26px;
  font-family: 'Druk-Wide';
  margin-bottom: 8px;
`;
const Description = styled.p`
  margin-bottom: 40px;
  line-height: 1;
  font-size: 16px;
  opacity: 0.9;
`;
const Title = styled.h2`
  margin-bottom: 20px;
  line-height: 36px;
  font-size: 20px;
  font-family: 'Druk-Wide';
`;
const InputWrapper = styled.div`
  margin-bottom: 8px;
`;
const InputWrapperWithSpace = styled(InputWrapper)`
  margin-bottom: 20px;
`;
const NotificationText = styled.p`
  display: block;
  width: 300px;
  margin: 0 auto 40px;
  line-height: 18px;
  font-size: 12px;
  text-align: center;
  color: hsla(0, 0%, 47%, 1);
`;
const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SignUpBlock = styled.label`
  display: inline-block;
  font-size: 14px;
  color: #787878;
`;
const SignUpLink = styled(Link)`
  margin-left: 8px;
  text-decoration: none;
  color: #9A5DE8;
`;
