import React from 'react';
import { useHistory } from 'react-router-dom';
import './HeaderReg.css';

import logoCasting from '../../images/castingform-logo.svg';
import emailIcon from '../../images/icons/email-icon.svg';
import whatsupIcon from '../../images/icons/whatsup.svg';

function Header() {

  let history = useHistory();

  function handleGoToStartPage() {
    history.push("/");
  }

  return (
    <header className="document__header">
        <img className="document__logo" src={logoCasting} alt="casting logo" onClick={handleGoToStartPage}/>
        <ul className="document__list">
          <li className="document__item">
            <a className="document__link document__link_header" href="https://wa.me/message/XFF4NDK6T677B1">
              <img src={whatsupIcon} alt="icon" className="document__icon document__icon_header" />
              {/* <span>+1 424 270 4906</span> */}
            </a>
          </li>
          <li className="document__item">
            <a className="document__link document__link_header" href="mailto:call@castingform.com">
              <img src={emailIcon} alt="icon" className="document__icon document__icon_header"/>
              {/* call@castingform.com */}
            </a>
          </li>
        </ul>
    </header>
  )
}

export default Header
