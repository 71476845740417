import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputBase } from '@mui/material';
import heightConverter from './heightConverter';

export const HeightInput = ({
  onChange, // (value: string) => void
  error,    // string | undefined
}) => {
  const [value, setValue] = useState('');
  const [isInchMeasure, setIsInchMeasure] = useState(true);
  
  const handleOnChange = useCallback((event) => {
    const newValue = event.target.value;
  
    const inchRegex = /^(\d{1,2})[\']?((\d)|([0-1][0-2]))?[\"]?$/g;
    const cmRegex = /^\d+$/;
    const inchesResult = inchRegex.exec(newValue);
    const cmResult = cmRegex.exec(newValue);

    if (newValue === '') {
      setValue(newValue);
      onChange && onChange(newValue);
    } else if (
      (isInchMeasure && inchesResult) ||
      (!isInchMeasure && cmResult)
    ) {
      const feet = inchesResult[1];
      const inches = inchesResult[2];

      const height = (((parseInt(feet) || 0) * 12 + (parseInt(inches) || 0)) / 0.393701).toString();

      setValue(newValue);
      onChange && onChange(isInchMeasure ? height : newValue);
    }
  }, [onChange, isInchMeasure]);

  const setCmMeasure = useCallback(() => {
    setIsInchMeasure(false);
  }, []);

  const setInchMeasure = useCallback(() => {
    setIsInchMeasure(true);
  }, []);

  useEffect(() => {
    if (!value) return;
    setValue(
      heightConverter(value, isInchMeasure ? 'inch' : 'cm')
    );
  }, [isInchMeasure]);

  return (
    <Wrapper>
      <Label>Height</Label>
      
      <InputStyled
        value={value}
        error={error}
        onChange={handleOnChange}
        endAdornment={(
          <Measure>
            <CmMeasure active={!isInchMeasure} onClick={setCmMeasure}>CM</CmMeasure>
            <InchMeasure active={isInchMeasure} onClick={setInchMeasure}>INCH</InchMeasure>
          </Measure>
        )}
      />

      <Error>{error}</Error>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  font-family: 'Formular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: hsla(0, 0%, 100%, 1);
  padding-bottom: 8px;
`;

const InputStyled = styled(InputBase)`
  &.MuiInputBase-root {
    height: 40px;
    width: 100%;
    padding: 10px 12px;
    border-radius: 8px;
    font-size: 14px;
    color: hsla(0, 0%, 83%, 1);
    background-color: hsla(240, 3%, 11%, 1);
    ${({error}) => error ? 'border: 1px solid hsla(0, 83%, 55%, 1);': ''}
  }
  &.Mui-disabled {
    color: hsla(0, 0%, 53%, 1);
  }
`;

const Measure = styled.div`
  cursor: auto;
`;

const CmMeasure = styled.span`
  color: ${({ active }) => !active && 'hsla(0, 0%, 53%, 1)'};
  cursor: pointer;
`;

const InchMeasure = styled(CmMeasure)`
  margin-left: 12px;
`;

const Error = styled.span`
  display: block;
  margin-top: 4px;
  width: 100%;
  height: 17px;
  line-height: 17px;
  font-size: 14px;
  color: hsla(0, 83%, 55%, 1);
`;