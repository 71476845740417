import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { createTheme, TextField, useMediaQuery, ThemeProvider } from '@mui/material';
import Calendar from '../../../../images/icons/calendar.png';

export const DateInput = ({
  value,    // string
  onChange, // (value: string) => void
  error,    // string | undefined
}) => {
  const [localValue, setLocalValue] = useState(new Date());
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = createTheme({
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
    },
  });

  const handleChange = (value) => {
    setLocalValue(value);
    onChange(value);
  }

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Wrapper>
      <Label>Date Of Birth</Label>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
      >
        <ThemeProvider theme={theme}>
          <DatePicker
            value={localValue}
            inputFormat="MM/dd/yyyy"
            renderInput={(params) => <InputStyled {...params} />}
            components={{
              OpenPickerIcon: CalendarIcon,
              
            }}
            onChange={handleChange}
          />
        </ThemeProvider>
      </LocalizationProvider>

      <Error>{error}</Error>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const Label = styled.label`
  font-family: 'Formular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: hsla(0, 0%, 100%, 1);
  padding-bottom: 8px;
`;

const InputStyled = styled(TextField)`
  && {
    .MuiInputBase-root {
      height: 40px;
      width: 100%;
      border-radius: 8px;
      font-size: 14px;
      color: hsl(0, 0%, 83%);
      background-color: hsl(240, 3%, 11%);
      ${({error}) => error ? 'border: 1px solid hsl(0, 83%, 55%);': ''}

      .MuiInputBase-input {
        width: calc(100% - 24px);
        border: none;
      }
    }

    .Mui-disabled {
      color: hsl(0, 0%, 53%);
    }
  }
`;

const CalendarIcon = styled.span`
  width: 16px;
  height: 16px;
  background-image: url(${Calendar});
`;

const Error = styled.span`
  display: block;
  margin-top: 4px;
  width: 100%;
  height: 17px;
  line-height: 17px;
  font-size: 14px;
  color: hsl(0, 83%, 55%);
`;