import React from 'react'
import './CastingInfoRight.css'

function CastingInfoRight({title, number, text}) {
  let width = window.innerWidth;
  return (
    <section className='casting-info_right'>
      <div className='casting-info_right__content'>
      {width > 1024 ? <div className="casting-info_right__print_big_top"/> : null}
        <div className={number === '3' ? 'casting-info_right__photo': 'casting-info_right__photo_5'}/>

        <div className={number === '3' ? 'casting-info_right__info' : 'casting-info_right__info_5'}>
          <p className='casting-info_right__info_number'>{number}.</p>
          <h2 className='casting-info_right__info_heading'>{title}</h2>
          <p className='casting-info_right__info_caption'>
            {text}
          </p>
        </div>
        {width > 1024 ? <div className={number === '3' ? "casting-info_right__print_small_bottom" : 'casting-info_right__print_small_bottom_5'}/> : null}
      </div>

    </section>
  )
}

export default CastingInfoRight
