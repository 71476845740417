import React from 'react';
import './Movies.css';


import Movie1 from '../../images/movies/hunter.png';
import Movie11 from '../../images/movies/radioactive.png';
import Movie111 from '../../images/movies/picasso.png';

import Movie2 from '../../images/movies/little_america.png';
import Movie22 from '../../images/movies/bodyguard.png';
import Movie222 from '../../images/movies/totem.png';
import Movie2222 from '../../images/movies/industry.png';
import Movie22222 from '../../images/movies/when.png';

import Movie3 from '../../images/movies/crown.png';
import Movie33 from '../../images/movies/mercenary.png';
import Movie333 from '../../images/movies/berlin.png';
import Movie3333 from '../../images/movies/the_chapter.jpeg';

import { Swiper, SwiperSlide } from "swiper/react";
import "../MainOffer/MySwiper.css";
import 'swiper/css';

import SwiperCore, {
  Autoplay,Navigation,FreeMode
} from 'swiper';
SwiperCore.use([Autoplay,Navigation,FreeMode]);

function Movies() {
  let width = window.innerWidth;
  return (
    <div className='movies'>
      <p className='movies__heading'>Global productions using our service</p>
      <div className='movies__sliders'>

          <Swiper
            spaceBetween={30}
            slidesPerView={'auto'}
            direction={'vertical'}
            speed={7000}
            freeMode={true}
            centeredSlides={false}
            autoplay={{
              "delay": 2500,
              "disableOnInteraction": false,
            }}
            //on/off slider buttons
            navigation={false}
            className='movies__slider'
          >
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie1} alt={Movie1}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie11} alt={Movie11}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie111} alt={Movie111}/>
            </SwiperSlide>

            {width < 1024 ?
            <>
              <SwiperSlide>
                <img className='movies__slider_img' src={Movie3} alt={Movie3}/>
              </SwiperSlide>
              <SwiperSlide>
                <img className='movies__slider_img' src={Movie33} alt={Movie33}/>
              </SwiperSlide>
            </> : null}
          </Swiper>

          <Swiper
            spaceBetween={30}
            slidesPerView={'auto'}
            direction={'vertical'}
            speed={6000}
            freeMode={true}
            centeredSlides={false}
            autoplay={{
              "delay": 2500,
              "disableOnInteraction": false,
              "reverseDirection": true,
            }}
            //on/off slider buttons
            navigation={false}
            className='movies__slider'
          >
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie2} alt={Movie2}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie22} alt={Movie22}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie222} alt={Movie222}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie2222} alt={Movie2222}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie22222} alt={Movie22222}/>
            </SwiperSlide>
            {width < 1024 ?
              <SwiperSlide>
                <img className='movies__slider_img' src={Movie333} alt={Movie333}/>
              </SwiperSlide>
             : null}
          </Swiper>
          {width > 1024 ?
          <Swiper
            spaceBetween={30}
            slidesPerView={'auto'}
            direction={'vertical'}
            speed={7000}
            freeMode={true}
            centeredSlides={false}
            autoplay={{
              "delay": 2500,
              "disableOnInteraction": false,
            }}
            //on/off slider buttons
            navigation={false}
            className='movies__slider'
          >
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie3} alt={Movie3}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie33} alt={Movie33}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie333} alt={Movie333}/>
            </SwiperSlide>
            <SwiperSlide>
              <img className='movies__slider_img' src={Movie3333} alt={Movie3333}/>
            </SwiperSlide>
          </Swiper>
          : null }
      </div>
    </div>
  )
}

export default Movies

