import React, { useRef, useState } from 'react';

import './FormDirector.css';
import iconSend from '../../images/castDir/icon-send.svg'
import { api } from '../../utils/Api';


const FormDirector = () => {
  const [answer, setAnswer] = useState(false);
  const [error, setError] = useState(false);
  const [isRequest, setIsRequest] = useState(false);

  const form = useRef(null);

  const name = useRef();
  const email = useRef();
  const text = useRef();

  const [files, setFiles] = useState([]);

  const handleUpload = (event)=> {
    setFiles(event.target.files);

  }

  const handleSubmit = async(e) => {
    setIsRequest(true)
    e.preventDefault();
    const data = form.current;
    console.log('data', data)

    try{
      await api.postDirectorForm(data)

      name.current.value = '';
      email.current.value = '';
      text.current.value = '';
      setFiles([]);
      setAnswer(true);
      setError(false);
      setIsRequest(false)
    } catch(err){
      console.log(err);
      setAnswer(false);
      setError(true);
      setIsRequest(false)
    }
  }

  let width = window.innerWidth;

  return (

    <section className="section" id="sharebreakdown">
      <div className="section__container">
        <h2 className="section__title">Looking for actors? Tell us about your casting needs and we’ll get back to you in less than 24 hours with our suggestions.</h2>
        <form ref={form} name="director-form" id="form" className="section__form" onSubmit={handleSubmit}>
          <ul className="section__list">
            <li className="section__item">
              <label htmlFor="name" className="section__label">Your name:</label>
              <input type="text" name="name" id="name" className="section__input" ref={name} required />
            </li>
            <li className="section__item">
              <label htmlFor="email" className="section__label">Your email:</label>
              <input type="email" ref={email} name="email" id="email" className="section__input" required />
            </li>
            <li className="section__item">
              <div className="section__flex-container">
                <label htmlFor="text" className="section__label section__label_area">Feel free to share instructions and any comments below:</label>
                <span className="section__span">Max. 160</span>
              </div>

              <textarea type="text" name="text" row="6" id="text" maxLength={160} className="section__input section__input_area" ref={text}  />
            </li>
          </ul>

            <label htmlFor="files" className="section__label section__label_area"><span type="button" className="section__button">Upload PDF   <img alt="icon" className="icon_send" src={iconSend}/></span> </label>
            <input  accept='.pdf, .zip, .rar' type="file" name="files" id="files" className="section__input_file" multiple onChange={handleUpload} />
            {files && files[0] && <p className="section__file">{files[0].name}</p>}
            {files && files[1] && <p className="section__file">{files[1].name}</p>}
            {files && files[2] && <p className="section__file">{files[2].name}</p>}
            {files && files[3] && <p className="section__file">{files[3].name}</p>}
            {files && files[4] && <p className="section__file">{files[4].name}</p>}
            <button type="submit" disabled={isRequest} className={`section__submit ${isRequest && 'section__submit_block'}`}>Share Breakdown</button>
            {answer
              ?
                <span className="section__answer">Success!</span>

              : error

              ?

                <span className="section__error">Sorry! Something went wrong, try again later !</span>
              : <></>

            }
        </form>
        {width >1024 ? <>
        <div className="section__wood_right"/>
        <div className="section__wood_left"/>
        </> : null}
      </div>

    </section>
  )
}

export default FormDirector;





