export const genderValues = [
  {
    title: 'Male',
    value: 'Male'
  },
  {
    title: 'Female',
    value: 'Female'
  },
  {
    title: 'Female to Male transgender',
    value: 'Transgender'
  },
  {
    title: 'Male to Female transgender',
    value: 'Male to Female Transgender'
  },
  {
    title: 'Gender Nonconforming',
    value: 'Gender Nonconforming'
  },
  {
    title: 'LGBTQ+',
    value: 'LGBTQ+'
  },
  {
    title: 'Other',
    value: 'Other/Non-binary'
  },
]
