import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Popup from '../Popup/Popup';
import image from '../../images/kickstart-image.jpg';
import whereImage from '../../images/where/where_kick.jpg'
import appleIcon from '../../images/apple-store-icon.png';
import googleIcon from '../../images/google-store-icon.png';
import directorImage from '../../images/castDir/director_kickstart.jpg'

import './Kickstart.css';

const Kickstart = ({children,  onOpen=null}) => {
  const location = useLocation();
  let width = window.innerWidth;

  const [modalActive, setModalActive] = useState(false);
  return (

      <section className="kickstart">
        <div className="container">
          {children}
          <img
            src={location.pathname === '/wheretostart' ? whereImage :
                 location.pathname === '/castingdirector' ? directorImage : image}
            alt="Actor illustration"
            className="kickstart__image"
          />
          <div className="kickstart__column">
            <h2 className="kickstart__title">{location.pathname === '/wheretostart' ? "CastingForm Premium" : (location.pathname === '/castingdirector' || location.pathname === '/castingdirector/#form-director') ? "Switch to mobile app" : "Kickstart your career"}</h2>
            {location.pathname === '/castingdirector'
              ?
                <p className="kickstart__text_casting">Desktop version is coming soon. Please download the app on your mobile device to sign up and sign in.</p>
              :
                <ul className="kickstart__list">
                  <li className="kickstart__item">
                    <span className="kickstart__text">Personal manager</span>
                  </li>
                  <li className="kickstart__item">
                    <span className="kickstart__text">Worldwide profile exposure</span>
                  </li>
                  <li className="kickstart__item">
                    <span className="kickstart__text">Selftape feedback</span>
                  </li>
                  <li className="kickstart__item">
                    <span className="kickstart__text">Training sessions</span>
                  </li>
                </ul>
            }

            {location.pathname === '/castingdirector' &&
            <a href='http://onelink.to/castingform' className='main-offer__button_type_join_link' >
              <button className="kickstart__button_type_join kickstart__button_type_gold">Join</button>
            </a>
            }

            {location.pathname === '/wheretostart'
              ?
              <a href='http://onelink.to/castingform' className='main-offer__button_type_join_link' >
                <button className="kickstart__button_type_join kickstart__button_type_premium">Premium</button>
              </a>
              : location.pathname === '/castingdirector'
              ? <button  className="kickstart__button_type_join kickstart__button_type_join_request" onClick={onOpen}>Request demo</button>
              :
              <a href='https://whatis.castingform.com/auth/sign-up/email' className='main-offer__button_type_join_link' >
                <button className="kickstart__button_type_join">Join</button>
              </a>
            }
            <ul className="kickstart__list-links">
              <li className="kickstart__item-link">
                <a href='http://onelink.to/castingform' className='main-offer__button_type_join_link'>
                  <button className="kickstart__link">
                    <img src={appleIcon} alt="" className="kickstart__icon kickstart__icon_apple" />
                  </button>
                </a>
              </li>
              <li className="kickstart__item-link">
                <a href='http://onelink.to/castingform' className='main-offer__button_type_join_link'>
                  <button className="kickstart__link">
                      <img src={googleIcon} alt="" className="kickstart__icon kickstart__icon_google" />
                  </button>
                </a>
              </li>
            </ul>
            {location.pathname === '/' &&
              <Link to="/wheretostart" className="kickstart__link-text">Where to start?</Link>
            }
          </div>
          <Popup active={modalActive} setActive={setModalActive}/>
          {location.pathname === '/wheretostart' && width > 1024 ? <>
            <div className="kickstart_print_small" />
            <div className="kickstart_print_big" />
          </> : null}
        </div>

      </section>

  )
}

export default Kickstart;
