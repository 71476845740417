import React from 'react';

import './WhatsNext.css';

const WhatsNext = () => {
  return (
    <section className="what">
      <h2 className="what__title">What's next?</h2>
      <ul className="what__list">

        <li className="what__item what__item_content_one">
          <h3 className="list__title">Stay tuned</h3>
          <p className="what__paragraph">
            Check your inbox for casting offers and reply on time. Follow us on Instagram and take part in our challenges. Up your
            chances of being seen by top filmmakers and casting directors.
          </p>
        </li>

        <li className="what__item what__item_content_two">
          <h3 className="list__title">Be active</h3>
          <p className="what__paragraph">
            Act out monologues and art performances of your choice
            on your Instagram. Perfect your skills and tag fellow actors, filmmakers and studios.
          </p>
        </li>

        <li className="what__item what__item_content_three">
          <h3 className="list__title">Get premium when ready</h3>
          <p className="what__paragraph">
            If you are planning to sign up for Premium, prepare your slates and additional materials in advance to use your Premium to the fullest extent.
          </p>
        </li>

      </ul>
    </section>
  )
}

export default WhatsNext;
