import React from 'react';
import { useLocation } from 'react-router-dom';
import './Sponsors.css';
import '../MainOffer/MySwiper.css';

import FestCannes from '../../images/sponsors/cannsfest.svg';
import Variety from '../../images/sponsors/variety.svg';
import Forbes from '../../images/sponsors/forbes.svg';
import Medium from '../../images/sponsors/medium.svg';
import ng from '../../images/castDir/director_ng.svg';
import amazon from '../../images/castDir/director_amazon.svg';
import netflix from '../../images/castDir/director_netflix.svg';
import fox from '../../images/castDir/director_fox.svg';
import bbc from '../../images/sponsors/BBC.svg';
import g10 from '../../images/sponsors/g10.svg';
import gaumont from '../../images/sponsors/gaumont.svg';
import hbo from '../../images/sponsors/HBO.svg';
import hulu from '../../images/sponsors/Hulu.svg';
import lionsgate from '../../images/sponsors/lionsgate.svg';
import millennium from '../../images/sponsors/millennium.svg';
import studiocanal from '../../images/sponsors/Studiocanal.svg';
import summit from '../../images/sponsors/Summit.svg';
import surface from '../../images/sponsors/surface.svg';


import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import SwiperCore, { Navigation, Autoplay, Scrollbar } from 'swiper';

SwiperCore.use([Navigation, Autoplay, Scrollbar]);

function Sponsors({ background=false, children }) {
  let width = window.innerWidth;
  const location = useLocation();
  const className =  (location.pathname === '/castingdirector' || location.pathname === '/castingdirector/#form-director') && background === true ? 'sponsors_bg_top' : (location.pathname === '/castingdirector' || location.pathname === '/castingdirector/#form-director') ? 'sponsors_bg' : ''

  return (
    <div className={`sponsors ${className}`}>

      <div className='sponsors__block'>
        {children}
        <h2 className='sponsors__heading'>{background === true ? "We work with" : "Market talks about CastingForm"}
        {width > 1024 ?
        <div className="actors__buttons">
            <button type="button" className="slider__button slider__button_prev"/>
            <button type="button" className="slider__button slider__button_next"/>
        </div>
        : null }
        </h2>
        {background === true
          ?
            <div className='sponsors__logo'>

              <Swiper
                slidesPerView={width > 1024 ? 4 : 3}
                spaceBetween={width > 1024 ? 1 : 100}
                slidesPerGroup={1}
                loop={true}
                autoplay={{
                  "delay": 2000,
                  "disableOnInteraction": false,
                }}
                speed={1900}
                navigation={
                  {
                    nextEl: '.slider__button_next',
                    prevEl: '.slider__button_prev',
                  }
                }
              >
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_cannes' src={ng} alt={FestCannes}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_variety' src={amazon} alt={Variety}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_forbes' src={netflix} alt={Forbes}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_fox' src={fox} alt={Medium}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_bbc' src={bbc} alt={bbc}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_g10' src={g10} alt={g10}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_gaumont' src={gaumont} alt={gaumont}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_hbo' src={hbo} alt={hbo}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_hulu' src={hulu} alt={hulu}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_lionsgate' src={lionsgate} alt={lionsgate}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_millennium' src={millennium} alt={millennium}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_studiocanal' src={studiocanal} alt={studiocanal}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_summit' src={summit} alt={summit}/>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <img className='sponsors__logo_img_surface' src={surface} alt={surface}/>
                </SwiperSlide>
              </Swiper>
            </div>
          :
              <div className='sponsors__logo'>

              <Swiper
                slidesPerView={width > 1024 ? 4 : 3}
                spaceBetween={width > 1024 ? 1 : 100}
                slidesPerGroup={1}
                loop={true}
                autoplay={{
                  "delay": 2000,
                  "disableOnInteraction": false,
                }}
                speed={1900}
                navigation={
                  {
                    nextEl: '.slider__button_next',
                    prevEl: '.slider__button_prev',
                  }
                }
              >
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <a className='sponsors__logo_item' href="https://player.vimeo.com/video/570962651">
                    <img className='sponsors__logo_img_cannes' src={FestCannes} alt={FestCannes}/>
                  </a>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <a className='sponsors__logo_item' href="https://variety.com/2021/film/news/film-news-in-brief-july-12-1235017538/">
                    <img className='sponsors__logo_img_variety' src={Variety} alt={Variety}/>
                  </a>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <a className='sponsors__logo_item' href="https://www.forbes.ru/forbes-agenda/forbes-agenda/406121-uehavshie-kak-popast-v-gollivud">
                    <img className='sponsors__logo_img_forbes' src={Forbes} alt={Forbes}/>
                  </a>
                </SwiperSlide>
                <SwiperSlide className='sponsors__slide' style={{backgroundColor: 'transparent'}}>
                  <a className='sponsors__logo_item' href="https://player.vimeo.com/video/570962651">
                    <img className='sponsors__logo_img_medium' src={Medium} alt={Medium}/>
                  </a>
                </SwiperSlide>
                </Swiper>
              </div>
      }
      </div>
    </div>
  )
}

export default Sponsors;
