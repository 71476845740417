import React from 'react';

import './PrivacyBlock.css'

const PrivacyBlock = ({title, isUpper=false, children}) => {
  return (
    <div className="privacy">
      <h2 className={`privacy__title ${isUpper && "privacy__title_upper"}`}>{title}</h2>
      {children}
    </div>
  )
}

export default PrivacyBlock;
