import React, { useState, useCallback } from 'react';
import { Route, Switch, Redirect, useRouteMatch  } from 'react-router-dom';
import styled from 'styled-components';
import AuthLayout from './components/layouts/authLayout';
import SignInPage from './sign-in/SignInPage';
import SignUpPage from './sign-up/SignUpPage';
import WelcomePage from './welcome/WelcomePage';


const AuthRootPage = () => {
  const { path } = useRouteMatch();

  const [currentUser, setCurrentUser] = useState(null);
  const [isNftLayout, setIsNftLayout] = useState(false);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    setCurrentUser(null);
  }, []);

  const changeIsNftLayout = useCallback((v) => {
    setIsNftLayout(v);
  }, []);

  return (
    <AuthWrapper>
      <Switch>
        <Route path={`${path}/sign-in`}>
          <SignInPage setCurrentUser={setCurrentUser} logout={logout} />
        </Route>
        <Route path={`${path}/sign-up`}>
          <SignUpPage currentUser={currentUser} setCurrentUser={setCurrentUser} logout={logout} />
        </Route>
        <Route path={`${path}/welcome/:occupation`}>
          <AuthLayout location="welcome" isNftLayout={isNftLayout}>
            <WelcomePage currentUser={currentUser} logout={logout} changeIsNftLayout={changeIsNftLayout} />
          </AuthLayout>
        </Route>
        <Redirect to={`${path}/sign-in`} />
      </Switch>
    </AuthWrapper>
  )
}

export default AuthRootPage;

const AuthWrapper = styled.main`
  height: 100vh;
  //min-height: 630px;
  width: 100vw;
  overflow-x: hidden;

  color: #fff;
  background-color: #000;
`;

