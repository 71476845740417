import React from 'react';
import { useLocation } from 'react-router-dom';
import play from '../../images/castDir/play-circle-fill.png'
import './MoreInfo.css';

import { Swiper, SwiperSlide } from "swiper/react";
import "../MainOffer/MySwiper.css";
import 'swiper/css';

import SwiperCore, {
  Autoplay,Pagination
} from 'swiper';

SwiperCore.use([Autoplay, Pagination]);

const MoreInfo = () => {
  const location = useLocation();
  let width = window.innerWidth;
  const className = location.pathname === '/castingdirector' ? 'videos_bg' : ''
  if (width > 768) {
  return (
    <section className={`videos ${className}`}>
      <div className="videos__container">
        <h2 className="videos__title">{location.pathname === '/castingdirector' ? "Become the jury for our Instagram Challenges" : "Learn from the best"}</h2>
        <p className="videos__paragraph">
          {location.pathname === '/castingdirector'
            ? `To determine the winners of our Acting Challenges on Instagram, we often ask casting experts for advice. Would you like to be
              our expert and have another fun and easy way to discover new talents? We look forward to welcoming you to our expert board!`
            : `To grow as an actor and become one of the best in the industry, you need to learn from the best. We asked top casting and talent
              representation experts for insights and secrets of success - and now they are available to you. Watch our videos, learn from top
              experts and become the best!`
          }
        </p>
            <ul className="videos__list">

              <li className="videos__item">
                <a className="video__link" href="https://www.youtube.com/watch?v=GSl4_K1blxE&ab_channel=CastingForm">
                  <div className="video__container video__container_michael">
                    <div className="video__overlay"/>
                    <p className="video__live">Live</p>
                    <img className="video__play" src={play} alt="button play"/>
                  </div>
                </a>
                <p className="video__paragraph video__paragraph_color">Guests:</p>
                <p className="video__paragraph video__paragraph_type_name">Michael Ovitz and Dasha Timbush</p>
                <p className="video__paragraph video__paragraph_mb">Talking on the future of technology and Talent Representation.</p>
                <p className="video__paragraph video__paragraph_color">Description:</p>
                <p className="video__paragraph">Co-founder of the Creative Artists Agency and former President of The Walt Disney Company Michael Ovitz and CastingForm
                  Founder Dasha Timbush discuss how AI and technologies will change the way talent representation works.
                </p>
              </li>

              <li className="videos__item">
                <a className="video__link" href="https://www.youtube.com/watch?v=9tJvmc_8vsQ&t=3085s&ab_channel=CastingForm">
                  <div className="video__container">
                    <div className="video__overlay"/>
                    <p className="video__live">Live</p>
                    <img className="video__play" src={play} alt="button play"/>
                  </div>
                </a>
                <p className="video__paragraph video__paragraph_color">Guests:</p>
                <p className="video__paragraph video__paragraph_type_name">Luci Lenox and Dasha Timbush</p>
                <p className="video__paragraph video__paragraph_mb">Feedback and insights - what makes a movie great from casting and actor’s perspective?</p>
                <p className="video__paragraph video__paragraph_color">Description:</p>
                <p className="video__paragraph">Leading casting director Luci Lenox who has cast over 100 international projects for HBO, NatGeo, Netflix,
                  Amazon Prime and many more gives her feedback for the top contestants of the challenge and talks about what makes an award-winning movie great
                  from the casting and actor's perspective.
                </p>
              </li>

              <li className="videos__item">
              <a className="video__link" href="https://www.youtube.com/watch?v=9tJvmc_8vsQ&t=3085s&ab_channel=CastingForm">
                  <div className="video__container video__container_raf">
                    <div className="video__overlay"/>
                    <p className="video__live">Live</p>
                    <img className="video__play" src={play} alt="button play"/>
                  </div>
                </a>
                <p className="video__paragraph video__paragraph_color">Guests:</p>
                <p className="video__paragraph video__paragraph_type_name">Raf Green and Dasha Timbush </p>
                <p className="video__paragraph video__paragraph_mb">Hiring actors with zero experience to work with Hollywood stars - impossible or real?</p>
                <p className="video__paragraph video__paragraph_color">Description:</p>
                <p className="video__paragraph">Co-Executive Producer of the Genius: Picasso, an Emmy Award-nominated producer and writer Raf Green will talk about
                  his career - from acting to becoming an outstanding Emmy Award-nominated producer and writer, and share his experience on hiring actors with zero
                  experience for supporting roles to work with Hollywood superstars.
                </p>
              </li>

          </ul>
      </div>
    </section>
  )
} else if (width < 1024 && location.pathname === '/castingdirector'){
  return(
    <section className='video__block_mobile_casting'>

      <div className="video_mobile_info">
        <h3 className="video_mobile_title">Become the jury for our Instagram Challenges</h3>
        <p className="video_mobile_paragraph">
          To determine the winners of our Acting Challenges on Instagram, we often ask casting experts for advice. Would you like to be our expert and have another fun and easy way to discover new talents? We look forward to welcoming you to our expert board!
        </p>
      </div>

      <div className='video_mobile__slider_block_casting'>
      <Swiper
          spaceBetween={32}
          centeredSlides={true}
          slidesPerView={'auto'}
          speed={1000}
          autoplay={{
            "delay": 5500,
            "disableOnInteraction": false
          }}
          pagination={{
            "clickable": true
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <a className="video__link_casting" href="https://www.youtube.com/watch?v=GSl4_K1blxE&ab_channel=CastingForm">
              <div className="video__container">
                <img className="video__play" src={play} alt="button play"/>
              </div>
              <div className='video__info_block'>
                <p className="video__paragraph">Guests:</p>
                <p className="video__paragraph video__paragraph_caption">Luci Lenox and Dasha Timbush</p>
                <p className="video__paragraph video__paragraph_caption">Feedback and insights - what makes a movie great from casting and actor’s perspective?</p>
                <p className="video__paragraph">Description:</p>
                <p className="video__paragraph video__paragraph_caption">Leading casting director Luci Lenox who has cast over 100 international projects for HBO, NatGeo, Netflix,
                  Amazon Prime and many more gives her feedback for the top contestants of the challenge and talks about what makes an award-winning movie great
                  from the casting and actor's perspective.
                </p>
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a className="video__link_casting" href="https://www.youtube.com/watch?v=9tJvmc_8vsQ&t=3085s&ab_channel=CastingForm">
              <div className="video__container video__container_michael">
                <img className="video__play" src={play} alt="button play"/>
              </div>
              <div className='video__info_block'>
                <p className="video__paragraph">Guests:</p>
                <p className="video__paragraph video__paragraph_caption">Michael Ovitz and Dasha Timbush</p>
                <p className="video__paragraph video__paragraph_caption">Talking on the future of technology and Talent Representation.</p>
                <p className="video__paragraph">Description:</p>
                <p className="video__paragraph video__paragraph_caption">Co-founder of the Creative Artists Agency and former President of The Walt Disney Company Michael Ovitz and CastingForm
                  Founder Dasha Timbush discuss how AI and technologies will change the way talent representation works.
                </p>
              </div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a className="video__link_casting" href="https://www.youtube.com/watch?v=9tJvmc_8vsQ&t=3085s&ab_channel=CastingForm">
              <div className="video__container video__container_raf">
                <img className="video__play" src={play} alt="button play"/>
              </div>
              <div className='video__info_block'>
                <p className="video__paragraph">Guests:</p>
                <p className="video__paragraph video__paragraph_caption">Luci Lenox and Dasha Timbush</p>
                <p className="video__paragraph video__paragraph_caption">Feedback and insights - what makes a movie great from casting and actor’s perspective?</p>
                <p className="video__paragraph">Description:</p>
                <p className="video__paragraph video__paragraph_caption">Leading casting director Luci Lenox who has cast over 100 international projects for HBO, NatGeo, Netflix,
                  Amazon Prime and many more gives her feedback for the top contestants of the challenge and talks about what makes an award-winning movie great
                  from the casting and actor's perspective.
                </p>
              </div>
            </a>
          </SwiperSlide>


        </Swiper>

      </div>

    </section>

  )
} else {
  return (
    <section className='video__block_mobile'>

      <div className="video_mobile_info">
        <h3 className="video_mobile_title">See how it works</h3>
        <p className="video_mobile_paragraph">
          Our experts share best practises and give valuable insight into the production industry.
        </p>
        <div className='video_mobile__watch_btn'>Watch</div>
      </div>

      <div className='video_mobile__slider_block'>
      <Swiper
          spaceBetween={32}
          centeredSlides={true}
          slidesPerView={'auto'}
          speed={1000}
          autoplay={{
            "delay": 5500,
            "disableOnInteraction": false
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <a className="video__link" href="https://www.youtube.com/watch?v=GSl4_K1blxE&ab_channel=CastingForm">
              <div className="video__container"></div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a className="video__link" href="https://www.youtube.com/watch?v=9tJvmc_8vsQ&t=3085s&ab_channel=CastingForm">
              <div className="video__container video__container_michael"></div>
            </a>
          </SwiperSlide>

          <SwiperSlide>
            <a className="video__link" href="https://www.youtube.com/watch?v=9tJvmc_8vsQ&t=3085s&ab_channel=CastingForm">
              <div className="video__container video__container_raf"></div>
            </a>
          </SwiperSlide>


        </Swiper>

      </div>

    </section>
  )
}
}

export default MoreInfo;
