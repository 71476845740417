import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';


export const InputPhoneBlock = (props) => {
  const { label, type, value, placeholder, error, disabled, onChange, onBlur } = props;
  return (
    <>
      {!!label && <InputLabel>{label}</InputLabel>}
      <InputPhone type={type} value={value} onChange={onChange} onBlur={onBlur} error={!!error} placeholder={placeholder} disabled={disabled} />
      <Error>{error}</Error>
    </>
  )
};

export const InputLabel = styled.label`
  display: inline-block;
  margin-bottom: 8px;
  line-height: 17px;
  font-size: 14px;
`;

const InputPhone = styled(PhoneInput)`
  height: 40px;
  width: 100%;
  padding: 10px 12px;
  border-radius: 8px;
  font-size: 14px;
  color: hsl(0, 0%, 83%);
  background-color: hsl(240, 3%, 11%);
  ${({error}) => error ? 'border: 1px solid hsl(0, 83%, 55%);': ''}

  .PhoneInputInput {
    color: hsl(0, 0%, 83%);
    background-color: hsl(240, 3%, 11%);

    border: none;
    outline: none;
  }
`;

const Error = styled.span`
  display: block;
  margin-top: 4px;
  width: 100%;
  height: 17px;
  line-height: 17px;
  font-size: 14px;
  color: hsl(0, 83%, 55%);
`;
