import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select as MuiSelect, MenuItem } from '@mui/material';
import ArrowIcon from '../../../../images/icons/Arrow.png';

export const Select = ({
  value,    // string
  label,    // string
  options,  // { title: string, value: string }[]
  onSelect, // (value: string) => void
  error,    // string | undefined
}) => {
  const [localValue, setLocalValue] = useState('');

  const handleOnSelect = useCallback((event) => {
    const newValue = event.target.value;
    setLocalValue(newValue);
    onSelect && onSelect(newValue);
  }, [onSelect]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Wrapper error={error}>
      {label && (
        <Label>{ label }</Label>
      )}
      <SelectStyled
        error={error}
        value={localValue}
        onChange={handleOnSelect}
        IconComponent={SelectIcon}
        MenuProps={{
          MenuListProps: {
            style: {
              color: 'hsla(0, 0%, 100%, 1)',
              backgroundColor: 'hsla(240, 3%, 11%, 1)',
            }
          }
        }}
      >
        {options?.map(option => (
          <MenuItemStyled key={option.value} value={option.value}>{option.title || option.value}</MenuItemStyled>
        ))}
      </SelectStyled>
      <Error>{error}</Error>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .MuiOutlinedInput-notchedOutline {
    border: ${({error}) => error ? '1px solid red' : 'none'};
  }
`;

const Label = styled.label`
  font-family: 'Formular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: hsla(0, 0%, 100%, 1);
  padding-bottom: 8px;
`;

const SelectStyled = styled(MuiSelect)`
  height: 40px;
  background-color: hsla(240, 3%, 11%, 1);

  && {
    border-radius: 8px;
  }

  .MuiSelect-select {
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: hsla(0, 0%, 100%, 1);
  }
`;

const SelectIcon = styled.span`
  width: 20px;
  height: 20px;
  background-image: url(${ArrowIcon});
  margin-top: -2px;
`;

const MenuItemStyled = styled(MenuItem)`
  && {
    font-family: 'Formular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    :hover {
      background-color: hsla(223, 5%, 25%, 1);
    }
  }
`;

const Error = styled.span`
  display: block;
  margin-top: 4px;
  width: 100%;
  height: 17px;
  line-height: 17px;
  font-size: 14px;
  color: hsl(0, 83%, 55%);
`;
