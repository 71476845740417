import React, { useState } from 'react';
import { useHistory  } from 'react-router-dom';
import { StyledPrimaryButton } from '../../components/buttons';
import styled from 'styled-components';

const occupationList = [
  { value: 'filmmaker', title: 'Filmmaker'},
  { value: 'actor', title: 'Actor'},
  { value: 'talentrep', title: 'Talent Rep'},
]

const OccupationPage = (props) => {
  const { changeUserInfo } = props;
  const history = useHistory();

  const [occupation, setOccupation] = useState('');

  const setUserOccupation = () => {
    // changeUserInfo({
    //   occupation: occupation,
    // });
    history.push(`/auth/sign-up/${occupation}/introduce`);
  };
  
  return (
    <Container>
      <Caption>Sign Up</Caption>
      <Header>Welcome to CastingForm</Header>
      <Description>The #1 platform to discover and book actors globally</Description>
      
      <Label>Indicate your occupation</Label>
      <OccupationBlock>
        {occupationList.map(item => (
          <OccupationItem key={item.value} onClick={() => setOccupation(item.value)} isActive={item.value === occupation}>
            {item.title}
          </OccupationItem>
        ))}
      </OccupationBlock>

      <ActionBlock>
        <div></div>
        <StyledPrimaryButton
          disabled={occupation === '' }
          onClick={setUserOccupation}
        >
          Next
        </StyledPrimaryButton>
      </ActionBlock>
    </Container>
  )
}

export default OccupationPage;

const Container = styled.div`

`;

const Caption = styled.p`
  margin-bottom: 8px;
  line-height: 1;
  font-size: 14px;
  color: #787878;
`;
const Header = styled.h2`
  line-height: 36px;
  font-size: 26px;
  font-family: 'Druk-Wide';
  margin-bottom: 8px;
`;
const Description = styled.p`
  margin-bottom: 40px;
  line-height: 1;
  font-size: 16px;
  opacity: 0.9;
`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 16px;
  font-size: 14px;
`;
const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OccupationBlock = styled.div`

`;
const OccupationItem = styled.div`
  display: flex;
  padding: 0 28px;
  margin-bottom: 20px;
  height: 78px;
  width: 100%;
  align-items: center;
  font-family: 'Druk-Wide';
  font-size: 16px;
  border: none;
  border-radius: 12px;
  background-color: hsla(240, 3%, 11%, 1);
  cursor: pointer;

  ${({isActive}) => isActive ? 'border: 1px solid #9A5DE8;' : ''}
`;