import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './ActorInfo.css';

import '../../Components/MainOffer/MySwiper.css';
import { api } from '../../utils/Api';
import { getIdString } from '../../utils/utils'

import logo from '../../images/logo.svg'

import { Swiper, SwiperSlide } from "swiper/react";
import '../../Components/TableTile/TableTileSwiper.css';
import 'swiper/css';

import SwiperCore, { Navigation, Autoplay, Scrollbar, Pagination } from 'swiper';
SwiperCore.use([Navigation, Autoplay, Scrollbar, Pagination]);

function ActorInfo() {

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [user, setUser] = useState({});

  const location = useLocation();


  React.useEffect(() => {

    api.getUserById(location.search.slice(6))
    .then(res => {
      console.log(res);
      setUser(res);
    })
    .catch(() => setIsError(true))
    .finally(() => setIsLoading(false))
  }, [location.search])

  return (<>
    {isLoading ?  <div style={{"minHeight":"60vh"}}>Loading....</div>
    : isError ? <div> Error...</div>
    :
      <section className='actor-info'>

        <div className='actor-info__block'>

          <div className='actor-info__slider'>
            { user.photos && user.photos.length > 0 && user.photos[0] !== null && <button type="button" className="slider__button_ActInfo slider__button_prev_ActInfo"/>}
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              slidesPerGroup={1}
              loop={true}
              speed={900}
              pagination={{
                "clickable": true
              }}
              navigation={
                {
                  nextEl: '.slider__button_next_ActInfo',
                  prevEl: '.slider__button_prev_ActInfo',
                }
              }
            >
              {user.photos && user.photos.length > 0 && user.photos[0] !== null && user.photos.map(item => (item !== null &&
                <SwiperSlide className='swiper-slide_actors' key={item.id}>
                  <img src={item.url ? item.url : item.link} alt="app-illustration" style={{"width":"418px", "height":"742px", "borderRadius": "24px", "objectFit": "cover"}}/>
                </SwiperSlide>
              ))}


            </Swiper>
            {user.photos.length > 0 && user.photos[0] !== null && <button type="button" className="slider__button_ActInfo slider__button_next_ActInfo"/>}
          </div>

          <div className='actor-info__data'>
            <img src={logo} alt="CastingForm logotype" className="actor-info__logo"/>
            <div className='actor-info__data_heading'>
              <h2 className='actor-info__data_name'>{user.firstName}</h2>
              <div className='actor-info__data_verification'/>
              <div className='actor-info__data_id'>#id{getIdString(user.shortId)}</div>
            </div>
            <div className='actor-info__data_main'>
              <div className='actor-info__data_main_left'>
                <p className='actor-info__data_main_heading'>Country</p>
                <p className='actor-info__data_main_caption'>{user.location}</p>
                <p className='actor-info__data_main_heading'>Languages</p>
                <p className='actor-info__data_main_caption'>{user.languages && user.languages.length > 0 && user.languages.slice(0,2).join(', ')}</p>
                <p className='actor-info__data_main_heading'>Citizenship</p>
                <p className='actor-info__data_main_caption'>{user.citizenship}</p>
                <p className='actor-info__data_main_heading'>Height</p>
                <p className='actor-info__data_main_caption'>{user.height}</p>
              </div>
              <div className='actor-info__data_main_right'>
                <p className='actor-info__data_main_heading'>City</p>
                <p className='actor-info__data_main_caption'>{user.city}</p>
                <p className='actor-info__data_main_heading'>Accent</p>

                {user.accents && user.accents.length > 0 ? user.accents.map((item, i) => (
                  <p className='actor-info__data_main_caption' key={i}>{item}</p>
                ))
                  : <p className='actor-info__data_main_caption'></p>
                }
                <p className='actor-info__data_main_heading'>Working Authorization</p>
                  {user.availableForWorkAt && user.availableForWorkAt.length > 0 ? user.availableForWorkAt.map((item, i) => (
                    <p className='actor-info__data_main_caption' key={i}>{item}</p>
                  ))
                  : <p className='actor-info__data_main_caption'></p>
                }

              </div>
            </div>
            <a href='https://api.whatsapp.com/message/XFF4NDK6T677B1' className='actor-info__data_btn_type_availability'>Contact Manager</a>
          </div>

        </div>

    </section>

    }
    </>
    )

}

export default ActorInfo
