import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import iphoneImage from '../../../images/registration/ios.png'
import qrImage from '../../../images/slider/qr.png';
import appleImage from '../../../images/slider/apple-app.png';
import googleImage from '../../../images/slider/google-app.png';
import successUpload from '../../../images/successUpload.svg';
import downloadImg from '../../../images/icons/Download.png';
import { api } from '../../../utils/Api';
import {
  Title,
  Description,
  InputWrapperWithSpace,
} from '../components/commonStyles';
import styled from 'styled-components';
import NftGenerator from "../components/nftGenerator/NftGenerator";

const descriptionValue = (occupationValue) => {
  switch (occupationValue) {
    case 'actor' :
      return 'With CastingForm you don’t need to submit for roles. Once you get a self tape request you will be notified immediately through the CastingForm mobile app. Download it now.';
    case 'filmmaker' :
      return 'Please share your breakdown and we will get back to you with our suggestions as soon as possible. Use the CastingFom mobile app to see actors available for selftape.';
    case 'talentrep' :
      return 'With CastingForm you don’t need to submit your actors for roles. Once your actors have got a selftape request you will be notified immediately through the CastingForm mobile app. Download it now.';
    default :
      return '';
  };
}

const WelcomePage = (props) => {
  const { changeIsNftLayout } = props;
  const param = useParams();
  const history = useHistory();
  const form = useRef(null);

  const [isBreakdownOpened, setBreakdownOpened] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const [fileName, setFileName] = useState('');
  const [isClaimedNft, setIsClaimedNft] = useState(false);

  useEffect(() => {
    api.getCurrentUser()
    .then((response) => {
      setUserInfo(response);
    })
    .catch((error) => {
      console.error(error);
      history.push('/auth/sign-in');
    })
  }, []);

  const handleUpload = useCallback((event)=> {
    const file = event.target.files[0];
    setFileName(file.name);
  }, []);

  const breakdownFormSubmit = useCallback((e) => {
    e.preventDefault();
    const data = e.currentTarget;
    setIsUpload(true);

    api.postDirectorForm(data)
    .then((res) => {
      setUploadStatus(res);
    })
    .catch((err) => console.log(err))
    .finally(() => {
      setBreakdownOpened(false);
      setIsUpload(false);
    });
  }, [userInfo, isBreakdownOpened, setBreakdownOpened]);

  const hasNftGenerator = !isBreakdownOpened && param.occupation === 'actor';

  const onClaimNft = useCallback((event) => {
    event.preventDefault();
    setIsClaimedNft(true);
  }, []);

  const changeIsNftLayoutCallback = useCallback((v) => {
    changeIsNftLayout(v);
  }, []);

  if (isClaimedNft) {
    return (
      <NftGenerator userInfo={userInfo} changeIsNftLayout={changeIsNftLayoutCallback} />
    )
  } else {
    return (
      <Container>
        <Title>Congratulations</Title>

        <OverflowDescription>
          Your CastingForm has been successfully created.
        </OverflowDescription>

        <MarginDescription>
          Once the CastingForm desktop version is released you will be immediately notified at <MailBolderCaption>{userInfo.email}</MailBolderCaption>
        </MarginDescription>

        <Description>
          {descriptionValue(param.occupation)}
        </Description>

        <ContentBlock>
          {param.occupation === 'filmmaker' &&
            <BreakdownBlock>
              { !isBreakdownOpened
                ? <ShareBreakdownButton
                  onClick={() => setBreakdownOpened(!isBreakdownOpened)}
                >
                  Post a Breakdown
                </ShareBreakdownButton>
                : <BreakdownForm ref={form} onSubmit={breakdownFormSubmit}>
                  <Title>Post a Breakdown</Title>

                  <InputWrapperWithSpace>
                    <InputLabel>Role name</InputLabel>
                    <RoleInput name="name" />
                  </InputWrapperWithSpace>

                  <InputWrapperWithSpace>
                    <InputLabel>Character Description</InputLabel>
                    <Textarea
                      type="text"
                      name="text"
                      row="6"
                    />
                  </InputWrapperWithSpace>

                  <InputWrapperWithSpace>
                    <InputLabel>Upload pdf</InputLabel>
                    <UploadFile htmlFor="files">
                      <UploadButton src={downloadImg} />
                      { !!fileName &&
                        <UploadFilename type="button">
                          {fileName}
                        </UploadFilename>
                      }
                    </UploadFile>

                    <UploadInput
                      type="file"
                      id="files" name="files"
                      accept='.pdf, .zip, .rar'
                      onChange={handleUpload}
                    />
                  </InputWrapperWithSpace>
                  <HideInput name="email" value={userInfo.email} />

                  <ActionBlock>
                    {isUpload
                      ? <SendButton disabled>
                        Load...
                      </SendButton>
                      : <SendButton type='submit'>
                        Send
                      </SendButton>
                    }
                  </ActionBlock>
                </BreakdownForm>
              }
            </BreakdownBlock>
          }

          { hasNftGenerator &&
            <ClaimNftButton onClick={onClaimNft} disabled={!userInfo.id}>
              Claim my NFT
            </ClaimNftButton>
          }

          { !isBreakdownOpened &&
            <ImageContainer>
              <IphoneImage src={iphoneImage}/>
              <LinksImages>
                <QrImage src={qrImage} />
                <UniversalMobileLink to='http://onelink.to/CastingForm'>
                  <AppBanner src={googleImage} />
                </UniversalMobileLink>
                <UniversalMobileLink to='http://onelink.to/CastingForm'>
                  <AppBanner src={appleImage} />
                </UniversalMobileLink>
              </LinksImages>
            </ImageContainer>
          }


          {!!uploadStatus &&
            <SuccessUploadBlock>
              <SuccessImage src={successUpload} />
              <SuccessText>Breakdown Submitted successfully</SuccessText>
            </SuccessUploadBlock>
          }
        </ContentBlock>

      </Container>
    )
  }

}

export default WelcomePage;

const Container = styled.section`
  max-width: 344px;
  height: 100%;
`;

const OverflowDescription = styled(Description)`
  width: 375px;
  overflow: visible;
  margin-bottom: 16px;
`;

const MarginDescription = styled(Description)`
  margin-bottom: 21px;
  max-width: 325px;
`;

const MailBolderCaption = styled.span`
  font-weight: 900;
  font-size: 14px;
`;

const ShareBreakdownButton = styled.button`
  height: 40px;
  width: 177px;
  color: hsl(0, 0%, 100%);
  background-color: hsl(40, 57%, 57%);
  border: none;
  border-radius: 8px;

  cursor: pointer;
`;

const HideInput = styled.input`
  margin: 0;
  height: 2px;
  opacity: 0;
`;

const RoleInput = styled.input`
  width: 100%;
  height: 32px;
  padding: 0 10px;
  margin: 0;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: hsl(240, 3%, 11%);
  color: #fff;
  overflow: hidden;
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  line-height: 17px;
  font-size: 14px;
`;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 119px;
  max-height: 500px;
  padding: 6px 10px;
  color: #fff;
  font-size: 14px;
  background-color: hsl(240, 3%, 11%);
  border-radius: 8px;
  outline: none;
  border: none;
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
`;

const IphoneImage = styled.img`
  max-width: 179px;
  max-height: 362.51px;
`;

const LinksImages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  margin-top: auto;
`;

export const UniversalMobileLink = styled(Link)`
  text-decoration: none;
`;

export const QrImage = styled.img`
  max-width: 99px;
`;

export const AppBanner = styled.img``;

const ContentBlock = styled.div`
  position: relative;
`;
const BreakdownBlock = styled.div``;
const BreakdownForm = styled.form``;

const ActionBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const SendButton = styled.button`
  height: 32px;
  padding: 0 20px;
  line-height: 32px;
  color: hsl(0, 0%, 100%);
  background-color: hsl(40, 57%, 57%);
  border: none;
  border-radius: 8px;

  cursor: pointer;
`;

const SuccessUploadBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
`;
const SuccessImage = styled.img``;
const SuccessText = styled.p`
  display: block;
  width: 170px;
  margin: 27px auto;

  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;

const UploadFile = styled.label`
  display: flex;
  margin-top: 10px;
  gap: 8px;
`;
const UploadButton = styled.img`
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 6px;
  margin: 0;
  background-color: rgb(28, 28, 30);
  border-radius: 50%;
  cursor: pointer;
`;
const UploadFilename = styled.span`
  display: inline-block;
  height: 32px;
  padding: 0 16px;

  font-size: 14px;
  line-height: 32px;

  background-color: rgb(28, 28, 30);
  border-radius: 15px;
  cursor: pointer;
`;
const UploadInput = styled.input`
  position: absolute;
  opacity: 0;
`;
const ClaimNftButton = styled.button`
  width: 100%;
  max-width: 336px;
  height: 52px;
  border-radius: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  background: #C2A56D;
  border: 1px solid #C2A56D;
  transition: .2s;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.8;
    pointer-events: none;
  }

  &:hover {
    background: #FFFFFF;
    border-color: #FFFFFF;
    color: #1C1C1E;
  }
`;
