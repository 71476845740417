import React from 'react';
import Image1 from '../../images/footer/footer-app.png';
import Image2 from '../../images/footer/qrcode.svg';
import Image3 from '../../images/footer/footer-button-app-google.png';
import Image4 from '../../images/footer/footer-button-app-apple.png';
import Image5 from '../../images/footer/logo-white.svg';
import ImageSocial1 from '../../images/socials/icon-facebook.svg';
import ImageSocial2 from '../../images/socials/icon-instagram.svg';
import ImageSocial3 from '../../images/socials/icon-tiktok.svg';
import {
  FooterBlock,
  Navigation,
  Block,
  FooterSection,
  FlexContainer,
  Container,
  List,
  ListTitle,
  ListItem,
  LinkStyled,
  FlexContainerCentr,
  FlexContainerBottom,
  LogoBlock,
  BlockBottomLeft,
  Text,
  LinkWithIconStyled,
  Socials,
  SocialItem,
  SocialLink,
  InscribedBlock,
} from './Footer.styles';

const links = [
  {
    title: 'Actor',
    link: 'https://whatis.castingform.com/wheretostart'
  },
  {
    title: 'Filmmaker',
    link: 'https://whatis.castingform.com/castingdirector'
  },
  {
    title: 'About',
    link: 'https://whatis.castingform.com/'
  },
  {
    title: 'FAQ',
    link: 'https://whatis.castingform.com/#FAQ'
  }
]


const Footer = () => {
  return (
    <FooterBlock>
      <InscribedBlock>
        <FlexContainer>
          <Container>
            <Navigation>
              <ListTitle>CastingForm</ListTitle>
              <List>
                { links.map((item, i) => (
                  <ListItem key={i} >
                    <LinkStyled href={item.link}>{item.title}</LinkStyled>
                  </ListItem>
                ))}
              </List>
            </Navigation>
            <Block>
              <ListTitle>Customer Support</ListTitle>
              <List>
                <ListItem>
                  <LinkStyled href="https://wa.me/message/XFF4NDK6T677B1">WhatsApp</LinkStyled>
                </ListItem>
                <ListItem>
                  <LinkStyled href="mailto:call@castingform.com">Email</LinkStyled>
                </ListItem>
              </List>
            </Block>
          </Container>
          <FlexContainerCentr>
            <img src={Image1} height="245" width="224" alt="application"/>
            <List>
                <ListItem>
                  <img src={Image2} height="73" width="73" alt="Casting Forms QR Code" />
                </ListItem>
                <ListItem>
                  <LinkStyled href="https://play.google.com/store/apps/details?id=com.castingform.inc&hl=en_US&gl=US">
                    <img src={Image3} height="40" width="130" alt="google app" />
                  </LinkStyled>
                </ListItem>
                <ListItem>
                  <LinkStyled href="https://apps.apple.com/us/app/castingform/id1478861578">
                    <img src={Image4} height="40" width="130" alt="apple app" />
                  </LinkStyled>
                </ListItem>
              </List>
          </FlexContainerCentr>
        </FlexContainer>
      </InscribedBlock>
      <FooterSection>
        <InscribedBlock>
          <FlexContainerBottom>
            <Block>
              <LogoBlock>
                <img src={Image5} layout="fill" alt="Casting Form" />
              </LogoBlock>
              <BlockBottomLeft>
                <Text>Copyright &copy; 2022 CastingForm, Inc.</Text>
                <LinkWithIconStyled href="https://whatis.castingform.com/terms">Terms Of Use</LinkWithIconStyled>
                <LinkWithIconStyled href="https://whatis.castingform.com/privacy">Privacy Policy</LinkWithIconStyled>
              </BlockBottomLeft>
            </Block>

            <Socials>
              <SocialItem>
                <SocialLink href="/">
                  <img src={ImageSocial1} width="20" height="20" />
                </SocialLink>
              </SocialItem>

              <SocialItem>
                <SocialLink href="/">
                  <img src={ImageSocial2} width="20" height="20" />
                </SocialLink>
              </SocialItem>

              <SocialItem>
                <SocialLink href="/">
                  <img src={ImageSocial3} width="20" height="20" />
                </SocialLink>
              </SocialItem>

            </Socials>
          </FlexContainerBottom>

        </InscribedBlock>
      </FooterSection>
    </FooterBlock>
  );
}

export default Footer;
