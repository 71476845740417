import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import './Header.css';
import Logo from '../../images/logo.svg';
import cannesImage from '../../images/cannes.png';
// import LogoShort from '../../images/LogoShort.svg';
// import Popup from '../Popup/Popup';

import { slide as Menu } from "react-burger-menu";
import styled from 'styled-components';

const toggleMenu = ({ isOpen }) => {
  const menuWrap = document.querySelector(".bm-menu-wrap");
  isOpen
    ? menuWrap.setAttribute("aria-hidden", false)
    : menuWrap.setAttribute("aria-hidden", true);
};

function Header({ onOpen }) {
  // const [modalActive, setModalActive] = useState(false);
  const location = useLocation();

  let width = window.innerWidth;
  let history = useHistory();

  function handleSignUp() {
    history.push("/auth/sign-up");
  }

  function handleSignIn() {
    history.push("/auth/sign-in");
  }

  function handleGoCannes() {
    history.push("/Cannes");
  }

    return (
        <header id="head" className={`header ${location.pathname === '/actor' && "header_none"}`}>
          <a href="https://castingform.com/">
            <img className='header__logo' src={Logo} alt={Logo}/>
          </a>

          <DesktopMenu>
            <nav className='header__nav'>
              {/* <DesktopCannesLink to="/cannes">
                <img src={cannesImage} />
              </DesktopCannesLink> */}
              {/* <button className='header__button_type_cannes' onClick={handleGoCannes} type='button'/> */}
              <Link to="/wheretostart" className='header__link'>Actor</Link>
              <Link to="/castingdirector" className='header__link'>Filmmaker</Link>
                {/* <a href='/' className='header__link'>Actor</a>
                <a href='/' className='header__link'>Premium</a> */}
            </nav>
            <button className='header__button_type_signin' onClick={handleSignIn} type='button'>Sign in</button>
            <button className='header__button_type_signin' onClick={handleSignUp} type='button'>Sign up</button>
            <a href="https://whatis.castingform.com/auth/sign-up" className="header__link__book">Book</a>
            {/* <Popup active={modalActive} setActive={setModalActive}/> */}
          </DesktopMenu>

          <MobileMenu>
            <MobileCannesLink to="/cannes">
              <img src={cannesImage} />
            </MobileCannesLink>
            <Menu right noOverlay disableOverlayClick onStateChange={toggleMenu}>
              <Link to="/wheretostart" className='menu-item'>Where to start?</Link>
              <Link to="/castingdirector" className='menu-item'>Casting Director</Link>
                <a href='http://onelink.to/castingform' className='main-offer__button_type_join_link'>
                  <button git className='header__button_type_signup' onClick={onOpen} type='button'>Sign up</button>
                </a>
              {/* <Popup active={modalActive} setActive={setModalActive}/> */}
            </Menu>
          </MobileMenu>
        </header>
    )
}

export default Header

const MobileMenu = styled.nav`
  display: block;
  @media (min-width: 1024px) {
    display: none;
  }
`;
const DesktopMenu = styled.nav`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
  }
`;

const DesktopCannesLink = styled(Link)`
  margin-right: 24px;
`;
const MobileCannesLink = styled(Link)`
  margin-right: 80px;
`;
