import React from 'react'
import './Popup.css'
import appleIcon from '../../images/apple-store-icon.png';
import googleIcon from '../../images/google-store-icon.png';

const Popup = ({active, setActive}) => {
    return (
        <div className={active ? "popup active" : "popup"} onClick={() => setActive(false)}>
            <div className={active ? "popup__content active" : "popup__content"} onClick={e => e.stopPropagation()}>
                <button className='popup__button_type_close' onClick={() => setActive(false)}>+</button>
                <h3 className='popup__heading'>Switch to mobile device</h3>
                <p className='popup__caption'>Desktop version is coming soon. <br/>Please open this page on your mobile device. Download the app on your mobile device for sign up and sign in.</p>
                {/* <a className="popup__button_type_join" href='http://onelink.to/CastingForm'>Join</a> */}

                <ul className="popup__list-links">
                  <li className="popup__item-link">
                    <a href="https://apps.apple.com/us/app/castingform/id1478861578" className="popup__link">
                      <img src={appleIcon} alt="" className="popup__icon popup__icon_apple" />
                    </a>
                  </li>
                  <li className="popup__item-link">
                    <a href="https://play.google.com/store/apps/details?id=com.castingform.inc&hl=en_US&gl=US" className="popup__link">
                        <img src={googleIcon} alt="" className="popup__icon popup__icon_google" />
                    </a>
                  </li>
                </ul>

            </div>
        </div>
    )
}

export default Popup
