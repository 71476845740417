export const API = 'https://api.castingform.com/api/v0';
export const NFT_API = 'https://castingformnft.argomedia.co/api'

export const mockTimes = [
  '03:00 AM',
  '04:00 AM',
  '05:00 AM',
  '06:00 AM',
  '07:00 AM',
  '08:00 AM',
  '09:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '13:00 PM',
  '14:00 PM',
  '15:00 PM',
  '16:00 PM',
  '17:00 PM',
  '18:00 PM',
]

export const mockZones = [
  'UTC -12:00',
  'UTC -11:00',
  'UTC -10:00',
  'UTC -09:00',
  'UTC -08:00',
  'UTC -07:00',
  'UTC -06:00',
  'UTC -05:00',
  'UTC -04:00',
  'UTC -03:00',
  'UTC -02:00',
  'UTC -01:00',
  'UTC  00:00',
  'UTC +01:00',
  'UTC +02:00',
  'UTC +03:00',
  'UTC +04:00',
  'UTC +05:00',
  'UTC +06:00',
  'UTC +07:00',
  'UTC +08:00',
  'UTC +09:00',
  'UTC +10:00',
  'UTC +11:00',
  'UTC +12:00'
]
