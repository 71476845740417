
import styled from 'styled-components';

export const PrimaryButton = (props) => {
  const { onClick, disabled, children } = props;
  return <StyledPrimaryButton>
    {children}
  </StyledPrimaryButton>;
};

export const StyledPrimaryButton = styled.button`
  padding: 0 10px;
  height: 32px;
  min-width: 95px;
  font-size: 14px;
  color: #fff;
  background-color: #9A5DE8;
  border: none;
  border-radius: 12px;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`;