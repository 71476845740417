import React from 'react';
import styled from 'styled-components';

import photoOk from '../../images/photo-ok.png';
import iconOk from '../../images/icon-ok.svg';
import iconNo from '../../images/icon-no.svg';
import { photos } from './mock'

export const RightHeadshot = () => {
  return (
    <Dashboard>
      <MainTitle>HOW TO MAKE RIGHT HEADSHOT</MainTitle>
      <FlexContainer>

        <LeftSide>
          <Title color='green'>Yes</Title>
          <Container>
            <Image type='ok' src={photoOk} alt="photo-example" />
            <Icon ok />
          </Container>
          <Text>The perfect headshot is when you are facing the camera and your face, skin, hair and eye color are clearly seen.</Text>
        </LeftSide>

        <RightSide>
          <Title color='red'>No</Title>
          <List>
          {photos.map((item, i) => (
              <Item key={i}>
                <ContainerImage>
                  <Image type='no' src={item.link} alt="photo-example" />
                  <Icon  />
                </ContainerImage>
                <Text>{item.text}</Text>
              </Item>
          ))}
          </List>
        </RightSide>
      </FlexContainer>
    </Dashboard>
  )
}


const Dashboard = styled.div`
  width: 800px;
  min-height: 675px;
  padding: 23px;

  background-color: hsla(240, 4%, 5%, 1);
  box-sizing: border-box;
`
const MainTitle = styled.h2`
  margin: 0;
  margin-bottom: 36px;

  font-family: 'Druk Wide Medium';
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: hsla(40, 57%, 57%, 1);
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftSide = styled.div`

`
const RightSide = styled.div`

`

const Container = styled.div`
  position: relative;
  max-width: 142px;
`

const ContainerImage = styled.div`
position: relative;
max-width: 72px;
`

const Title = styled.h3`
  margin: 0;
  margin-bottom: 20px;

  font-family: 'Druk Wide Medium';
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: ${({color}) => color === 'red' ? 'hsla(0, 100%, 65%, 1)' : 'hsla(122, 41%, 59%, 1)'};

  text-transform: uppercase
`
const Image = styled.img`
  width: ${({type}) => type === 'ok' ? '142px' : '72px'};
  height: ${({type}) => type === 'ok' ? '256px' : '128px'};
  display: block;
  margin-bottom: ${({type}) => type === 'ok' ? '33px' : '12px'};

  border-radius: 12px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
`
const Icon = styled.span`
  position: absolute;
  bottom: -13px;
  right: -13px;


  width: 27.5px;
  height: 27.5px;
  display: block;

  background-image: ${({ok}) => ok ? `url(${iconOk})` : `url(${iconNo})`}
`
const List = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 74px;
  row-gap: 23px;

  list-style: none;
`
const Item = styled.li`

`

const Text = styled.span`
  display: block;
  max-width: 189px;
  font-family: 'Formular';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
`
