import React, { useCallback } from 'react';
import styled from 'styled-components';

export const ManyValuesBlock = ({
  list,
  values,
  onChange // (arr) => void;
}) => {

  const pushItem = useCallback((value) => {
    onChange([...values, value]);
  }, [values, onChange]);

  const deleteItem = useCallback((value) => {
    const newValues = values.filter(item => item !== value);
    onChange(newValues);
  }, [values, onChange]);

  const handleClick = useCallback((value) => {
    const isActive = values.findIndex((current) => value === current) >= 0;
    
    if (isActive) {
      deleteItem(value);
    } else {
      pushItem(value);
    }
  }, [values, pushItem, deleteItem]);

  return (
    <Wrapper>
      {list.map((item, i) => (
        <Item
          key={i}
          isActive={values.findIndex((current) => item === current) >= 0}
          onClick={() => handleClick(item)}
        >
          {item}
        </Item>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 8px;

  list-style: none;
`;

const Item = styled.li`
  padding: 8px 12px;

  font-family: 'Formular';
  font-size: 16px;
  line-height: 20px;
  color: hsl(0, 0%, 100%);

  border-radius: 36px;
  background-color: ${({ isActive }) => isActive ? 'hsl(266, 75%, 64%)' : 'hsl(240, 3%, 11%)'};

  cursor: pointer;
`;