import React from 'react';

import './Additional.css';

const Additional = () => {
  let width = window.innerWidth;
  return (
    <section className="additional">
      <div className="additional__container">
        <div className='additional__photos'>
          <div className="additional__flex-container">
            <div className='additional__photo_top additional__photo'/>
            <div className='additional__photo_top-right additional__photo'/>
          </div>
          <div className="additional__flex-container">
            <div className='additional__photo_bottom-left additional__photo'/>
            <div className='additional__photo_bottom-right additional__photo'/>
          </div>
          <div className='additional__photo_position-absolute additional__photo'/>
        </div>

        <div className='slate__info'>
          <p className='slate__info_number'>6.</p>
          <h2 className='slate__info_heading'>Additional materials</h2>
          <p className='slate__info_caption'>
            Create an account and add a portfolio. Send the account for approval from the CastingForm virtual manager.
          </p>
        </div>

        {width > 1024 ?
        <>
        <div className="additional_print_small" />
        <div className="additional_print_half" />
        <div className="additional_print_big" />
        </> : null}

      </div>
    </section>
  )
}

export default Additional;
