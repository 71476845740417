import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

export const StepContent = styled.div``;
export const StepCounter = styled.div``;

export const Caption = styled.p`
  margin-bottom: 8px;
  font-size: 14px;
  color: hsl(0, 0%, 47%);
`;

const Heading = styled.h2`
  line-height: 36px;
  font-size: 26px;
  font-family: 'Druk-Wide';
`;
export const Header = styled(Heading)`
  margin-bottom: 8px;
`;
export const Title = styled(Heading)`
  margin-bottom: 20px;
  font-size: 20px;
`;
export const TitleWithSpace = styled(Title)`
  margin-bottom: 40px;
`;

export const Description = styled.p`
  margin-bottom: 40px;
  font-size: 16px;
`;

export const InputWrapper = styled.div`
  margin-bottom: 8px;
`;
export const InputWrapperWithSpace = styled(InputWrapper)`
  margin-bottom: 20px;
`;

export const NotificationText = styled.p`
  display: block;
  width: 300px;
  margin: 0 auto 40px;
  line-height: 18px;
  font-size: 12px;
  text-align: center;
  color: hsla(0, 0%, 47%, 1);
`;

export const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AuthLink = styled(Link)`
  text-decoration: none;
  color: hsl(266, 75%, 64%);
`;

export const BackLink = styled(Link)`
  text-decoration: none;
  color: hsl(0, 0%, 100%);
`;
