import React, {useState} from 'react';
import { Route, Switch, useHistory  } from 'react-router-dom';
import './App.css';
import Header from './Components/Header/Header';
import MainPage from './pages/MainPage/MainPage';
import Footer from './Components/Footer/Footer';
// import MobilePage from './Components/MobilePage/MobilePage';
import WhereToStart from './pages/WhereToStart/WhereToStart';
import CastingDirector from './pages/CastingDirector/CastingDirector';
import TermsPage from './pages/TermsPage/TermsPage';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ActorInfo from './pages/ActorInfo/ActorInfo';
import CannesPoster from './pages/AdvertisingPoster/CannesAdvertisingPoster';

import PlugPage from './pages/RegistrationPage/PlugPage';
import PostBreakdown from './pages/RegistrationPage/PostBreakdownPage';
import AuthRootPage from './pages/AuthPage/AuthRootPage';
import Popup from './Components/Popup/Popup';

const PageWrapper = (props) => {
  const [isOpenedPopup, setIsOpenedPopup] = useState(false);

  const handleOpenPopup = () => {
    setIsOpenedPopup(true);
  }
  return <>
    <Header onOpen={handleOpenPopup} />
    {props.children}
    <Footer />

    <Popup active={isOpenedPopup} setActive={setIsOpenedPopup} />
  </>
};

function App() {
  const history = useHistory();

  const [currentUser, setCurrentUser] = useState(null);

  const onLogout = () => {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh')
      setCurrentUser(null);
      history.push('/')
  }

  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <PageWrapper>
            <MainPage />
          </PageWrapper>
        </Route>
        <Route path="/wheretostart">
          <PageWrapper>
            <WhereToStart/>
          </PageWrapper>
        </Route>
        <Route path="/castingdirector">
          <PageWrapper>
            <CastingDirector/>
          </PageWrapper>
        </Route>
        <Route path="/terms">
          <PageWrapper>
            <TermsPage/>
          </PageWrapper>
        </Route>
        <Route path="/privacy">
          <PageWrapper>
            <PrivacyPolicy/>
          </PageWrapper>
        </Route>
        <Route path={`/sharing-actor`}>
          <ActorInfo/>
        </Route>

        <Route path="/auth">
          <AuthRootPage currentUser={currentUser} setCurrentUser={setCurrentUser} />
        </Route>

        <Route path={`/cannes`}>
          <CannesPoster />
        </Route>

        <Route path={`/welcome`}>
          <PlugPage currentUser={currentUser} onSignOut={onLogout}/>
        </Route>
        <Route path={`/send-form`}>
          <PostBreakdown currentUser={currentUser} onSignOut={onLogout} />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
