import React from 'react';
import qrImage from '../../../../../images/slider/qr.png';
import appleImage from '../../../../../images/slider/apple-app.png';
import googleImage from '../../../../../images/slider/google-app.png';
import phoneImage from '../../../../../images/slider/phones.png';
import ellipseImage from '../../../../../images/slider/ellipse.png';
import {
  SlideCardWrapper,
  SlideCard,
  SlideText,
} from './styles';
import styled from 'styled-components';

const MobileSlide = () => {
  return (
    <SlideCardWrapper>
      <SlideCard>
        <SlideText width="220px">
          DOWNLOAD MOBILE VERSION
        </SlideText>
        <TemplateImageWrapper>
          <TemplateImage src={ellipseImage} />
        </TemplateImageWrapper>
        <MainImage src={phoneImage} />
        <MobileBlock>
          <QrImage src={qrImage} />
          <AppBlock>
            <AppBanner src={googleImage} />
            <AppBanner src={appleImage} />
          </AppBlock>
        </MobileBlock>
      </SlideCard>
    </SlideCardWrapper>
  )
};

export default MobileSlide;

const MainImage = styled.img`
  position: absolute;
  top: -46px;
  left: 180px;
`;
const TemplateImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  buttom: 0;
  height: 338px;
  width: 381px;
  overflow: hidden;
`;
const TemplateImage = styled.img`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
`;

const MobileBlock = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 24px;
  gap: 12px;
`;
const AppBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`;
const QrImage = styled.img``;
const AppBanner = styled.img``;