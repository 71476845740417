import React from 'react'
import './Drama.css'
import Play from '../../images/where/play.svg'

function Drama({title, number, text}) {
  let width = window.innerWidth;
  return (
    <section className='drama'>
      <div className='drama__content'>
        {width > 1024 ? <div className="drama__print_big_top"/> : null }
        <div className='drama__info'>
          <p className='drama__info_number'>{number}.</p>
          <h2 className='drama__info_heading'>{title}</h2>
          <p className='drama__info_caption'>
            {text}
          </p>
        </div>
        <div className='drama__photos'>
          <div className={number === '3' ? 'drama__photo_top': 'drama__photo_top_5'}>
            <img className='drama__photo_button_type_play' src={Play} alt={Play} />
          </div>
          <div className={number === '3' ? 'drama__photo_center': 'drama__photo_center_5'}/>
          <div className={number === '3' ? 'drama__photo_bottom': 'drama__photo_bottom_5'}>
            <img className='drama__photo_button_type_play' src={Play} alt={Play} />
          </div>
        </div>
      </div>

    </section>
  )
}

export default Drama
