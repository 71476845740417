import React, { useState, useCallback } from 'react';
import { Link, useHistory  } from 'react-router-dom';
import Slider from '../components/slider/cardSlider';
import { api } from '../../../utils/Api';
import { InputComponent } from '../components/inputs/Input';
import { StyledPrimaryButton } from '../components/buttons';
import Header from '../components/header';
import styled from 'styled-components';

const getOccupationLink = (user) => {
  if (!!user.filmMaker) return '/auth/welcome/filmmaker';
  if (!!user.talentRep) return '/auth/welcome/talentrep';
  if (!!user.actor) return '/auth/welcome/actor';
  return '/auth/sign-up/occupation'
}

const SignInPage = (props) => {
  const { setCurrentUser } = props;
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailValidation, setEmailValidation] = useState('');
  const [passwordValidation, setPasswordValidation] = useState('');

  const onEmailChange = useCallback((e) => {
    setEmailValidation('');
    setEmail(e.target.value);
  }, []);

  const onPasswordChange = useCallback((e) => {
    setPasswordValidation('');
    setPassword(e.target.value);
  }, []);

  const onLogin = useCallback((email, password) => {
    api.auth(email, password)
      .then((res) => {
        localStorage.setItem('token', res.token);
        localStorage.setItem('refresh', res.refreshToken);
        setCurrentUser(res);
        history.push(getOccupationLink(res));
      })
      .catch((err) => console.log(err));
      // TODO: setValidation after error
  }, [setCurrentUser, history]);

  return (
    <Container>
      <Header withLogout={false} />
      <AuthHelper>
        <TopShadow />
        <AuthHelperContent>
          <Slider />
        </AuthHelperContent>
        <BottomShadow />
      </AuthHelper>
      <AuthContent>
        <LoginWrapper>
          <Caption>Sign In</Caption>
          <HeaderText>Welcome to CastingForm</HeaderText>
          <Description>The #1 platform to discover and book actors globally</Description>
          <Title>Enter contact details</Title>

          <InputWrapper>
            <InputComponent label="Email" value={email} placeholder="Your email" onChange={onEmailChange} error={emailValidation} />
          </InputWrapper>

          <InputWrapper>
            <LabelWrapper>
              <InputLabel>Password</InputLabel>
              {/* <InputLink to='/'>Forgot password?</InputLink> */}
            </LabelWrapper>
            <InputComponent type="password" value={password} placeholder="Your password" onChange={onPasswordChange} error={passwordValidation} />
          </InputWrapper>

          <ActionBlock>
            <SignUpBlock>
              Don't have an account?
              <SignUpLink to="/auth/sign-up">Sign Up</SignUpLink>
            </SignUpBlock>
            <StyledPrimaryButton
              disabled={email === '' || password === '' || !!emailValidation || !!passwordValidation }
              onClick={() => onLogin(email, password)}
            >
              Next
            </StyledPrimaryButton>
          </ActionBlock>

        </LoginWrapper>
      </AuthContent>
    </Container>
  )
}

export default SignInPage;

const Container = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  justify-content: stretch;
`;

const AuthHelper = styled.div`
  position: relative;
  display: grid;
  flex: 1 1 100%;
  padding-top: 74px;

  overflow: hidden;
`;
const AuthHelperContent = styled.section`
  height: 100%;
  padding-left: 38px;
  place-self: center;
`;

const ShadowBlock = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 133px;

  z-index: 5;
`;
const TopShadow = styled(ShadowBlock)`
  top: 74px;
  background: linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,1));
`;
const BottomShadow = styled(ShadowBlock)`
  bottom: 0;
  background: linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0));
`;

const AuthContent = styled.section`
  flex: 1 0 590px;
  display: flex;
  padding: 0 121px 40px 121px;
  align-items: center;
`;

const LoginWrapper = styled.div``;

const Caption = styled.p`
  margin-bottom: 8px;
  line-height: 1;
  font-size: 14px;
  color: #787878;
`;
const HeaderText = styled.h2`
  line-height: 36px;
  font-size: 26px;
  font-family: 'Druk-Wide';
  margin-bottom: 8px;
`;
const Description = styled.p`
  margin-bottom: 40px;
  line-height: 1;
  font-size: 16px;
  opacity: 0.9;
`;
const Title = styled.h2`
  margin-bottom: 20px;
  line-height: 36px;
  font-size: 20px;
  font-family: 'Druk-Wide';
`;
const InputWrapper = styled.div`
  margin-bottom: 16px;
`;
const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: flex-end;
`;
const InputLabel = styled.label`
  line-height: 17px;
  font-size: 14px;
`;
const InputLink = styled(Link)`
  font-size: 12px;
  text-decoration: none;
  color: #9A5DE8;
`;

const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SignUpBlock = styled.label`
  display: inline-block;
  font-size: 14px;
  color: #787878;
`;
const SignUpLink = styled(Link)`
  margin-left: 8px;
  text-decoration: none;
  color: #9A5DE8;
`;
