import React from 'react';

import MainOfferWTS from '../../Components/MainOffer/MainOfferWTS';
import Headshots from '../../Components/Headshots/Headshots';
import Slate from '../../Components/Slate/Slate';
import Drama from '../../Components/Drama/Drama';
import Additional from '../../Components/Additional/Additional';
import WhatsNext from '../../Components/WhatsNext/WhatsNext';
import MoreInfo from '../../Components/MoreInfo/MoreInfo';
import Kickstart from '../../Components/Kickstart/Kickstart';


function WhereToStart() {
  return (
    <div>

      <MainOfferWTS/>
       <Headshots/>
      <Slate
        title="Slate"
        number="2"
        text="Upload a short video (up to 30 sec)
        of yourself. State your name, height, location, languages, work permissions and any special skills you have."
      />
      <Drama
        title="Drama Monologue"
        number="3"
        text="Create and upload a dramatic dialogue from a play or movie up to 60 seconds. Be sincere and do your best to showcase your talent."
      />
      <Slate
        print
        title="Comedy Monologue"
        number="4"
        text="If you are more into comedy than drama, upload a comedy monologue from a play or movie."
      />
      <Drama
        title="Your most organic self"
        number="5"
        text="Choose a monologue which relates to you the most. Show your acting skills in the most natural way."
      />
      <Additional />
      <WhatsNext />
      <MoreInfo />
      <Kickstart />
    </div>
  )
}

export default WhereToStart
