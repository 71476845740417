import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory  } from 'react-router-dom';
import { StyledPrimaryButton } from '../../../components/buttons';

import { StepsCounter } from '../../../components/steps';
import { Checkbox } from '../../../components/checkBox';
import {
  Container,
  StepContent,
  StepCounter,
  Caption,
  Title,
  ActionBlock,
  BackLink,
} from '../../../components/commonStyles';
import { api } from '../../../../../utils/Api';

import styled from 'styled-components';
import bascketImg from './images/uploadImg.png'
import placeholder from './images/headshotPlaceholder.png'


const ActorHeadshotPage = (props) => {
  const { userInfo, setUserInfo, setUserData, openHeadShots } = props;
  const history = useHistory();
  const form = useRef(null)

  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [isDownloadImage, setIsDownloadImage] = useState(false);
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    api.getCurrentUser()
    .then(() => {
      console.log('active user');
    })
    .catch((error) => {
      console.error(error);
      history.push('/auth/sign-in');
    })
  }, [history]);

  const  handleClearHeadshotInput = useCallback((event) => {
    event.preventDefault();
    setIsDownloadImage(false);
  }, []);

  const handleCheckClick = useCallback(() => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  const handleUploadHeadshot = useCallback((e)=> {
    const data = form.current;
    setIsLoading(true)
    api.createNewFile(data, 'public')
      .then((res) => {
        const photoUrl = URL.createObjectURL(e.target.files[0]);
        setIsDownloadImage(true);
        setImageFile(photoUrl);
        setUserInfo({ photos: [ res[0].id ] });
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }, [setUserInfo]);

  const onRedirect = useCallback(() => {
    setUserData('actor');
  }, [setUserData]);

  return (
    <Container>
      <StepContent>
        <Caption>Sign Up</Caption>
        <Title>The Headshot is the most important</Title>
        <Text>
          Make sure your face fits the circle <StyledLink white={true} to="#" onClick={openHeadShots}>more info</StyledLink>
        </Text>
        <form ref={form}>
          <File
            type='file'
            name='files'
            id='file'
            accept='.png, .jpg'

            onChange={handleUploadHeadshot}
          />
        </form>

        {isDownloadImage && <ImageContainer>
          <ButtonClear
            type='button'
            onClick={handleClearHeadshotInput}
          />
          <Image
            className="steps__image"
            src={imageFile} alt="Аватар"/>
        </ImageContainer>}
        {!isDownloadImage && <label htmlFor='file'><Example /></label> }

        <AcceptContainer>
          <Checkbox onChange={handleCheckClick} />
          <AcceptText>I accept <StyledLink to='/terms'>Terms of Service</StyledLink> and <StyledLink to='/privacy'>Privacy Police</StyledLink></AcceptText>
        </AcceptContainer>

        <ActionBlock>
          <BackLink to="/auth/sign-up/actor/social">Back</BackLink>
          <StyledPrimaryButton
            disabled={ !isChecked }
            onClick={() => onRedirect()}
          >
            Create
          </StyledPrimaryButton>
        </ActionBlock>
      </StepContent>
      <StepCounter>
        <StepsCounter currentStep={6} length={6} />
      </StepCounter>
    </Container>
  )
}

export default ActorHeadshotPage;

const Text = styled.p`
  margin: 0;
  margin-bottom: 23px;
  display: block;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
const AcceptText = styled(Text)`
  color: hsl(0, 0%, 53%);
  margin: 0;
`;

const StyledLink = styled(Link)`
  font-family: 'Formular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({white}) => white ? 'white' : 'hsla(0, 0%, 53%, 1)'};
`;

const File = styled.input`
  width: 100%;
  height: 68px;
  color: inherit;
  line-height: 1.22;
  color: #000;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 16px;
  font-family: inherit;
  box-sizing: border-box;
  position: relative;
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
`;
const ButtonClear = styled.button`
  width: 30px;
  height: 40px;
  position: absolute;
  border: none;
  background-color: transparent;
  background-position: 50%;
  background-repeat: no-repeat;
  background-image: url(${bascketImg});
  cursor: pointer;
  top: 0;
  right: 48px;
  z-index: 2000;
`;
const Image = styled.img`
  max-width: 251px;
  width: 100%;
  min-height: 416px;
  margin: 0 50px 34px;
  object-fit: cover;
  border-radius: 16px;
  cursor: pointer;
`;
const Example = styled.span`
  display: block;
  max-width: 251px;
  width: 100%;
  min-height: 416px;
  border: none;
  border-radius: 12px;
  background-color: #1C1C1E;
  background-image: url(${placeholder});
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 auto 34px;
`;
const ImageContainer = styled.div`
  position: relative;
`;

const AcceptContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  gap: 10px;
`;
