import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import logoCasting from '../../../images/castingform-logo.svg';
import emailIcon from '../../../images/icons/email-icon.svg';
import whatsupIcon from '../../../images/icons/whatsup.svg';

import styled from 'styled-components';

const Header = (props) => {

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
  }, []);

  return (
    <PageHeader>
      <MainHeader>
        <LogoBlock>
          <a href="https://castingform.com/">
            <Logo src={logoCasting} alt="CastingForm" />
          </a>
        </LogoBlock>
        <ContactBlock>
          <ContactLink href="https://wa.me/message/XFF4NDK6T677B1">
            <ContactImage src={whatsupIcon} />
          </ContactLink>
          <ContactLink href="mailto:cannes@castingform.com" target="_blank">
            <ContactImage src={emailIcon} />
          </ContactLink>
        </ContactBlock>
      </MainHeader>
      <AuthBlock>
        { props.withLogout && <LogoutLink to="/" onClick={() => logout()}>Sign out</LogoutLink> }
      </AuthBlock>
    </PageHeader>
  )
}

export default Header;

const PageHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 74px;
  justify-content: space-between;
  align-items: center;

  z-index: 100;
`;

const MainHeader = styled.div`
  display: flex;
  padding: 0 40px;
  width: 100%;
  justify-content: space-between;
`;

const AuthBlock = styled.div`
  flex: 0 0 590px;
  display: flex;
  padding: 0 40px;
  justify-content: flex-end;
`;

const LogoBlock = styled.div``;
const Logo = styled.img``;
const ContactBlock = styled.div``;
const ContactLink = styled.a``;

const ContactImage = styled.img`
  height: 20px;
  margin-left: 16px;
`;

const LogoutLink = styled(Link)`
  font-size: 16px;
  line-height: 20px;
  color: hsl(250, 100%, 69%);
  text-decoration: none;
`;
