import React from 'react';

import MainOffer from '../../Components/MainOffer/MainOffer';
import WorkScheme from '../../Components/WorkScheme/WorkScheme';
import Statistics from '../../Components/Statistics/Statistics';
import Movies from '../../Components/Movies/Movies';
import Sponsors from '../../Components/Sponsors/Sponsors';
import Reviews from '../../Components/Reviews/Reviews';
import Principles from '../../Components/Principles/Principles';
import Actors from '../../Components/Actors/Actors';
import Films from '../../Components/Films/Films';
import Faq from '../../Components/Faq/Faq';
import Kickstart from '../../Components/Kickstart/Kickstart';
// import PopupAdvertising from '../../Components/PopupAdvertising/PopupAdvertising';

import 'react-calendar/dist/Calendar.css';
import './MainPage.css'

const MainPage = () => {



  return (
    <>
      <MainOffer/>
      <WorkScheme/>
      <Statistics/>
      <Movies/>
      <Sponsors/>
      <Reviews/>
      <Principles/>
      <Actors />
      <Films/>
      <Faq />
      <Kickstart />
      {/* <PopupAdvertising /> */}
    </>
  )
}

export default MainPage;
