
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, Link, useRouteMatch  } from 'react-router-dom';

import { api } from '../../../../utils/Api';
import { StyledPrimaryButton } from '../../components/buttons';
import { InputPhoneBlock } from '../../components/inputs/PhoneInput';
import { InputComponent } from '../../components/inputs/Input';
import { getTime } from '../../utils/utils';
import styled from 'styled-components';

const PhonePage = (props) => {
  const { changeUserInfo } = props;
  const history = useHistory();

  const { path } = useRouteMatch();

  const [step, setStep] = useState('code request');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [isTimer, setIsTimer] = useState(false);
  const [counter, setCounter] = useState(5);

  const [phoneValidation, setPhoneValidation] = useState('');
  const [codeValidation, setCodeValidation] = useState('');

  const onPhoneChange = useCallback((value) => {
    setPhoneValidation('');
    setPhone(value === undefined ? '' : value);
  }, []);
  const onCodeChange = useCallback((e) => {
    setCodeValidation('');
    setCode(e.target.value);
  }, []);

  const sendSMS = useCallback((phone) => {
    api.sendPhone({ phone })
    .then((res) => {
      console.log(res)
      setIsTimer(true)
    })
    // TODO: error function;
  }, []);

  const checkNumber = useCallback((phone) => {
    if (phone === '+11111111111') {
      // It's for test
      history.push('/auth/sign-up/occupation');
    } else {
      api.query('user/check_phone_number', { phone })
      .then(response => {
        if (!!response) {
          sendSMS(phone);
          setStep('confirmation');
        } else {
          setPhoneValidation('This phone number already exists.');
        }
      });
    };
  }, [sendSMS, history]);

  const verifyPhone = useCallback((phone, code) => {
    const data = {
      phone,
      code
    };
    api.verifyPhone(data)
    .then(res => {
      console.log('phone number verified: ' + res);
      changeUserInfo({
        phone,
      });
      history.push('/auth/sign-up/occupation');
    })
  }, [changeUserInfo, history]);

  useEffect(() => {
    if (isTimer) {
      let _interval = setInterval(() => {
        setCounter(counter - 1); //reverse order
        if (counter === 1) {
            clearInterval(_interval);
            setIsTimer(false);
            setCounter(10);
        }
      }, 1 * 1000);
      return () => {
        clearInterval(_interval);
      }
    }
}, [counter, isTimer]);

  return (
    <Container>
      <Caption>Sign Up</Caption>
      <Title>Verify your phone number</Title>

      <InputWrapperWithSpace>
        <InputPhoneBlock
          label='Phone'
          value={phone}
          placeholder="Your phone"
          onChange={onPhoneChange}
          error={phoneValidation}
          disabled={step === 'confirmation'}
        />
      </InputWrapperWithSpace>

      {step === 'confirmation' &&
      <>
        <NotificationText>
          For security reasons, we need to make sure it's really you. Please enter a 6-digit verification code that you now have on your phone.
        </NotificationText>

        <InputWrapperWithSpace>
          <InputComponent label="Confirmation code" type="number" value={code} onChange={onCodeChange} error={codeValidation} />
        </InputWrapperWithSpace>
      </>}

      <ActionBlock>
        <BackBlock>
          {step === 'confirmation' &&
            <BackButton onClick={() => setStep('code request')}>Back</BackButton>
          }
        </BackBlock>
        <SignUpBlock>
          {step === 'confirmation'
            ? <>
              {isTimer
                ? <TimerParagraph>
                    Request new code {getTime(counter)}
                  </TimerParagraph>
                : <RecallCode onClick={() => sendSMS(phone)}>
                    Request new code
                  </RecallCode>
              }
              <StyledPrimaryButton
                disabled={(code.length < 6) || !!codeValidation }
                onClick={() => verifyPhone(phone, code)}
              >
                Confirm
              </StyledPrimaryButton>
            </>
            : <StyledPrimaryButton
                disabled={phone.length < 12 || !!phoneValidation }
                onClick={() => checkNumber(phone)}
              >
              Request code
            </StyledPrimaryButton>
          }
        </SignUpBlock>
      </ActionBlock>
    </Container>
  )
}

export default PhonePage;

const Container = styled.div`
  min-height: 408px;
`;

const Caption = styled.p`
  margin-bottom: 8px;
  line-height: 1;
  font-size: 14px;
  color: hsl(0, 0%, 47%);
`;
const Title = styled.h2`
  margin-bottom: 20px;
  line-height: 36px;
  font-size: 20px;
  font-family: 'Druk-Wide';
`;
const InputWrapper = styled.div`
  margin-bottom: 8px;
`;
const InputWrapperWithSpace = styled(InputWrapper)`
  margin-bottom: 20px;
`;
const NotificationText = styled.p`
  display: block;
  margin-bottom: 20px;
  line-height: 18px;
  font-size: 12px;
  color: hsl(0, 0%, 47%);
`;
const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const BackBlock = styled.div``;
const SignUpBlock = styled.div`
  display: flex;
  align-items: center;
`;

const RecallCode = styled.span`
  margin-right: 10px;

  line-height: 20px;
  font-size: 14px;
  color: hsla(266, 75%, 64%);
  cursor: pointer;
`;

const TimerParagraph = styled.span`
  margin-right: 20px;

  line-height: 20px;
  font-size: 14px;
  color: hsl(0, 0%, 53%);
`;

const BackButton = styled.span`
  cursor: pointer;
`;
