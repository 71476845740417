import React from 'react'
import './CastingInfoLeft.css'

function CastingInfoRight({title, number, text}) {
  let width = window.innerWidth;
  return (
    <section className='casting-info_left'>
      <div className='casting-info_left__content'>
      {width > 1024 ? <div className="casting-info_left__print_big_top"/> : null}
        <div className={number === '2' ? 'casting-info_left__info' : 'casting-info_left__info_4'}>
          <p className='casting-info_left__info_number'>{number}.</p>
          <h2 className='casting-info_left__info_heading'>{title}</h2>
          <p className='casting-info_left__info_caption'>
            {text}
          </p>
        </div>
        <div className={number === '2' ? 'casting-info_left__photo' : 'casting-info_left__photo_4'}/>

        {width > 1024 ? <div className={number === '2' ? "casting-info_left__print_big_bottom" : 'casting-info_left__print_big_bottom_4'}/> : null}
      </div>

    </section>
  )
}

export default CastingInfoRight
