import React from 'react';
import { Route, Switch, Redirect, useRouteMatch  } from 'react-router-dom';
import CommonInformationPage from './common/information/InformationPage';
import CommonIntroducePage from './common/introduce/IntroducePage';
import CommonSocialPage from './common/social/SocialPage';
import AuthLayout from '../components/layouts/authLayout';


const TalentRepSignUpPage = (props) => {
  const { path } = useRouteMatch();

  return (
    <AuthLayout location="talentrep">
      <Switch>
        <Route path={`${path}/introduce`}>
          <CommonIntroducePage occupation="talentrep" {...props} />
        </Route>
        <Route path={`${path}/information`}>
          <CommonInformationPage occupation="talentrep" {...props} />
        </Route>
        <Route path={`${path}/social`}>
          <CommonSocialPage occupation="talentrep" {...props} />
        </Route>

        <Redirect to={`${path}/introduce`} />
      </Switch>
    </AuthLayout>
  )
}

export default TalentRepSignUpPage;
