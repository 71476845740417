import React, { useState, useCallback } from 'react';
import { Route, Switch, Redirect, useRouteMatch, useHistory  } from 'react-router-dom';
import AuthLayout from '../components/layouts/mainAuthLayout';
import OccupationPage from './occupation/OccupationPage';
import FilmmakerSignUpPage from './SignUpRouteFilmmaker';
import TalentRepSignUpPage from './SignUpRouteTalentRep';
import ActorSignUpPage from './SignUpRouteActor';
import EmailPage from './email/EmailPage';
import PhonePage from './phone/PhonePage';
import { api } from '../../../utils/Api';


const SignUpPage = (props) => {
  const { setCurrentUser, logout } = props;
  const { path } = useRouteMatch();
  const history = useHistory();

  const [userInfo, setUserInfo] = useState({});

  const changeUserInfo = useCallback((value) => {
    setUserInfo(previousInfo => {
      return {
        ...previousInfo,
        ...value,
      }
    })
  }, []);

  const setUserData = useCallback((occupation) => {
    let method;
    switch (occupation) {
      case 'actor': method = 'signup_as_actor'; break;
      case 'filmmaker': method = 'signup_as_film_maker'; break;
      case 'talentrep': method = 'signup_as_talent_rep'; break;
      default: return;
    };
    api.signup(userInfo, method)
    .then(res => {
      history.push(`/auth/welcome/${occupation}`);
    });
  }, [userInfo, history]);

  return (
    <Switch>
      <Route path={`${path}/email`}>
        <AuthLayout>
          <EmailPage setCurrentUser={setCurrentUser} changeUserInfo={changeUserInfo} />
        </AuthLayout>
      </Route>
      <Route path={`${path}/phone`}>
        <AuthLayout>
          <PhonePage changeUserInfo={changeUserInfo} />
        </AuthLayout>
      </Route>
      <Route path={`${path}/occupation`}>
        <AuthLayout>
          <OccupationPage changeUserInfo={changeUserInfo} />
        </AuthLayout>
      </Route>

      <Route path={`${path}/actor`}>
        <ActorSignUpPage userInfo={userInfo} setUserInfo={changeUserInfo} setUserData={setUserData} />
      </Route>
      <Route path={`${path}/filmmaker`}>
        <FilmmakerSignUpPage userInfo={userInfo} setUserInfo={changeUserInfo} setUserData={setUserData} />
      </Route>
      <Route path={`${path}/talentrep`}>
        <TalentRepSignUpPage userInfo={userInfo} setUserInfo={changeUserInfo} setUserData={setUserData} />
      </Route>

      <Redirect to={`${path}/email`} />
    </Switch>
  )
}

export default SignUpPage;
