import React from 'react';
import discoverImage from '../../../../../images/slider/discover.png';
import ellipseImage from '../../../../../images/slider/ellipse.png';
import backgroundImage from '../../../../../images/slider/earth.png';
import phoneImage from '../../../../../images/slider/phones.png';
import {
  SlideCardWrapper,
  SlideCard,
  SlideText,
  BackgrounImageWrapper,
  BackgrounImage,
} from './styles';
import styled from 'styled-components';

const DiscoveredSlide = () => {
  return (
    <SlideCardWrapper>
      <SlideCard>
        <SlideText width="230px">
          GET DISCOVERED
        </SlideText>
        <BackgrounImageWrapper>
          <BackgrounImage src={backgroundImage} />
        </BackgrounImageWrapper>
        <TemplateImageWrapper>
          <TemplateImage src={ellipseImage} />
        </TemplateImageWrapper>
        <DiscoverImage src={discoverImage} />
        <MainImage src={phoneImage} />
      </SlideCard>
    </SlideCardWrapper>
  )
};

export default DiscoveredSlide;

const MainImage = styled.img`
  position: absolute;
  bottom: 34px;
  left: 166px;
  width: 250px;
`;

const TemplateImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 80px;
  buttom: 0;
  height: 338px;
  width: 381px;
  overflow: hidden;
`;
const TemplateImage = styled.img`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
`;

const DiscoverImage = styled.img`
  position: absolute;
  top: 90px;
  left: 70px;
`;