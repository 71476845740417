import React, { useState } from 'react';
import styled from 'styled-components';

import check from '../images/checked.png'

export const Checkbox = ({
  onChange // (bool) => void
}) => {

  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
    onChange(!isActive);
  }

  return (
    <Box active={isActive} onClick={handleClick} />
  )
}


const Box = styled.span`

  display: inline-block;
  width: 16px;
  height: 16px;

  background-image: ${({active}) => active && `url(${check})`};

  border: ${({active}) => !active && '1px solid hsla(0, 0%, 53%, 1)'};
  border-radius: 4px;
`;
