import React from 'react'
import './CastingInfoFirst.css'

function CastingInfoRight() {
  let width = window.innerWidth;
  return (
    <section className='casting-info_first'>

      <div className='casting-info_first__content'>
        <h2 className='casting-info_first__heading'>How does it work?</h2>
        <div className='casting-info_first__photo'/>
        <div className='casting-info_first__info'>
          <p className='casting-info_first__info_number'>1.</p>
          <h2 className='casting-info_first__info_heading'>Start Casting</h2>
          <p className='casting-info_first__info_caption'>
          Use the form on the website to upload your casting with the criteria for the roles. A CastingForm personal manager will contact you soon.
          </p>
        </div>
        {width > 1024 ? <div className="casting-info_first__print_big_top"/> : null}
      </div>

    </section>
  )
}

export default CastingInfoRight
