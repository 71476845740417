import React, { useState } from 'react';
import wooden from '../../../../images/slider/wooden.png';
import prevArrow from '../../../../images/icons/arrow-prev.svg';
import nextArrow from '../../../../images/icons/arrow-next.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import styled from 'styled-components';

const AuthSlider = (props) => {
  const { speed, autoPlayDelay, list } = props;
  const [swiper, setSwiper] = useState({});

  return (
    <Container>
      <WoodenTopImage src={wooden} />
      <SliderContainer>
        <Swiper
          modules={[Navigation]}
          loop={true}
          speed={speed || 1000}
          autoplay={{
            "delay": autoPlayDelay || 7000,
          }}
          onInit={(sw) => {
            setSwiper(sw)
          }}
        >
          {list.map((item, index) => (
            <SwiperSlide key={index}>
              {item}
            </SwiperSlide>
          ))}
        </Swiper>
        <SwiperActionBlock>
          <NavigationButton onClick={() => swiper.slidePrev()} img={prevArrow} />
          <NavigationButton onClick={() => swiper.slideNext()} img={nextArrow} />
        </SwiperActionBlock>
      </SliderContainer>
      <WoodenBottomImage src={wooden} />
    </Container>
  )
}

export default AuthSlider;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 738px;
  height: 556px;
  justify-content: center;
  align-items: flex-end;
`;
const WoodenTopImage = styled.img`
  position: absolute;
  top: 10px;
  left: 0;
`;
const WoodenBottomImage = styled.img`
  position: absolute;
  right: 5px;
  bottom: 30px;

  transform: rotate(-45deg);
`;
const SliderContainer = styled.div`
  width: 684px;
  margin: 0 auto;
`;

const SwiperActionBlock = styled.div`
  display: flex;
  margin: 14px auto 0 auto;
  width: 210px;
  height: 40px;
  justify-content: space-between;
`;

const NavigationButton = styled.div`
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;

  background-image: url(${({img}) => img});
  background-size: 25%;
  background-position: 50%;
  background-repeat: no-repeat;

  background-color: hsl(266, 75%, 64%);
  border-radius: 50%;

  cursor: pointer;
`;
