import React from 'react';
import Slider from '../slider/cardSlider';
import Header from '../header';
import styled from 'styled-components';


const AuthLayout = (props) => {
  const { children } = props;

  return (
    <Container>
      <Header />
      <AuthHelper>
        <TopShadow />
        <AuthHelperContent>
          <Slider />
        </AuthHelperContent>
        <BottomShadow />
      </AuthHelper>
      <AuthContent>
        {children}
      </AuthContent>
    </Container>
  )
}

export default AuthLayout;

const Container = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  justify-content: stretch;
`;

const AuthHeader = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 74px;
`;

const AuthHelper = styled.div`
  position: relative;
  display: grid;
  flex: 1 1 100%;
  padding-top: 74px;

  overflow: hidden;
`;
const AuthHelperContent = styled.section`
  height: 100%;
  padding-left: 38px;
  place-self: center;
`;

const ShadowBlock = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 133px;

  z-index: 5;
`;
const TopShadow = styled(ShadowBlock)`
  top: 74px;
  background: linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,1));
`;
const BottomShadow = styled(ShadowBlock)`
  bottom: 0;
  background: linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0));
`;

const AuthContent = styled.section`
  flex: 1 0 590px;
  display: flex;
  padding: 0 121px 40px 121px;
  align-items: center;
`;