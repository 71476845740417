import React, { useState, useCallback } from 'react';
import { useHistory  } from 'react-router-dom';
import { StepsCounter } from '../../../components/steps';
import { InputComponent } from '../../../components/inputs/Input';
import { StyledPrimaryButton } from '../../../components/buttons';

import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { 
  Container,
  StepContent,
  StepCounter,
  Caption,
  TitleWithSpace,
  InputWrapper,
  InputWrapperWithSpace,
  ActionBlock,
  BackLink,
} from '../../../components/commonStyles';
import styled from 'styled-components';

const CommonInformationPage = (props) => {
  const { userInfo, setUserInfo, occupation } = props;
  const history = useHistory();

  
  const [company, setCompany] = useState('');
  const [website, setWebsite] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  
  const onCompanyChange = useCallback((e) => {
    setCompany(e.target.value);
  }, []);

  const onWebsiteChange = useCallback((e) => {
    setWebsite(e.target.value);
  }, []);

  const selectCountry = useCallback((value) => {
    setCountry(value);
  }, []);

  const selectState = useCallback((value) => {
    setState(value);
  }, []);

  const onCityChange = useCallback((e) => {
    setCity(e.target.value);
  }, []);

  const onRedirect = useCallback((occupation) => {
    setUserInfo({
      company,
      website,
      mainLocation: {
        country,
        state,
        city,
      }
    });
    history.push(`/auth/sign-up/${occupation}/social`);
  }, [history, company, website, country, state, city]);

  return (
    <Container>
      <StepContent>
        <Caption>Sign Up</Caption>
        <TitleWithSpace>Personal information</TitleWithSpace>

        <InputWrapper>
          <InputComponent
            label="Company name"
            value={company}
            onChange={onCompanyChange}
          />
        </InputWrapper>

        <InputWrapper>
          <InputComponent
            label="Company web site"
            value={website}
            onChange={onWebsiteChange}
          />
        </InputWrapper>

        <InputWrapperWithSpace>
          <LabelWrapper>
            <InputLabel>Country</InputLabel>
          </LabelWrapper>
          <StyledCountryDropdown
            value={country}
            onChange={selectCountry}
          />
        </InputWrapperWithSpace>

        <InputWrapperWithSpace>
          <LabelWrapper>
            <InputLabel>State/Region</InputLabel>
          </LabelWrapper>
          <StyledRegionDropdown
            country={country}
            value={state}
            onChange={selectState}
          />
        </InputWrapperWithSpace>

        <InputWrapperWithSpace>
          <InputComponent
            label="City"
            value={city}
            onChange={onCityChange}
          />
        </InputWrapperWithSpace>

        <ActionBlock>
          <BackLink to={`/auth/sign-up/${occupation}/introduce`}>
            Back
          </BackLink>
          <StyledPrimaryButton
            disabled={company === '' || country === '' || city === ''}
            onClick={() => onRedirect(occupation)}
          >
            Next
          </StyledPrimaryButton>
        </ActionBlock>
      </StepContent>
      <StepCounter>
        <StepsCounter currentStep={3} length={4} />
      </StepCounter>
    </Container>
  )
}

export default CommonInformationPage;


const LabelWrapper = styled.div`
  margin-bottom: 8px;
  align-items: flex-end;
`;
const InputLabel = styled.label`
  line-height: 17px;
  font-size: 14px;
`;

const StyledCountryDropdown = styled(CountryDropdown)`
  width: 100%;
  height: 40px;
  padding: 0 12px;

  color: #fff;
  background-color: hsl(240, 3%, 11%);

  border-radius: 8px;
  border: none;
`;

const StyledRegionDropdown = styled(RegionDropdown)`
  width: 100%;
  height: 40px;
  padding: 0 12px;
  
  color: #fff;
  background-color: hsl(240, 3%, 11%);

  border-radius: 8px;
  border: none;
`;
