import React, {useState} from 'react'

import { HashLink as Link } from 'react-router-hash-link';

import './MainOfferWTS.css'
import Popup from '../Popup/Popup';

const MainOfferDir = () => {
    const [modalActive, setModalActive] = useState(false);
    let width = window.innerWidth;
    if (width > 768) {
    return (
      <div className='main-offer_wts'>

                <div className='main-offer__usp main-offer__usp_dir'>
                  <h1 className='main-offer__heading_wts'>Filmmaker</h1>
                  <p className='main-offer__caption_wts'>
                    Check our diverse talent marketplace to discover and book best candidates for your project.
                  </p>

                  <Link to='/castingdirector#sharebreakdown'>
                    <button className='main-offer__button_type_join_wts main-offer__button_type_join_dir'>Submit Breakdown</button>
                  </Link>
                </div>
                <div className='main-offer__photos_wts'>
                    <div className='main-offer__img_1 main-offer__img_1_dir'></div>
                    <div className='main-offer__img_2 main-offer__img_2_dir'></div>
                    <div className='main-offer__img_3 main-offer__img_3_dir'></div>
                </div>
        <Popup active={modalActive} setActive={setModalActive}/>
        <a href='https://api.whatsapp.com/message/XFF4NDK6T677B1' className='main-offer__button_type_chat_wts' ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.00401338 20L1.35601 15.032C0.465151 13.5049 -0.00289063 11.1024 1.34322e-05 10C1.34322e-05 4.477 4.47701 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C8.23279 20.0029 6.49667 19.5352 4.97001 18.645L0.00401338 20ZM6.39101 5.308C6.26188 5.31602 6.13569 5.35003 6.02001 5.408C5.91153 5.46943 5.81251 5.54622 5.72601 5.636C5.60601 5.749 5.53801 5.847 5.46501 5.942C5.09542 6.423 4.89662 7.01342 4.90001 7.62C4.90201 8.11 5.03001 8.587 5.23001 9.033C5.63901 9.935 6.31201 10.89 7.20101 11.775C7.41501 11.988 7.62401 12.202 7.84901 12.401C8.9524 13.3725 10.2673 14.073 11.689 14.447L12.258 14.534C12.443 14.544 12.628 14.53 12.814 14.521C13.1053 14.506 13.3896 14.4271 13.647 14.29C13.813 14.202 13.891 14.158 14.03 14.07C14.03 14.07 14.073 14.042 14.155 13.98C14.29 13.88 14.373 13.809 14.485 13.692C14.568 13.606 14.64 13.505 14.695 13.39C14.773 13.227 14.851 12.916 14.883 12.657C14.907 12.459 14.9 12.351 14.897 12.284C14.893 12.177 14.804 12.066 14.707 12.019L14.125 11.758C14.125 11.758 13.255 11.379 12.724 11.137C12.668 11.1126 12.608 11.0987 12.547 11.096C12.4786 11.089 12.4095 11.0967 12.3443 11.1186C12.2791 11.1405 12.2193 11.1761 12.169 11.223V11.221C12.164 11.221 12.097 11.278 11.374 12.154C11.3325 12.2098 11.2754 12.2519 11.2098 12.2751C11.1443 12.2982 11.0733 12.3013 11.006 12.284C10.9409 12.2666 10.877 12.2445 10.815 12.218C10.691 12.166 10.648 12.146 10.563 12.109L10.558 12.107C9.98592 11.8572 9.45624 11.5198 8.98801 11.107C8.86201 10.997 8.74501 10.877 8.62501 10.761C8.23159 10.3842 7.88873 9.95801 7.60501 9.493L7.54601 9.398C7.50364 9.33416 7.46937 9.2653 7.44401 9.193C7.40601 9.046 7.50501 8.928 7.50501 8.928C7.50501 8.928 7.74251 8.662 7.86101 8.518C7.9551 8.39832 8.04289 8.27382 8.12401 8.145C8.24201 7.955 8.27901 7.76 8.21701 7.609C7.93701 6.925 7.64701 6.244 7.34901 5.568C7.29001 5.434 7.11501 5.338 6.95601 5.319C6.90201 5.313 6.84251 5.307 6.79401 5.303C6.65972 5.29633 6.52515 5.29766 6.39101 5.307V5.308Z" fill="#43D854"/>
        </svg></a>
      </div>
    )
}  else {
  return(
    <div className='main-offer__usp_mobile'>
      <div className='main-offer__usp_mobile_top'>
        <div className='main-offer__img_1 main-offer__img_1_dir'></div>
        <div className='main-offer__img_2 main-offer__img_2_dir'></div>
      </div>
      <div className='.main-offer__usp main-offer__usp_dir'>
        <h1 className='main-offer__heading_wts'>Casting?</h1>
        <p className='main-offer__caption_wts'>Check our diverse actor database to choose the perfect person for your project.</p>
        <a href='http://onelink.to/castingform' className='main-offer__button_type_join_link' >
          <button className='main-offer__button_type_join'>Join</button>
        </a>
      </div>
      <div className='main-offer__img_3 main-offer__img_3_dir'></div>
    </div>
  )
}
}

export default MainOfferDir
