import React from 'react';

import MainOfferPP from '../../Components/MainOffer/MainOfferPP'
import PrivacyBlock from '../../Components/PrivacyBlock/PrivacyBlock'

import { Link } from 'react-router-dom'
import { onLinkNav } from '../../utils/utils'

import './PrivacyPolicy.css'


const PrivacyPolicy = () => {
  return (
    <div className="privacy">
      <MainOfferPP/>

      <ul className="privacy__list">

        <li className="privacy__item">
          <PrivacyBlock
            title="PRIVACY POLICY"
            isUpper
          >
            <p className="privacy__paragraph privacy__paragraph_mb_30">
              In this Privacy Policy (“Policy”), we describe how CastingForm Inc. (“Company” or “we” “us” “our”) collects, uses, and discloses information that
              we obtain about visitors to our website www.castingform.com (the “Site”), users of our mobile application (“App”), and the services available through
              our Site (collectively, the “Services”).
            </p>
            <p className="privacy__paragraph ">
              By visiting the Site or using our App or any of our Services, you agree that your personal information will be handled as described in this Policy.
              Your use of our Site, App, or Services, and any dispute over privacy, is subject to this Policy and our Terms of Service, including its applicable
              limitations on damages and the resolution of disputes. The
              <Link to="/terms" onClick={onLinkNav} className="privacy__link"> Terms of Use </Link>
              are incorporated by reference into this Policy.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="The Information We Collect About You"
          >
            <p className="privacy__paragraph">
              We collect information about you directly from you, from third parties, and automatically through your use of our Site, App, or Services.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Information We Collect Directly From You"
          >
            <p className="privacy__paragraph privacy__paragraph_mb_30">
              The information we collect from you depends on how you use our services. To create an account, you must provide your
            </p>
              <ul className="paragraph__list"></ul>
              <p className="privacy__paragraph privacy__paragraph_bullet">
                •	A working email address;
              </p>
              <p className="privacy__paragraph privacy__paragraph_bullet">
                •	First and last name;
              </p>
              <p className="privacy__paragraph privacy__paragraph_bullet">
                •	Preferred username and password;
              </p>
              <p className="privacy__paragraph privacy__paragraph_bullet">
                •	Mobile number;
              </p>
              <p className="privacy__paragraph privacy__paragraph_bullet">
                •	Speaking languages;
              </p>
              <p className="privacy__paragraph privacy__paragraph_bullet">
                •	Country and city where located;
              </p>
              <p className="privacy__paragraph privacy__paragraph_bullet">
                •	A valid link(s) to your social media;
              </p>
              <p className="privacy__paragraph privacy__paragraph_bullet">
                •	Language(s);
              </p>
              <p className="privacy__paragraph privacy__paragraph_bullet">
                •	Gender;
              </p>
              <p className="privacy__paragraph privacy__paragraph_bullet">
                •	Height;
              </p>
              <p className="privacy__paragraph privacy__paragraph_bullet privacy__paragraph_mb_30">
                •	Date of birth.
              </p>
              <p className="privacy__paragraph privacy__paragraph_mb_30">
                In addition, if you make a purchase on our Site, we will collect your payment information, such as your credit or debit card information and your
                billing and shipping address.
              </p>
              <p className="privacy__paragraph">
                We may collect other information from you that you choose to provide to us, including links to your social media site pages such as your Facebook,
                Instagram, and IMDb pages and any information you provide to use via a chat session on our Site or App. In addition, in connection with your use of
                the App, you may grant us access to the photos and videos stored on your device, in which case we may access those materials.
              </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Information We Collect From Other Entities"
          >
            <p className="privacy__paragraph">
              If you provide us with links to your social media site pages, we may collect information posted on those pages.
              We also collect information from public records and marketing companies. To the extent permitted by applicable law,
              we combine this information with other information we collect about you, including your personal information.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Information We Collect Automatically"
          >
            <p className="privacy__paragraph">
              We automatically collect information about your use of our Site and App through cookies, web beacons, and other technologies,
              including technologies designed for mobile apps. This includes (on the Site), your IP address, browser type of operating system,
              web pages you view on the Site, links you click on the Site, length of time you visit the Site and use the Services, the referring URL or
              website that led you to our Site. This also includes (on the App), your mobile device ID, your device name and model, your device operating
              system type, name and operating system, and your activities within the App, including the length of time that you are logged into the App
              and the pages you visit within the App. To the extent permitted by applicable law, we combine this information with other information we
              collect about you, including your personal information. Please see the section “Cookies and Other Tracking Mechanisms” below for more information
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="How We Use Your Information"
          >
            <p className="privacy__paragraph">
              We use your information, including your personal information, for the following purposes:</p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • To provide our Services to you and to fulfill your orders.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • To communicate with you about your use of our Services, to respond to your inquiries, and for other customer service purposes.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • To tailor the content and information that we may send or display to you, to offer personalized help and
              instructions, and to otherwise personalize your experiences while using the Services.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • To send you email marketing about our products and services.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • To send you news and newsletters.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • To send you email marketing about products and services of our affilated entities.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • To assist us in advertising on third party websites, mobile apps, and other online services, and to evaluate the success of our adverting
              campaigns through third party channels (including our online targeted advertising and offline promotional campaigns).
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • To better understand how users access and use our Services, both on an aggregated and individualized basis. For example, we will evaluate which
              features of our Site and App are more (or least) used by users, and we will use this information.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • To administer surveys and questionnaires, such as for market research or member satisfaction purposes.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • To comply with legal obligations, as part of our general business operations, and for other business administration purposes.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • Where we believe necessary to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving
              potential threats to the safety of any person or violations of our Terms of Service or this Policy.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="How We Share Your Information"
          >
            <p className="privacy__paragraph">
              We may share your information, including personal information, as follows:
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • Service Providers. We disclose the information we collect from you to service providers, contractors or agents who perform functions on our behalf.
              These service providers include companies that will engage in marketing on our behalf, service providers who provide cyber security-related services,
              and web/app developers.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • Affiliates. We disclose the information we collect from you to our affiliates or subsidiaries.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet privacy__paragraph_mb_30">
              • Other Users of our Services. The purpose of our Services is to connect actors and filmmakers.
              Therefore, we make your information available to other users of our Site and App to facilitate such connections.
            </p>

            <p className="privacy__paragraph">
              We also disclose information in the following circumstances:
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • Business Transfers. If (i) we or our affiliates are or may be acquired by, merged with, or invested in by another company, or (ii) if any of our
              assets are or may be transferred to another company, whether as part of a bankruptcy or insolvency proceeding or otherwise, we may transfer the
              information we have collected from you to the other company. As part of the business transfer process, we may share certain of your personal information
              with lenders, auditors, and third party advisors, including attorneys and consultants.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • In Response to Legal Process. We disclose your information to comply with the law, a judicial proceeding, court order, or other legal process, such as
              in response to a court order or a subpoena.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • To Protect Us and Others. We disclose your information when we believe it is appropriate to do so to investigate, prevent, or take action regarding
              illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of our Terms of Service or this Policy,
              or as evidence in litigation in which we are involved.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • Aggregate and De-Identified Information. We share aggregate, anonymized, or de-identified information about users with third parties for marketing, advertising,
              research or similar purposes.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Our Use of Cookies and Other Tracking Mechanisms"
          >
            <p className="privacy__paragraph privacy__paragraph_mb_40">
              We and our third party service providers use cookies and other tracking mechanisms to track information about your use of our Services. As noted above,
              we may combine this information with other personal information we collect from you (and our third party service providers may do so on our behalf).
            </p>
            <p className="privacy__paragraph privacy__paragraph_mb_30">
              Cookies. Cookies are alphanumeric identifiers that we transfer to your device’s hard drive through your web browser for record-keeping purposes.
              Some cookies allow us to make it easier for you to navigate our Site and Services, while others are used to enable a faster log-in process or to allow
              us to track your activities at our Site and Service. There are two types of cookies: session and persistent cookies.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet">
              • Session Cookies. Session cookies exist only during an online session. They disappear from your device when you close your browser or turn off your
              device. We use session cookies to allow our systems to uniquely identify you during a session or while you are logged into the Site and Services.
              This allows us to process your online transactions and requests and verify your identity, after you have logged in, as you move through our Site.
            </p>
            <p className="privacy__paragraph privacy__paragraph_bullet privacy__paragraph_mb_30">
              • Persistent Cookies. Persistent cookies remain on your device after you have closed your browser or turned off your device. We use persistent cookies
              to track aggregate and statistical information about user activity.
            </p>
            <p className="privacy__paragraph">
              Disabling Cookies. Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future.
              The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify
              you when you receive a new cookie, or how to disable cookies altogether. Visitors to our Site and Services who disable cookies will be able to browse certain
              areas of the Site, but some features may not function.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Flash Local Storage Objects"
          >
            <p className="privacy__paragraph">
              We may use Flash Local Storage Objects (“Flash LSOs”) to store your Site preferences and to personalize your visit. Flash LSOs are different from browser
              cookies because of the amount and type of data stored. Typically, you cannot control, delete, or disable the acceptance of Flash LSOs through your web
              browser. For more information on Flash LSOs, or to learn how to manage your settings for Flash LSOs, go to the Adobe Flash Player Help Page,
              choose “Global Storage Settings Panel” and follow the instructions. To see the Flash LSOs currently on your computer, choose “Website Storage Settings
              Panel” and follow the instructions to review and, if you choose, to delete any specific Flash LSO.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Clear GIFs, pixel tags and other technologies."
          >
            <p className="privacy__paragraph">
              Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which are stored on your computer’s
              hard drive, clear GIFs are embedded invisibly on web and app pages. We may use clear GIFs (a.k.a. web beacons, web bugs or pixel tags), in connection
              with our Services to, among other things, track the activities of Site visitors and App users, help us manage content, and compile statistics about
              Site and App usage. We and our third party service providers also use clear GIFs in HTML e-mails to our customers, to help us track e-mail response rates,
              identify when our e-mails are viewed, and track whether our e-mails are forwarded.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Third Party Analytics"
          >
            <p className="privacy__paragraph">
              We use automated devices and applications, such as Google Analytics, to evaluate usage of our Site, and to the extent permitted, our App. We also may use
              other analytic means to evaluate our Site and App. We use these tools to help us improve our Site’s and App’s performance and user experiences. These entities
              may use cookies and other tracking technologies, such as web beacons or local storage objects (LSOs), to perform their services. To learn more about Google’s
              privacy practices, please review the Google Privacy Policy at https://www.google.com/policies/privacy/. You can also download the Google Analytics Opt-out
              Browser Add-on to prevent their data from being used by Google Analytics at https://tools.google.com/dlpage/gaoptout.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Cross-Device Use"
          >
            <p className="privacy__paragraph">
              We and our third party service providers, including Google, may use the information that we collect about you (whether directly from our Site, from our App,
              through your device(s), or from a third party) to help us and our third party service providers identify other devices that you use (e.g., a mobile phone,
              tablet, other computer, etc.). We, and our third party service providers also may use the cross-device use and other information we learn about you to serve
              targeted advertising on your devices and to send you emails. To opt-out of cross-device advertising, you may follow the instructions set forth in the Third
              Party Ad Networks section below. Please note that if you opt-out of these targeted advertising cookies, your opt- out will be specific to the web browser,
              app, or device from which you accessed the opt-out. If you use multiple devices or web browsers, you will need to opt-out each browser or device that you use.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Do-Not-Track"
          >
            <p className="privacy__paragraph">
              Currently, our systems do not recognize browser “do-not-track” requests. You may, however, disable certain tracking as discussed in
              this section (e.g., by disabling cookies); you also may opt- out of targeted advertising by following the instructions in the Third Party Ad Network section.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Third-Party Ad Networks"
          >
            <p className="privacy__paragraph privacy__paragraph_mb_30">
              We use third parties such as network advertisers to serve advertisements on our Site, App, and on third- party websites or other media (e.g., social
              networking platforms). This enables us and these third parties to target advertisements to you for products and services in which you might be
              interested. Third-party ad network providers, advertisers, sponsors and/or traffic measurement services may use cookies, JavaScript, web beacons
              (including clear GIFs), Flash LSOs and other tracking technologies to measure the effectiveness of their ads and to personalize advertising content
              to you. These third-party cookies and other technologies are governed by each third party’s specific privacy policy, not this one. We may provide
              these third-party advertisers with information, including personal information, about you.
            </p>
            <p className="privacy__paragraph">
              Users in the United States may opt out of many third-party ad networks. For example, you may go to the Digital Advertising Alliance (“DAA”) Consumer
              Choice Page for information about opting out of interest- based advertising and their choices regarding having information used by DAA companies.
              You may also go to the Network Advertising Initiative (“NAI”) Consumer Opt-Out Page for information about opting out of interest-based advertising and
              their choices regarding having information used by NAI members. Opting out from one or more companies listed on the DAA Consumer Choice Page or the NAI
              Consumer Opt-Out Page will opt you out from those companies’ delivery of interest-based content or ads to you, but it does not mean you will no longer
              receive any advertising through our Services, or on other websites. You may continue to receive advertisements, for example, based on the particular
              website that you are viewing (i.e., contextually based ads). Also, if your browsers are configured to reject cookies when you opt out on the DAA or NAI
              websites, your opt out may not be effective. Additional information is available on the DAA’s website at www.aboutads.info or the NAI’s website at
              www.networkadvertising.org.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Third-Party Links"
          >
            <p className="privacy__paragraph">
              Our Services may contain links to third-party websites. Any access to and use of such linked websites is not governed by this Policy, but instead is
              governed by the privacy policies of those third party websites. We are not responsible for the information practices of such third party websites.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Security of My Personal Information"
          >
            <p className="privacy__paragraph privacy__paragraph_mb_30">
              We have implemented reasonable precautions to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration,
              and destruction. Please be aware that despite our best efforts, no data security measures can guarantee security.
            </p>
            <p className="privacy__paragraph">
              You should take steps to protect against unauthorized access to your password, phone, and computer by, among other things, signing off after using a shared computer,
              choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private. We are not responsible for any lost, stolen, or
              compromised passwords or for any activity on your account via unauthorized password activity.
            </p>
          </PrivacyBlock>
        </li>


        <li className="privacy__item">
          <PrivacyBlock
            title="What Choices Do I Have Regarding Use of My Personal Information?"
          >
            <p className="privacy__paragraph privacy__paragraph_mb_30">
              We may send periodic promotional emails to you. You may opt-out of promotional emails by following the opt-out instructions contained in the email.
              Please note that it may take up to 10 business days for us to process opt-out requests. If you opt-out of receiving promotional emails, we may still
              send you emails about your account or any services you have requested or received from us.
            </p>

            <p className="privacy__paragraph">
              You may also update or request that we delete the information we have collected from you by logging into your account.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Children Under 13"
          >
            <p className="privacy__paragraph">
              Our Services are not designed for children under 13. If we discover that a child under 13 has provided us with personal information, we will delete such
              information from our systems.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Special Information for California Consumers"
          >
            <p className="privacy__paragraph">
              California residents may request a list of certain third parties to which we have disclosed personally identifiable information about you for their own
              direct marketing purposes. You may make one request per calendar year. In your request, please attest to the fact that you are a California resident and
              provide a current California address for your response. You may request this information in writing by contacting us at support@castingform.com. Please
              allow up to thirty (30) days for a response.
            </p>
          </PrivacyBlock>
        </li>

        <li className="privacy__item">
          <PrivacyBlock
            title="Changes to this Policy"
          >
            <p className="privacy__paragraph">
              This Policy is current as of the Effective Date set forth above. We may change this Policy from time to time, so please be sure to check back periodically.
              We will post any changes to this Policy on our Site and App. If we make any changes to this Policy that materially affect our practices with regard to the
              personal information we have previously collected from you, we will endeavor to provide you with notice in advance of such change by highlighting the
              change on our Site and App, and via the email address we have on file for you.
            </p>
          </PrivacyBlock>
        </li>


        <li className="privacy__item">
          <PrivacyBlock
            title="Contact Us"
          >
            <p className="privacy__paragraph">
              If You have any questions about these Terms or otherwise need to contact Us for any reason, You can reach Us at:</p>
              <p className="privacy__paragraph">CastingForm, Inc.</p>
              <p className="privacy__paragraph">1901 Avenue of the Stars, Los Angeles, CA, 90067</p>
              <p className="privacy__paragraph">support@castingform.com</p>
              <p className="privacy__paragraph">http://wa.me/14245359159</p>
          </PrivacyBlock>
        </li>
      </ul>
    </div>
  )
}

export default PrivacyPolicy;
