import styled from 'styled-components';

export const SlideCardWrapper = styled.div`
  padding: 50px;
`;

export const SlideCard = styled.div`
  position: relative;
  width: 584px;
  height: 338px;
  border-radius: 12px;
  background-color: #000;
`;

export const SlideText = styled.div`
  width: ${({width}) => !!width ? width : 'auto'};
  padding: 24px;
  font-size: 24px;
  line-height: 31px;
  font-weight: bold;
  font-family: 'Druk-Wide';
  text-transform: uppercase;
  color: hsla(40, 57%, 57%, 1);
`;


export const BackgrounImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
`;

export const BackgrounImage = styled.img`
  place-self: center;
`;
