import React from 'react';
import desktopImage from '../../../../../images/slider/desktop.png';
import {
  SlideCardWrapper,
  SlideCard,
  SlideText,
} from './styles';
import styled from 'styled-components';

const DesktopSlide = () => {
  return (
    <SlideCardWrapper>
      <SlideCard>
        <SlideText width="250px">
          DESKTOP VERSION COMING<br />IN JUNE
        </SlideText>
        <MainImage src={desktopImage} />
      </SlideCard>
    </SlideCardWrapper>
  )
};

export default DesktopSlide;

const MainImage = styled.img`
  position: absolute;
  top: 18px;
  left: 190px;
  width: 440px;
`;