export const getResponse = (res) => {
  return res.ok ? res.json() : Promise.reject(`Ошибка: ${res.status}`);
}

export function onLinkNav() {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, 100);
}

export const stringWithEnglishChar = (value) => {
  return value.replace(/[^A-Za-z\s]/g,'');
};

export const getIdString = (id) => {
  const template = '0000000';
  const strId = String(id);

  return `${template.slice(0, (template.length - strId.length))}${strId}`
}

export const getTime = (num) => {
  let min = num >= 60 ? 1 : 0;
  let sec = num % 60 >= 10 ? num % 60 : `0${num % 60}`;

  return `0${min}:${sec}`
}
