import { API } from './constants';
import { getResponse } from './utils';


class Api {

  postDirectorForm(data) {
    return fetch(`${API}/create_casting_request`, {
      method: 'POST',
      headers: {
        "x-source": "site"
      },
      body: new FormData(data)
    })
      .then(getResponse);
  }

  postForm(data) {
    console.log(data)
    return fetch(`${API}/create_casting_request`, {
      method: 'POST',
      headers: {
        "x-source": "site"
      },
      body: JSON.stringify(data)
    })
      .then(getResponse);
  }




  postDemoRequest(data) {
    return fetch(`${API}/create_demo_request`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data)
    })
    .then(getResponse);
  }

  getUserById(id) {
    return fetch(`${API}/actor/dl?id=${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',

    })
    .then(getResponse);
  }


  //  REGISTRATION - LOGIN

  // REGISTRATION

  signup(data, link) {
    return fetch(`${API}/user/${link}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    }).then(getResponse);
  }

  signupEmail(email, password) {
    return fetch(`${API}/auth/signup/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    }).then(getResponse);
  }

  auth(email, password) {
    return fetch(`${API}/auth/signin/email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    }).then(getResponse);
  }

  refresh() {
    return fetch(`${API}/auth/token/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: localStorage.getItems('refresh') }),
    }).then(getResponse);
  }

  sendPhone(data) {
    return fetch(`${API}/user/send_phone_code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    }).then(getResponse);
  }

  verifyPhone(data) {
    return fetch(`${API}/user/verify_phone_code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(data),
    }).then(getResponse);
  };

  query(src, body) {
    return fetch(`${API}/${src}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(body),
    }).then(response => {
      return response.ok
        ? response.json()
        : Promise.reject(`Ошибка${response.status}: ${response.statusText}`);
    });
  };

  logOut() {
    return fetch(`${API}/logout`, {
      method: "POST",

    }).then(getResponse);
  }


  getCurrentUser() {
    return fetch(`${API}/user/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }

    }).then(getResponse);
  }

  createNewFile(data, type) {
    return fetch(`${API}/files/upload/${type}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: new FormData(data)
    })
      .then(getResponse);

  }

}

export const api = new Api();



