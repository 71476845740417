import React from 'react';
import { Route, Switch, Redirect, useRouteMatch  } from 'react-router-dom';
import CommonInformationPage from './common/information/InformationPage';
import CommonIntroducePage from './common/introduce/IntroducePage';
import CommonSocialPage from './common/social/SocialPage';
import AuthLayout from '../components/layouts/authLayout';


const FilmmakerSignUpPage = (props) => {
  const { path } = useRouteMatch();

  return (
    <AuthLayout location="filmmaker">
      <Switch>
        <Route path={`${path}/introduce`}>
          <CommonIntroducePage occupation="filmmaker" {...props} />
        </Route>
        <Route path={`${path}/information`}>
          <CommonInformationPage occupation="filmmaker" {...props} />
        </Route>
        <Route path={`${path}/social`}>
          <CommonSocialPage occupation="filmmaker" {...props} />
        </Route>

        <Redirect to={`${path}/inroduce`} />
      </Switch>
    </AuthLayout>
  )
}

export default FilmmakerSignUpPage;
