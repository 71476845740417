import React, { useState, useCallback, useEffect } from 'react';
import { Route, Switch, Redirect, useRouteMatch, useHistory  } from 'react-router-dom';
import AuthLayout from '../components/layouts/authLayout';
import ActorBasedPage from './actor/based/BasedPage';
import ActorHeadshotPage from './actor/headshot/HeadshotPage';
import ActorInformationPage from './actor/information/InformationPage';
import ActorIntroducePage from './actor/introduce/IntroducePage';
import CommonSocialPage from './common/social/SocialPage';


const ActorSignUpPage = (props) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const [isOpenHeadshots, setIsOpenHeadshots] = useState(false);

  const openHeadShots = useCallback(() => {
    setIsOpenHeadshots(true)
  }, [])

  useEffect(() => {
    setIsOpenHeadshots(false)
  }, [history])

  return (
    <AuthLayout location="actor" isOpenHeadshots={isOpenHeadshots}>
      <Switch>
        <Route path={`${path}/introduce`}>
          <ActorIntroducePage {...props} />
        </Route>
        <Route path={`${path}/information`}>
          <ActorInformationPage {...props} />
        </Route>
        <Route path={`${path}/based`}>
          <ActorBasedPage  {...props} />
        </Route>
        <Route path={`${path}/social`}>
          <CommonSocialPage occupation="actor" {...props} />
        </Route>
        <Route path={`${path}/headshot`}>
          <ActorHeadshotPage {...props} openHeadShots={openHeadShots} />
        </Route>

        <Redirect to={`${path}/introduce`} />
      </Switch>
    </AuthLayout>
  )
}

export default ActorSignUpPage;
