import React from 'react';
import backgroundImage from '../../../../../images/slider/earth.png';
import challengeImage from '../../../../../images/slider/challenges.png';
import cup from '../../../../../images/slider/cup.png'
import smallCup from '../../../../../images/slider/smallCup.png'
import {
  SlideCardWrapper,
  SlideCard,
  SlideText,
  BackgrounImageWrapper,
  BackgrounImage,
} from './styles';
import styled from 'styled-components';

const ChallengeSlide = () => {
  return (
    <SlideCardWrapper>
      <SlideCard>
        <SlideText width="270px">
          WORLD FAMOUS CASTINGFORM ACTING CHALLENGES
        </SlideText>
        <BackgrounImageWrapper>
          <BackgrounImage src={backgroundImage} />
        </BackgrounImageWrapper>
        <MainImage src={challengeImage} />
        <BigCupImage src={cup} />
        <SmallCupImage src={smallCup} />
      </SlideCard>
    </SlideCardWrapper>
  )
};

export default ChallengeSlide;

const MainImage = styled.img`
  position: absolute;
  top: 72px;
  right: 69px;
`;

const BigCupImage = styled.img`
  width: 46px;
  height: 59px;
  object-fit: cover;
  position: absolute;
  top: 232px;
  left: 39px;
`;

const SmallCupImage = styled.img`
  width: 44px;
  height: 35px;
  object-fit: cover;
  position: absolute;
  top: 242px;
  left: 220px;
`;
