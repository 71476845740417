import React from 'react';

import Actor1 from '../../../../images/welcomePage/photo1.jpg';
import Actor2 from '../../../../images/welcomePage/photo2.jpg';
import Actor3 from '../../../../images/welcomePage/photo3.jpg';
import Actor4 from '../../../../images/welcomePage/photo4.jpg';
import Actor5 from '../../../../images/welcomePage/photo5.jpg';
import Actor6 from '../../../../images/welcomePage/photo6.jpg';
import Actor7 from '../../../../images/welcomePage/photo7.jpg';
import Actor8 from '../../../../images/welcomePage/photo8.jpg';
import Actor9 from '../../../../images/welcomePage/photo9.jpg';
import Actor10 from '../../../../images/welcomePage/photo10.jpg';
import Actor11 from '../../../../images/welcomePage/photo11.jpg';
import Actor12 from '../../../../images/welcomePage/photo12.jpg';
import Actor13 from '../../../../images/welcomePage/photo13.jpg';
import Actor14 from '../../../../images/welcomePage/photo14.jpg';
import Actor15 from '../../../../images/welcomePage/photo15.jpg';
import Actor16 from '../../../../images/welcomePage/photo16.jpg';
import Actor17 from '../../../../images/welcomePage/photo17.jpg';
import Actor18 from '../../../../images/welcomePage/photo18.jpg';
import Actor19 from '../../../../images/welcomePage/photo19.jpg';
import Actor20 from '../../../../images/welcomePage/photo20.jpg';
import Actor21 from '../../../../images/welcomePage/photo21.jpg';
import Actor22 from '../../../../images/welcomePage/photo22.jpg';
import Actor23 from '../../../../images/welcomePage/photo23.jpg';
import Actor24 from '../../../../images/welcomePage/photo24.jpg';
import Actor25 from '../../../../images/welcomePage/photo25.jpg';
import Actor26 from '../../../../images/welcomePage/photo26.jpg';
import Actor27 from '../../../../images/welcomePage/photo27.jpg';
import Actor28 from '../../../../images/welcomePage/photo28.jpg';
import Actor29 from '../../../../images/welcomePage/photo29.jpg';
import Actor30 from '../../../../images/welcomePage/photo30.jpg';
import Actor31 from '../../../../images/welcomePage/photo31.jpg';
import Actor32 from '../../../../images/welcomePage/photo32.jpg';
import Actor33 from '../../../../images/welcomePage/photo33.jpg';
import Actor34 from '../../../../images/welcomePage/photo34.jpg';
import Actor35 from '../../../../images/welcomePage/photo35.jpg';
import Actor36 from '../../../../images/welcomePage/photo36.jpg';
import Actor37 from '../../../../images/welcomePage/photo37.jpg';
import Actor38 from '../../../../images/welcomePage/photo38.jpg';
import Actor39 from '../../../../images/welcomePage/photo39.jpg';
import Actor40 from '../../../../images/welcomePage/photo40.jpg';
import Actor41 from '../../../../images/welcomePage/photo41.jpg';
import Actor42 from '../../../../images/welcomePage/photo42.jpg';
import Actor43 from '../../../../images/welcomePage/photo43.png';
import Actor44 from '../../../../images/welcomePage/photo44.png';
import Actor45 from '../../../../images/welcomePage/photo45.png';
import Actor46 from '../../../../images/welcomePage/photo46.png';
import Actor47 from '../../../../images/welcomePage/photo47.png';
import Actor48 from '../../../../images/welcomePage/photo48.png';


import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';

const sliderList = [
  { id: 1, speed: 8000, delay: 0, srcList: [Actor1, Actor2, Actor3, Actor4, Actor5, Actor6] },
  { id: 2, speed: 9000, delay: 300, srcList: [Actor7, Actor8, Actor9, Actor10, Actor11, Actor12] },
  { id: 3, speed: 7000, delay: 600, srcList: [Actor13, Actor14, Actor15, Actor16, Actor17, Actor18] },
  { id: 4, speed: 9000, delay: 300, srcList: [Actor19, Actor20, Actor21, Actor22, Actor23, Actor24] },
  { id: 5, speed: 8000, delay: 0, srcList: [Actor25, Actor26, Actor29, Actor28, Actor27, Actor30] },
  { id: 6, speed: 9000, delay: 300, srcList: [Actor31, Actor32, Actor45, Actor36, Actor35, Actor37] },
  { id: 7, speed: 7000, delay: 600, srcList: [Actor34, Actor38, Actor39, Actor42, Actor41, Actor47] },
  { id: 8, speed: 9000, delay: 300, srcList: [Actor43, Actor44, Actor33, Actor46, Actor40, Actor48] },
]

const Slider = () => {
  return (
    <Container>
      <SliderContainer>
        {sliderList.map(column => (
          <SliderColumn key={column.id}>
            <Swiper
              spaceBetween={13}
              slidesPerView="auto"
              direction="vertical"
              speed={column.speed}
              freeMode={true}
              centeredSlides={false}
              autoplay={{
                "delay": column.delay,
              }}
            >
              {column.srcList.map((src, index) =>(
                <SwiperSlide key={`${column.id}${index}`}>
                  <SlideImage src={src} />
                </SwiperSlide>
              ))}
            </Swiper>
          </SliderColumn>
        ))}
      </SliderContainer>
    </Container>
  )
}

export default Slider;

const Container = styled.div`

`;
const SliderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 13px;
`;

const SliderColumn = styled.div`
  width: 100%;
  height: 100%;
  max-width: 150px;

  overflow: hidden;
`;
const SlideImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  max-height: 225px;
  border-radius: 12px;
`;
