import { InputBase } from '@mui/material';
import React from 'react';
import styled from 'styled-components';


export const InputComponent = (props) => {
  const { label, type, value, placeholder, error, disabled, onChange, onBlur } = props;

  return (
    <>
      {!!label && <InputLabel>{label}</InputLabel>}
      <StyledInput
        type={type || 'text'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={!!error}
        placeholder={placeholder}
        disabled={disabled}
      />
      <Error>{error}</Error>
    </>
  )
}


export const InputLabel = styled.label`
  display: inline-block;
  margin-bottom: 8px;
  line-height: 17px;
  font-size: 14px;
`;
const StyledInput = styled(InputBase)`
  &.MuiInputBase-root {
    height: 40px;
    width: 100%;
    padding: 10px 12px;
    border-radius: 8px;
    font-size: 14px;
    color: hsla(0, 0%, 83%, 1);
    background-color: hsla(240, 3%, 11%, 1);
    ${({error}) => error ? 'border: 1px solid hsl(0, 83%, 55%);': ''}
    -webkit-user-select: text;
  }

  .MuiInputBase-input {
    -webkit-user-select: text;
  }
  &.Mui-disabled {
    color: hsla(0, 0%, 53%, 1);
  }
`;
const Error = styled.span`
  display: block;
  margin-top: 4px;
  width: 100%;
  height: 17px;
  line-height: 17px;
  font-size: 14px;
  color: hsl(0, 83%, 55%);
`;
