import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { HeightInput } from '../../../components/inputs/HeightInput';
import { Select } from '../../../components/select/Select';
import { ManyValuesBlock } from '../../../components/manyValuesBlock/ManyValuesBlock';
import { StepsCounter } from '../../../components/steps';
import { DateInput } from '../../../components/inputs/DateInput';
import { StyledPrimaryButton } from '../../../components/buttons';
import {
  Container,
  StepContent,
  StepCounter,
  Caption,
  TitleWithSpace,
  InputWrapperWithSpace,
  ActionBlock,
  BackLink,
} from '../../../components/commonStyles';
import { format, isValid } from 'date-fns';

import { genderValues } from '../../../utils/mockGenders';

const disabilities = ['Motor', 'Hard of Hearing', 'Vision', 'Cognitive', 'Speech impediment', 'Deaf', 'Little people', 'Other'];

const ActorInformationPage = (props) => {
  const { userInfo, setUserInfo } = props;
  const history = useHistory();

  const [height, setHeight] = useState();
  const [gender, setGender] = useState('');
  const [currentDisabilities, setCurrentDisabilities] = useState([]);
  const [birthDate, setBirthDate] = useState(new Date());

  const handleChangeHeightValue = useCallback((value) => {
    setHeight(value);
  }, []);

  const onGenderChange = useCallback((value) => {
    setGender(value);
  }, []);

  const onBirthDateChange = useCallback((value) => {
    setBirthDate(value);
  }, []);

  const onRedirect = () => {
    setUserInfo({
      gender,
      height,
      disability: currentDisabilities[0],
      dob: format(birthDate, 'yyyy-MM-dd'),
    })
    history.push(`/auth/sign-up/actor/based`);
  };

  return (
    <Container>
      <StepContent>
        <Caption>Sign Up</Caption>
        <TitleWithSpace>Personal information</TitleWithSpace>

        <DateInput
          onChange={onBirthDateChange}
          error={!isValid(birthDate)}
        />

        <TextContainer>
          Please enter you real age for legal purposes. Only playing age will be displayed.
        </TextContainer>

        <InputWrapperWithSpace>
          <HeightInput onChange={handleChangeHeightValue} error=''/>
        </InputWrapperWithSpace>

        <InputWrapperWithSpace>
          <Select
            label="Gender"
            value={gender}
            options={genderValues}
            onSelect={onGenderChange}
          />
        </InputWrapperWithSpace>

        <DisabilitiesContainer>
          <Label>Disability / special needs</Label>
          <ManyValuesBlock
            list={disabilities}
            values={currentDisabilities}
            onChange={setCurrentDisabilities}
          />
        </DisabilitiesContainer>


        <ActionBlock>
          <BackLink to="/auth/sign-up/actor/introduce">Back</BackLink>
          <StyledPrimaryButton
            disabled={ gender === '' }
            onClick={() => onRedirect()}
          >
            Next
          </StyledPrimaryButton>
        </ActionBlock>
      </StepContent>
      <StepCounter>
      <StepsCounter currentStep={3} length={6} />
      </StepCounter>
    </Container>
  )
}

export default ActorInformationPage;

const TextContainer = styled.p`
  margin-bottom: 24px;

  font-family: 'Formular';
  line-height: 17px;
  font-size: 14px;
  color: hsla(0, 0%, 53%, 1);
`;

const Label = styled.p`
  margin: 0;
  margin-bottom: 8px;

  font-family: 'Formular';
  line-height: 16px;
  font-size: 12px;
  color: hsla(0, 0%, 53%, 1);
`
const DisabilitiesContainer = styled.div`
  margin-bottom: 46px;
`
