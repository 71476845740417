import React from 'react';
import './Principles.css';

function Principles() {
  let width = window.innerWidth;
  return (
    <div className='principles'>
      <div className='principles__block'>
        <h2 className='principles__heading'>We stand for</h2>

        <div className='principles__items'>

          <div className='principles__item'>
            <p className='principles__item_heading'>Equity</p>
            {width > 1024 ?
            <p className='principles__item_caption'>
              CastingForm’s technology is fair and impartial. Our algorythms provide you with an ability to promote your talent and get discovered.
            </p>
            :
            <p className='principles__item_caption'>
              CastingForm’s technology is fair and impartial. Our algorithms provide you with an ability to promote your talent and get discovered. Equity is about ensuring that every individual has an equal opportunity to make the most of their talents and opportunities.
            </p>
            }
          </div>

          <div className='principles__item'>
            <p className='principles__item_heading'>Equality</p>
            <p className='principles__item_caption'>
            Equality is about ensuring that every individual has an equal opportunity to make the most of their lives and talents.
            </p>
          </div>

          <div className='principles__item'>
            <p className='principles__item_heading'>Diversity</p>
            <p className='principles__item_caption'>
            Including or involving people from a range of different social and ethnic backgrounds and of different genders, sexual orientations.
            </p>
          </div>

          <div className='principles__item'>
            <p className='principles__item_heading'>Inclusion</p>
            <p className='principles__item_caption'>
            Providing equal access to opportunities and resources for everyone and everywhere. For us each and every talent is unique.
            </p>
          </div>

        </div>

      </div>
    </div>
  )
}

export default Principles
