import React from 'react';
import styled from 'styled-components';


export const StepsCounter = ({
  currentStep,
  length
}) => {


  const pointList = Array.from({ length }, (value, index) => index + 1);

  return (
    <StepList>
      {pointList.map((item) => (

        <StepItem
          key={item}
          isCurrent={item === currentStep}
          isPrevious={item <= currentStep}
        >
          {item === currentStep ? item : ''}
        </StepItem>

      ))}
    </StepList>
  )
}

export const StepList = styled.ul`
  display: flex;
  margin: 0 auto;
  padding: 0;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  list-style: none;
`;

export const StepItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isCurrent }) => isCurrent ? '25' : '6'}px;
  height: ${({ isCurrent }) => isCurrent ? '25' : '6'}px;

  background-color: ${({ isPrevious }) => isPrevious ? 'hsl(266, 75%, 64%)' : 'hsl(0, 0%, 53%)'};

  border-radius: 50%;
`;
