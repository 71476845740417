import React, { useState, useCallback } from 'react';
import { useHistory  } from 'react-router-dom';
import { InputComponent } from '../../../components/inputs/Input';
import { DateInput } from '../../../components/inputs/DateInput';
import { Select } from '../../../components/select/Select';
import { StepsCounter } from '../../../components/steps';
import { StyledPrimaryButton } from '../../../components/buttons';
import { stringWithEnglishChar } from '../../../../../utils/utils';
import {
  Container,
  StepContent,
  StepCounter,
  Caption,
  TitleWithSpace,
  InputWrapper,
  InputWrapperWithSpace,
  ActionBlock,
  BackLink,
} from '../../../components/commonStyles';
import { format, isValid } from 'date-fns';

import { genderValues } from '../../../utils/mockGenders';




const CommonIntroducePage = (props) => {
  const { userInfo, setUserInfo, occupation } = props;
  const history = useHistory();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState(new Date());
  const [gender, setGender] = useState('');
  const [validationError, setValidationError] = useState('');

  const onFirstNameChange = useCallback((e) => {
    setFirstName(stringWithEnglishChar(e.target.value));
  }, []);

  const onLastNameChange = useCallback((e) => {
    setLastName(stringWithEnglishChar(e.target.value));
  }, []);

  const onBirthDateChange = useCallback((value) => {
    setBirthDate(value);
  }, []);

  const onGenderChange = useCallback((value) => {
    setGender(value);
  }, []);

  const onRedirect = useCallback((firstName, lastName, birthDate, gender) => {
    setUserInfo({
      firstName,
      lastName,
      birthDate: format(birthDate, 'yyyy-MM-dd'),
      gender,
    });
    history.push(`/auth/sign-up/${occupation}/information`);
  }, [setUserInfo, occupation, history]);

  return (
    <Container>
      <StepContent>
        <Caption>Sign Up</Caption>
        <TitleWithSpace>Introduce yourself</TitleWithSpace>

        <InputWrapper>
          <InputComponent
            label="First name"
            value={firstName}
            onChange={onFirstNameChange}
          />
        </InputWrapper>

        <InputWrapper>
          <InputComponent
            label="Last name"
            value={lastName}
            onChange={onLastNameChange}
          />
        </InputWrapper>

        <InputWrapper>
          <DateInput
            onChange={onBirthDateChange}
            error={!isValid(birthDate)}
          />
        </InputWrapper>

        <InputWrapperWithSpace>
          <Select
            label="Gender"
            value={gender}
            options={genderValues}
            onSelect={onGenderChange}
          />
        </InputWrapperWithSpace>

        <ActionBlock>
          <BackLink to="/auth/sign-up/occupation">
            Back
          </BackLink>
          <StyledPrimaryButton
            disabled={ firstName === '' || lastName === '' || gender === '' }
            onClick={() => onRedirect(firstName, lastName, birthDate, gender)}
          >
            Next
          </StyledPrimaryButton>
        </ActionBlock>
      </StepContent>
      <StepCounter>
        <StepsCounter currentStep={2} length={4} />
      </StepCounter>
    </Container>
  )
}

export default CommonIntroducePage;
