import React from 'react';
import background from '../../../../../images/slider/star.png';
import {
  SlideCardWrapper,
  SlideCard,
  SlideText,
  BackgrounImageWrapper,
  BackgrounImage,
} from './styles';

const PersonSlide = () => {
  return (
    <SlideCardWrapper>
      <SlideCard>
        <SlideText width="280px">
          Be the star you were born to be
        </SlideText>
        <BackgrounImageWrapper>
          <BackgrounImage src={background} />
        </BackgrounImageWrapper>
      </SlideCard>
    </SlideCardWrapper>
  )
};

export default PersonSlide;