import React from 'react';
import AuthSlider from '../slider/authSlider';
import PersonSlide from '../slider/slides/personSlide';
import StudiosSlide from '../slider/slides/studiosSlide';
import CastingSlide from '../slider/slides/castingSlide';
import ChallengeSlide from '../slider/slides/challengeSlide';
import DiscoveredSlide from '../slider/slides/discoveredSlide';
import DesktopSlide from '../slider/slides/desktopSlide';
import MobileSlide from '../slider/slides/mobileSlide';
import Header from '../header';
import styled from 'styled-components';
import { RightHeadshot } from '../rightHeadshot/RightHeadshot';
import wooden from "../../../../images/slider/wooden.png";
import iphoneImage from "../../../../images/registration/ios.png";
import qrImage from "../../../../images/slider/qr.png";
import googleImage from "../../../../images/slider/google-app.png";
import appleImage from "../../../../images/slider/apple-app.png";
import {
  AppBanner,
  UniversalMobileLink
} from "../../welcome/WelcomePage";
import desktopImage from "../../../../images/slider/desktop.png";

const actorSlideList = [PersonSlide, StudiosSlide, CastingSlide, ChallengeSlide, DiscoveredSlide];
const filmmakerSlideList = [StudiosSlide, CastingSlide, ChallengeSlide];
const welcomeSlideList = [DesktopSlide, MobileSlide];

const getActualSlideList = (location) => {
  switch (location) {
    case 'actor': return actorSlideList;
    case 'welcome': return welcomeSlideList;
    default: return filmmakerSlideList;
  }
}

const AuthLayout = (props) => {
  const { location, children, isOpenHeadshots, isNftLayout } = props;

  return (
    <Container>
      <Header withLogout={location === 'welcome'} />
      <AuthHelper isNftLayout={isNftLayout}>
        <AuthHelperContent>
          {isNftLayout ?
            <Information>
              <WoodenTopImage src={wooden} />
              <InfoCol>
                <InfoTitle>
                  Casting Directors are looking for you.
                  <br/>
                  You will be notified immediately when
                  <br/>
                  you get audition request.
                </InfoTitle>
                <InfoRow>
                  <ImageContainer>
                    <IphoneImage src={iphoneImage}/>
                  </ImageContainer>
                  <DesktopNftInfo></DesktopNftInfo>
                </InfoRow>
                <InfoRow>
                  <LinksImages>
                    <QrImage src={qrImage} />
                    <InfoLinks>
                      <UniversalMobileLink to='http://onelink.to/CastingForm'>
                        <AppBanner src={googleImage} />
                      </UniversalMobileLink>
                      <UniversalMobileLink to='http://onelink.to/CastingForm'>
                        <AppBanner src={appleImage} />
                      </UniversalMobileLink>
                    </InfoLinks>
                  </LinksImages>
                  <InfoDescription>
                    Don’t miss your audition request. Scan this QR code
                    <br/>
                    and download CastingForm on your mobile device
                    <br/>
                    to access your account anytime.
                  </InfoDescription>
                </InfoRow>
              </InfoCol>

              <WoodenBottomImage src={wooden} />
            </Information>
            : (location === 'actor' && isOpenHeadshots)
              ? <RightHeadshot />
              : <AuthSlider list={getActualSlideList(location)} />

          }
          {/*{!hasNftInformation && (location === 'actor' && isOpenHeadshots)*/}
          {/*  ? <RightHeadshot />*/}
          {/*  : <AuthSlider list={getActualSlideList(location)} />*/}
          {/*}*/}
        </AuthHelperContent>
      </AuthHelper>
      <AuthContent>
        {children}
      </AuthContent>
    </Container>
  )
}

const qwe = () => {

}

export default AuthLayout;

const Information = styled.section`
  position: relative;
  display: flex;
  width: 738px;
  height: 554px;
  justify-content: center;
  align-items: flex-end;
`;

const InfoTitle = styled.h1`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  font-family: 'Druk-Wide';
  width: 100%;
  max-width: 497px;
  margin: 0 auto 56px;
`;

const InfoDescription = styled.h2`
  font-family: 'Formular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  width: 100%;
  max-width: 397px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: translateY(-100%);
`;

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
`;

const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IphoneImage = styled.img`
  max-width: 195.72px;
  max-height: 406.64px;
`;

const LinksImages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  margin-top: 20px;
`;

const InfoLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: stretch;
  height: fit-content;
`;

const AuthHelper = styled.div`
  position: relative;
  display: grid;
  flex: 1 1 100%;
  padding-top: 74px;
  ${({width}) => !!width ? width : 'auto'};
  height: 100vh;
  min-height: ${({isNftLayout}) => !!isNftLayout ? '875px' : '100vh'};

  background-color: hsl(240, 2%, 5%);

  overflow: hidden;
`;
const AuthHelperContent = styled.section`
  padding: 38px 38px 76px;
  place-self: center;
`;

const AuthContent = styled.section`
  flex: 1 0 590px;
  display: flex;
  padding: 104px 121px 60px 121px;
  align-items: center;
  position: relative;
  background: #000000;
`;

const WoodenTopImage = styled.img`
  position: absolute;
  top: -12px;
  left: -25px;
`;
const WoodenBottomImage = styled.img`
  position: absolute;
  //right: 5px;
  //bottom: 30px;
  right: -24px;
  bottom: 50px;

  transform: rotate(-45deg);
`;

const DesktopNftInfo = () => {
  return (
    <SlideCardWrapper>
      <SlideCard>
        <SlideText>
          DESKTOP VERSION COMING SOON
        </SlideText>
        <MainImage src={desktopImage} />
      </SlideCard>
    </SlideCardWrapper>
  )
};

const SlideCardWrapper = styled.div`
  padding: 24px 0 0 36px;
`;

const SlideCard = styled.div`
  position: relative;
  width: 454px;
  height: 297.97px;
  border-radius: 12px;
  background-color: #000;
`;

const SlideText = styled.div`
  width: 151px;
  padding: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #D0A653;
  font-family: 'Druk-Wide';
  text-transform: uppercase;
`;



const MainImage = styled.img`
  position: absolute;
  top: 27px;
  left: 92px;
  width: 360px;
`;

const QrImage = styled.img`
  width: 99px;
`;
