import React from 'react';

import FaqListItem from '../FaqListItem/FaqListItem';

import './Faq.css';

const Faq = () => {
  return (
    <section id="FAQ" className="faq" >
      <div className="overlay" />
      <div className="faq__container">
        <h2 className="faq__title">Faq</h2>
        <ul className="faq__list">
          <li className="faq__item">
            <FaqListItem
              question="How does CastingForm work?"
              answer="You create an account, add your photos and send the account for verification. After that you can take part in challenges to make your profile remarkable and get the ability to take part in film castings."
            />
          </li>
          <li className="faq__item">
            <FaqListItem
              question="I signed up for CastingForm, what should I do next?"
              answer="Congratulations - you’ve already upped your chances of getting your first role. Now when a casting director finds you perfectly fitting for a role, you will be notified of the upcoming casting. What you can do now is check our Instagram for the upcoming Acting Challenges, join them, have fun acting and increase your visibility to filmmakers using CastingForm."
            />
          </li>
          <li className="faq__item">
            <FaqListItem
              question="What will I get with a Premium account?"
              answer="A Premium account allows you to upgrade your profile: upload videos, have a direct link to your profile to share. We also offer exclusive workshops and sessions with casting directors for Premium members."
            />
          </li>
          <li className="faq__item">
            <FaqListItem
              question="Does Premium account guarantee closed role deals?"
              answer="No, we have equal rights to participate in challenges and castings with both premium and free accounts. We also offer deal support contracts, document flow and visa process help."/>

          </li>
          <li className="faq__item">
            <FaqListItem
              question="Why hasn't my profile passed the account verification process?"
              answer="We were unable to verify your acting experience. Please check if the information you provided is correct."
            />
          </li>
          <li className="faq__item">
            <FaqListItem
              question="What are requirements for my headshots?"
              answer="The photos should be clear and well-lit, not underexposed or overexposed, not blurry, shaded or black&white. Please make sure not to use a white background. The perfect headshot is when you are facing the camera and your face, skin, hair and eye color are clearly seen."
            />
          </li>
          <li className="faq__item">
            <FaqListItem
              question="How many roles can I get using CastingForm?"
              answer="We don’t guarantee roles offered through our service. We provide casting information and challenges to take part in to increase your chances of being seen and getting selected by directors."
            />
          </li>
          <li className="faq__item">
            <FaqListItem
              question="How many photos do I need to upload to attract a producer?"
              answer="One is necessary, the maximum number is 5."
            />
          </li>
          <li className="faq__item">
            <FaqListItem
              question="My account has been suspended. Why did this happen?"
              answer="Actor’s account may be suspended if your main headshot in the gallery doesn’t meet our requirements for the following reasons:"
            >
              <ul className="answer__list">
                <li className="answer__item">unsatisfactory image quality (blurry, pixeled, unfocused, shaded, over-edited, overexposed, underexposed etc.);</li>
                <li className="answer__item">impossible to identify face, skin, hair, eye color (black and white, closed eyes, sunglasses, not facing a camera, covered face, full body shot, too far/close etc.);</li>
                <li className="answer__item">headshot is missing (empty photo, stills, art, costumed, fake and random images).</li>
              </ul>
            </FaqListItem>
          </li>
          <li className="faq__item">
            <FaqListItem
              question="Which filmmakers and casting directors use CastingForm? Which studios will be able to see me in the database?"
              answer="We have successfully helped actors to get jobs in international productions for Netflix, HBO, Amazon, NatGeo, Fox 21 Television Studios, Bad Wolf, Millennium Films and many more."
            />
          </li>
          <li className="faq__item">
            <FaqListItem
              question="How long will it take me to get my first role?"
              answer="Although we do not guarantee that our actors get roles, our users typically land their first role within 5-6 months, sometimes even in one month! Again, a lot depends on the actor’s initiative, so we encourage you to take part in our challenges to up your chances to be noticed."
            />
          </li>
          <li className="faq__item">
            <FaqListItem
              question="I haven’t found the answer to my question here, what should I do?"
              answer="You can always contact our Support team via Whatsapp, we are happy to answer your questions and help you on your way to your first role!"
            />
          </li>
        </ul>
        {/* <div className="faq___print_small" /> */}
      </div>

    </section>
  )

}

export default Faq;
