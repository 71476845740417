import React from 'react';
import './WorkScheme.css';

import { Swiper, SwiperSlide } from "swiper/react";
import "../MainOffer/MySwiper.css";
import 'swiper/css';
import SwiperCore, {
  Autoplay,Navigation
} from 'swiper';

SwiperCore.use([Autoplay,Navigation]);

function WorkScheme() {
  let width = window.innerWidth;
  return (
    <div className="work-scheme__background">
      <div className='work-scheme'>
        <div className="work_scheme_print_top" />
        <div className='work-scheme__heading_block'>
          <h2 className='work-scheme__heading'>How does it work?</h2>
          {/* <button type="button" className='work-scheme__button_type_video'>Watch the video</button> */}
        </div>

        {width > 1024 ?
        <div className='work-scheme__stages'>

          <div className='work-scheme__stage'>
            <p className='work-scheme__stage_number'>1.</p>
            <h3 className='work-scheme__stage_heading'>Create account</h3>
            <p className='work-scheme__stage_caption'>
              Download the CastingForm app on your mobile device. It's available in the Apple App Store and on Google Play. A desktop version is coming soon.
            </p>
          </div>

          <div className='work-scheme__stage'>
            <p className='work-scheme__stage_number'>2.</p>
            <h3 className='work-scheme__stage_heading'>Keep it current</h3>
            <p className='work-scheme__stage_caption'>
              Add your headshots, slate, reel and audition monologues. Keep your profile updated with all of your most current material. You can link your
              social media too!
            </p>
          </div>

          <div className='work-scheme__stage'>
            <p className='work-scheme__stage_number'>3.</p>
            <h3 className='work-scheme__stage_heading'>Get discovered</h3>
            <p className='work-scheme__stage_caption'>
              CastingForm will verify that you're available to work with filmmakers from across the globe. Visit your notifications. CastingForm's Virtual Talent
              Manager will send you audition requests inside the app.
            </p>
          </div>

          <div className='work-scheme__stage'>
            <p className='work-scheme__stage_number'>4.</p>
            <h3 className='work-scheme__stage_heading'>Selftape</h3>
            <p className='work-scheme__stage_caption'>
              When you get an audition, upload and submit your material from the CastingForm App. Self-tape your audition, then upload and submit the video to the
              casting director. It's easy, just be aware of the deadline for submissions
            </p>
          </div>

          <div className='work-scheme__stage'>
            <p className='work-scheme__stage_number'>5.</p>
            <h3 className='work-scheme__stage_heading'>Book a job</h3>
            <p className='work-scheme__stage_caption'>
              CastingForm handles everything - callbacks, final offers, even travel arrangements to the production location. CastingForm is your virtual talent manager.
            </p>
          </div>

        </div>
        :
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          slidesPerView={'auto'}
          speed={1000}
          autoplay={{
            "delay": 5500,
            "disableOnInteraction": false
          }}
          //on/off slider buttons
          navigation={false}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className='work-scheme__stage'>
              <p className='work-scheme__stage_number'>1.</p>
              <h3 className='work-scheme__stage_heading'>Create account</h3>
              <p className='work-scheme__stage_caption'>
                Download the CastingForm app on your mobile device. It's available in the Apple App Store and on Google Play. A desktop version is coming soon.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='work-scheme__stage'>
              <p className='work-scheme__stage_number'>2.</p>
              <h3 className='work-scheme__stage_heading'>Keep it current</h3>
              <p className='work-scheme__stage_caption'>
                Add your headshots, slate, reel and audition monologues. Keep your profile updated with all of your most current material. You can link your
                social media too!
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='work-scheme__stage'>
              <p className='work-scheme__stage_number'>3.</p>
              <h3 className='work-scheme__stage_heading'>Get discovered</h3>
              <p className='work-scheme__stage_caption'>
                CastingForm will verify that you're available to work with filmmakers from across the globe. Visit your notifications. CastingForm's Virtual Talent
                Manager will send you audition requests inside the app.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='work-scheme__stage'>
              <p className='work-scheme__stage_number'>4.</p>
              <h3 className='work-scheme__stage_heading'>Selftape</h3>
              <p className='work-scheme__stage_caption'>
                When you get an audition, upload and submit your material from the CastingForm App. Self-tape your audition, then upload and submit the video to the
                casting director. It's easy, just be aware of the deadline for submissions
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='work-scheme__stage'>
              <p className='work-scheme__stage_number'>5.</p>
              <h3 className='work-scheme__stage_heading'>Book a job</h3>
              <p className='work-scheme__stage_caption'>
                CastingForm handles everything - callbacks, final offers, even travel arrangements to the production location. CastingForm is your virtual talent manager.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
        }

        <div className="work_scheme_print_small" />
        <div className={width > 1024 ? "work_scheme_print_big" : null} />

      </div>
    </div>
  )
}

export default WorkScheme
