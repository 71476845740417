import React, { useState, useCallback } from 'react';
import { Link, useHistory  } from 'react-router-dom';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

import { StepsCounter } from '../../../components/steps';
import { StyledPrimaryButton } from '../../../components/buttons';
import { InputComponent } from '../../../components/inputs/Input';
import styled from 'styled-components';

import {
  Container,
  StepContent,
  StepCounter,
  Caption,
  TitleWithSpace,
  InputWrapper,
  InputWrapperWithSpace,
  ActionBlock,
  BackLink,
} from '../../../components/commonStyles';

const ActorBasedPage = (props) => {
  const { userInfo, setUserInfo } = props;
  const history = useHistory();

  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [currentCity, setCurrentCity] = useState('');

  const [citizenship, setCitizenship] = useState('');
  const [workAuth, setWorkAuth] = useState('');


  const handleChangeCurrentCity = useCallback((e) => {
    setCurrentCity(e.target.value)

  }, [])




  const onRedirect = useCallback(() => {

    setUserInfo({
      mainLocation: {
        country,
        state: region,
        city: currentCity,
      },
      citizenship,
      workAuthorization: workAuth
    })

    history.push(`/auth/sign-up/actor/social`);
  }, [citizenship, country, currentCity, history, region, workAuth]);



  return (
    <Container>
      <StepContent>
        <Caption>Sign Up</Caption>
        <TitleWithSpace>Where are you based</TitleWithSpace>
        <FormTitle>Location</FormTitle>
        <InputWrapperWithSpace>
          <Label>Country</Label>
          <StyledCountryDropdown
            value={country}
            onChange={(val) => setCountry(val)}
            />
        </InputWrapperWithSpace>
        <InputWrapperWithSpace>
          <Label>State / Region</Label>
          <StyledRegionDropdown
          country={country}
          value={region}

          onChange={(val) => setRegion(val)} />
        </InputWrapperWithSpace>
        <InputWrapperWithSpace>
          <InputComponent
            label="City"
            value={currentCity}
            onChange={handleChangeCurrentCity}
          />
        </InputWrapperWithSpace>

        <InputWrapperWithSpace>
          <Label>Citizenship</Label>
          <StyledCountryDropdown
            value={citizenship}
            onChange={(val) => setCitizenship(val)}
            />
        </InputWrapperWithSpace>

        <InputWrapperWithSpace>
          <Label>Work Authorization</Label>
          <StyledCountryDropdown
            value={workAuth}
            onChange={(val) => setWorkAuth(val)}
            defaultOptionLabel=''
            />
        </InputWrapperWithSpace>

        <CurrentActionBlock>
          <BackLink to="/auth/sign-up/actor/information">Back</BackLink>
          <StyledPrimaryButton
            disabled={ currentCity === '' || country === '' || citizenship === '' }
            onClick={() => onRedirect()}
          >
            Next
          </StyledPrimaryButton>
        </CurrentActionBlock>
      </StepContent>
      <StepCounter>
      <StepsCounter currentStep={4} length={6} />
      </StepCounter>
    </Container>
  )
}

export default ActorBasedPage;


const ContainerSelect = styled.div`
  margin-bottom: 20px;
`

const Label = styled.label`
  margin-bottom: 8px;
  display: block;

  color: hsla(0, 0%, 100%, 1);
  font-size: 14px;
  line-height: 16px;
  font-family: 'Formular';

`

const CurrentActionBlock = styled(ActionBlock)`
  margin-top: 46px;
`
const FormTitle = styled.h2`
  margin: 0;
  margin-bottom: 20px;

  font-family: 'Formular';
  font-size: 16px;
  line-height: 20px;
`

const StyledCountryDropdown = styled(CountryDropdown)`
  width: 100%;
  height: 40px;
  padding: 0 12px;

  color: #fff;
  background-color: hsl(240, 3%, 11%);

  border-radius: 8px;
  border: none;
`;

const StyledRegionDropdown = styled(RegionDropdown)`
  width: 100%;
  height: 40px;
  padding: 0 12px;

  color: #fff;
  background-color: hsl(240, 3%, 11%);

  border-radius: 8px;
  border: none;
`;
