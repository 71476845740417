import React from 'react'
import './Slate.css'
import Play from '../../images/where/play.svg'

function Slate({title, number, text, print=false}) {
  let width = window.innerWidth;
  return (
    <section className='slate'>
      <div className='slate__content'>
        <div className='slate__photos'>
          <div className={number === '2' ? 'slate__photo_top': 'slate__photo_top_4'}>
            <img className='slate__photo_button_type_play' src={Play} alt={Play} />
          </div>
          <div className={number === '2' ? 'slate__photo_center' : 'slate__photo_center_4'}/>
          <div className={number === '2' ? 'slate__photo_bottom': 'slate__photo_bottom_4'}>
            <img className='slate__photo_button_type_play' src={Play} alt={Play} />
          </div>
        </div>
        <div className='slate__info'>
          <p className='slate__info_number'>{number}.</p>
          <h2 className='slate__info_heading'>{title}</h2>
          <p className='slate__info_caption'>
            {text}
          </p>
        </div>
        {print && width > 1024 ? <div className="slate__print_small_top"/> : null}
        {width > 1024 ? <div className="slate__print_big_bottom"/> : null}
      </div>

    </section>
  )
}

export default Slate

