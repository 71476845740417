import {NFT_API} from "./constants";
import {getResponse} from "./utils";


class NftApi {
  getNftByUserId(userId) {
    return fetch(`${NFT_API}/sbt/${userId}/`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',

    })
      .then(getResponse);
  }

  setNftByUser(userId, userData) {
    return fetch(`${NFT_API}/sbt/${userId}/`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(userData),
    })
      .then(getResponse);
  }
}

export const nftApi = new NftApi();



