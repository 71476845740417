import React, {useState} from 'react';
import './Statistics.css';
import Popup from '../Popup/Popup';

const Statistics = () => {
  const [modalActive, setModalActive] = useState(false);
  let width = window.innerWidth;
  return (
    <div className='statistics'>
      <p className='statistics__heading'>
      CastingForm is the #1 platform to discover and book actors globally.
      </p>

      <div className='statistics__data'>
        <div className='statistics__data_block'>
          <p className='statistics__data_block_number'>108,281</p>
          <p className='statistics__data_block_caption'>actors</p>
        </div>

        <div className='statistics__data_block'>
          <p className='statistics__data_block_number'>241</p>
          <p className='statistics__data_block_caption'>filmmakers</p>
        </div>

        <div className='statistics__data_block'>
          <p className='statistics__data_block_number'>124</p>
          <p className='statistics__data_block_caption'>countries</p>
        </div>

        <div style={{display: 'none'}} className='statistics__data_block'>
          <p style={{display: 'none'}} className='statistics__data_block_number'>14</p>
          <p style={{display: 'none'}} className='statistics__data_block_caption'>deals</p>
        </div>
      </div>
      <div className='statistics__button_block'>
      <button className='statistics__button_type_join' onClick={() => setModalActive(true)} type='button'>Join</button>
      </div>
      <Popup active={modalActive} setActive={setModalActive}/>
      <div className={width < 1024 ? "statistics_print_small" : null} />
    </div>
  )
}

export default Statistics
