import React from 'react';
import './Reviews.css';
import CarolDudley from '../../images/CarolD.jpg';
// import Luci from '../../images/Luci.jpg';
// import Raf from '../../images/Raf.jpg';

import { Swiper, SwiperSlide } from "swiper/react";
import "../MainOffer/MySwiper.css";
import 'swiper/css';

import SwiperCore, { Autoplay,Navigation,Pagination} from 'swiper';
SwiperCore.use([Autoplay,Navigation,Pagination]);

function Reviews() {
  return (
<>
    <Swiper
      spaceBetween={90}
      centeredSlides={true}
      speed={1500}
      autoplay={{
        "delay": 5000,
        "disableOnInteraction": false
      }}
      pagination={{
        "clickable": true
      }}
      //on/off slider buttons
      navigation={false}
      className="reviews"
    >

    <SwiperSlide className='reviews__slide'>
      <div className='reviews__info_block'>
        <p className='reviews__info_name'>
          Carol Dudley
        </p>
        <p className='reviews__info_caption'>
        President, CSA European Chapter Board Of Governors, Casting Society Of America
        </p>
        <p className='reviews__info_quote'>
          “We deal with actors who deserve a shot at international success but have neither agents nor ways of meeting them. They are not connected, not networked,
          CSA European Chapter Board of Governors President Carol Dudley said. “Even if everyone recognizes them on the street in their own country, they fly to the
          US and they can’t get an appointment with an agent or a casting director. I think things will progress slowly, but what you’re providing is filling an
          absolute hole that you’ve recognized in the marketplace, where you don’t have a conduit from the talent to the casting people, and the US in general,”
          she said of CastingForm.
        </p>
      </div>
      <img className='reviews__photo' src={CarolDudley} alt={CarolDudley}/>
    </SwiperSlide>

    {/* <SwiperSlide>
      <div className='reviews__info_block'>
        <p className='reviews__info_name'>
          Luci Lenox
        </p>
        <p className='reviews__info_caption reviews__info_caption_long '>
        International Casting Director, CSA, Vicky Christina Barcelona (Cannes 2008), Savage Grace (Cannes 2007), Amnesia (Cannes 2015)
        </p>
        <p className='reviews__info_quote'>
          ”I doubt that Artificial Intelligence will replace us - there’s so much about our industry that is about feelings and personal tastes. The most successful people in our industry are usually mavericks. And I don’t think they are going to
          suddenly want to just trust a computer. And also, even as casting directors, sometimes we think that somebody’s totally right, that we’ve done the algorithm.
          And then the director or showrunner doesn’t. It’s a very personal industry. And our job as casting directors is to understand exactly what the project and
          the director requires, and put people forward. And then there’s this magical moment - it’s like falling in love.”
        </p>
      </div>
      <img className='reviews__photo' src={Luci} alt={Luci}/>
    </SwiperSlide>

    <SwiperSlide>
      <div className='reviews__info_block'>
        <p className='reviews__info_name'>
          Raf Green
        </p>
        <p className='reviews__info_caption reviews__info_caption_long '>
          Emmy Award-nominated producer, writer, actor
        </p>
        <p className='reviews__info_quote'>
          ”I don't think that 10 years from now acting and storytelling as we know it is going to completely evaporate, but I do think it's going to transform.
          There will probably be a lot less movie theatres and some of the traditional things like broadcast TV are obviously fading fast, but I do think there'll
          still be a great appetite for acting and writing and directing. Television has been there for 70 years, and it hasn't changed that much. There are still
          30-minute sitcoms and one-hour dramas. And they were there even way back at the beginning so chances are, if it hasn't changed in 70 years, it's probably
          not going to completely disappear in the next 10 years. But I do think the acceleration of how it's delivered will slowly change what's delivered.”
        </p>
      </div>
      <img className='reviews__photo' src={Raf} alt={Raf}/>
    </SwiperSlide> */}

    </Swiper>
    <div className='reviews__bottom'></div>
    </>
  )
}

export default Reviews;
