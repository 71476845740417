import React from 'react'
import './Films.css'
import FilmsLogo from '../../images/films-logo.svg'

import { Swiper, SwiperSlide } from "swiper/react";
import "./FilmsSwiper.css";
import 'swiper/css';
import SwiperCore, { Autoplay,Navigation,Pagination} from 'swiper';

SwiperCore.use([Autoplay,Navigation,Pagination]);

function Films() {
  let width = window.innerWidth;
  return (
    <section className='films'>
      <div className='films__block'>
        <div className="films__print_small_top"/>
        {width > 1024 ? <div className="films__print_big_top"/> : null }
        <div className="films__header-container">
          <h2 className='films__heading'>Films created by our actors</h2>
          {width > 1024 ?
          <div className="films__buttons">
              <button type="button" className="films__button films__button_prev"/>
              <button type="button" className="films__button films__button_next"/>
          </div>
          : null}
        </div>


        <div className='films__content'>

        <Swiper
          spaceBetween={50}
          centeredSlides={true}
          loop={true}
          speed={1900}
          // autoplay={{
          //   "delay": 4500,
          //   "disableOnInteraction": false
          // }}
          pagination={{
            "clickable": true
          }}
          //on/off slider buttons
          navigation={
            {
              nextEl: '.films__button_next',
              prevEl: '.films__button_prev',
            }
          }
          className="mySwiper"
        >

          <SwiperSlide className='swiper-slide__films' style={{backgroundColor: 'transparent'}}>
            <div className='films__content_left'>
              <a className='films__content_link'
                 href="https://www.youtube.com/watch?v=-NB4f4RQycY&ab_channel=FilipeAmorim"
                >
                <div className='films__content_snapshot films__content_snapshot_1'>
                  <div className='films__content_snapshot_win'/>
                </div>
              </a>
              <div className='films__content_logo_block'>
              {width > 1024 ?<p className='films__content_logo_caption'>Distributed on</p> : null}
                <a href="https://watchargo.com/" className='films__content_link'>
                  <img className='films__content_logo_img' src={FilmsLogo} alt={FilmsLogo}/>
                </a>
              </div>
            </div>

            <div className='films__content_right'>
              <a className='films__content_link'
                 href="https://www.youtube.com/watch?v=-NB4f4RQycY&ab_channel=FilipeAmorim"
                >
              <h3 className='films__content_heading'>The White Pill</h3>
              </a>
              <p className='films__content_description'>After mixing up his vitamins, James embarks on a journey that takes him inside an adventurous Wild Life Documentary </p>
              <p className='films__content_placeholder'>Director</p>
              <p className='films__content_director-name'>Filipe Amorim, Manuel Pedro Gil</p>
              <p className='films__content_placeholder'>Actors</p>
              <ul className='films__content_actors-list'>
                <li className='films__content_actors-list_item'>Filipe Amorim</li>
                <li className='films__content_actors-list_item'>Diogo Fernandes</li>
                <li className='films__content_actors-list_item'>Sophia Ferreira</li>
              </ul>
              <div className='films__content_additionally'>
                <div className='films__content_additionally_countries'>
                  <p className='films__content_placeholder'>Countries of origin</p>
                  <p className='films__content_countries'>Portugal</p>
                </div>
                <div className='films__content_additionally_runtime'>
                  <p className='films__content_placeholder'>Runtime</p>
                  <p className='films__content_runtime'>4,5 min</p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='swiper-slide__films' style={{backgroundColor: 'transparent'}}>
            <div className='films__content_left'>
              <a className='films__content_link'
                 href="https://drive.google.com/file/d/1gxrhgZNMXcwaagjbGL-Ggt8CkdvEqZcH/view"
                >
                <div className='films__content_snapshot films__content_snapshot_6'/>
              </a>
              <div className='films__content_logo_block'>
              {width > 1024 ?<p className='films__content_logo_caption'>Distributed on</p> : null}
                <a href="https://watchargo.com/" className='films__content_logo_link'>
                  <img className='films__content_logo_img' src={FilmsLogo} alt={FilmsLogo}/>
                </a>
              </div>
            </div>

            <div className='films__content_right'>
              <a className='films__content_link'
                 href="https://drive.google.com/file/d/1gxrhgZNMXcwaagjbGL-Ggt8CkdvEqZcH/view"
                >
                <h3 className='films__content_heading'>Lifeless</h3>
              </a>
              <p className='films__content_description'>A tired young mother decides to bring her illusion of a life to an end on a bridge. While there, she encounters a beautiful blonde who wants to claim the bridge for herself.</p>
              <p className='films__content_placeholder'>Director</p>
              <p className='films__content_director-name'>Alex Fedosov</p>
              <p className='films__content_placeholder'>Actors</p>
              <ul className='films__content_actors-list'>
                <li className='films__content_actors-list_item'>Elvira Sinelnik</li>
                <li className='films__content_actors-list_item'>Ian Michaels</li>
                <li className='films__content_actors-list_item'>Erin Michele Soto</li>
              </ul>
              <div className='films__content_additionally'>
                <div className='films__content_additionally_countries'>
                  <p className='films__content_placeholder'>Countries of origin</p>
                  <p className='films__content_countries'>Russia, United States</p>
                </div>
                <div className='films__content_additionally_runtime'>
                  <p className='films__content_placeholder'>Runtime</p>
                  <p className='films__content_runtime'>15 min</p>
                </div>
              </div>
            </div>
          </SwiperSlide>



          <SwiperSlide className='swiper-slide__films' style={{backgroundColor: 'transparent'}}>
            <div className='films__content_left'>
              <a className='films__content_link'
                 href="https://drive.google.com/file/d/1QmdXSU0upOEVbINIu8BihEukXIeZEoCn/view"
                >
                <div className='films__content_snapshot films__content_snapshot_3'/>
              </a>
              <div className='films__content_logo_block'>
              {width > 1024 ?<p className='films__content_logo_caption'>Distributed on</p> : null}
                <a href="https://watchargo.com/" className='films__content_logo_link'>
                  <img className='films__content_logo_img' src={FilmsLogo} alt={FilmsLogo}/>
                </a>
              </div>
            </div>

            <div className='films__content_right'>
              <a className='films__content_link'
                 href="https://drive.google.com/file/d/1QmdXSU0upOEVbINIu8BihEukXIeZEoCn/view"
                >
                <h3 className='films__content_heading'>Dating Santa</h3>
              </a>
              <p className='films__content_description'>Becky’s online boyfriend is kind, caring and they talk to each other all the time. But how can she explain to her friends who he really is?</p>
              <p className='films__content_placeholder'>Director</p>
              <p className='films__content_director-name'>Jeanne Jaeger</p>
              <p className='films__content_placeholder'>Actors</p>
              <ul className='films__content_actors-list'>
                <li className='films__content_actors-list_item'>Jeanne Jaeger</li>
                <li className='films__content_actors-list_item'>JD Hunt</li>
                <li className='films__content_actors-list_item'>Tobias Diakow</li>
                <li className='films__content_actors-list_item'>Kelly Fonseca</li>
              </ul>
              <div className='films__content_additionally'>
                <div className='films__content_additionally_countries'>
                  <p className='films__content_placeholder'>Countries of origin</p>
                  <p className='films__content_countries'>Germany</p>
                </div>
                <div className='films__content_additionally_runtime'>
                  <p className='films__content_placeholder'>Runtime</p>
                  <p className='films__content_runtime'>6 min</p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='swiper-slide__films' style={{backgroundColor: 'transparent'}}>
            <div className='films__content_left'>
              <a className='films__content_link'
                 href="https://www.youtube.com/watch?v=LvEgSzlSgU4&ab_channel=EvaniEvani"
                >
                <div className='films__content_snapshot films__content_snapshot_4'/>
              </a>
              <div className='films__content_logo_block'>
              {width > 1024 ?<p className='films__content_logo_caption'>Distributed on</p> : null}
                <a href="https://watchargo.com/" className='films__content_logo_link'>
                  <img className='films__content_logo_img' src={FilmsLogo} alt={FilmsLogo}/>
                </a>
              </div>
            </div>

            <div className='films__content_right'>
              <a className='films__content_link'
                 href="https://www.youtube.com/watch?v=LvEgSzlSgU4&ab_channel=EvaniEvani"
                >
                <h3 className='films__content_heading'>Twice life</h3>
              </a>
              <p className='films__content_description'>Nobody knows what brought her on the street to become homeless.</p>
              <p className='films__content_placeholder'>Director</p>
              <p className='films__content_director-name'>Angelina Dyatlova, Aleksandra Satko</p>
              <p className='films__content_placeholder'>Actors</p>
              <ul className='films__content_actors-list'>
                {/* <li className='films__content_actors-list_item'>Evani-Ivanna Keresteshiy</li> */}
                <li className='films__content_actors-list_item'>Vitalii Husak</li>
                <li className='films__content_actors-list_item'>Veronika Dovhalova</li>
                <li className='films__content_actors-list_item'>Igor Parkhomenko</li>
              </ul>
              <div className='films__content_additionally'>
                <div className='films__content_additionally_countries'>
                  <p className='films__content_placeholder'>Countries of origin</p>
                  <p className='films__content_countries'>Ukraine</p>
                </div>
                <div className='films__content_additionally_runtime'>
                  <p className='films__content_placeholder'>Runtime</p>
                  <p className='films__content_runtime'>5 min</p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='swiper-slide__films' style={{backgroundColor: 'transparent'}}>
            <div className='films__content_left'>
              <a className='films__content_link'
                 href="https://drive.google.com/file/d/1bsbRfHwSSJRvuDsIM7_PzNzB53mlUtLZ/view"
                >
                <div className='films__content_snapshot films__content_snapshot_5'/>
              </a>
              <div className='films__content_logo_block'>
              {width > 1024 ?<p className='films__content_logo_caption'>Distributed on</p> : null}
                <a href="https://watchargo.com/" className='films__content_logo_link'>
                  <img className='films__content_logo_img' src={FilmsLogo} alt={FilmsLogo}/>
                </a>
              </div>
            </div>

            <div className='films__content_right'>
              <a className='films__content_link'
                 href="https://drive.google.com/file/d/1bsbRfHwSSJRvuDsIM7_PzNzB53mlUtLZ/view"
                >
                <h3 className='films__content_heading'>Circumstances</h3>
              </a>
              <p className='films__content_description'>One day in the life of a company executive in China during the COVID19 with a surprising twist due to unpredictable circumstances.</p>
              <p className='films__content_placeholder'>Director</p>
              <p className='films__content_director-name'>Tony Barros</p>
              <p className='films__content_placeholder'>Actors</p>
              <ul className='films__content_actors-list'>
                <li className='films__content_actors-list_item'>José Reis</li>
                <li className='films__content_actors-list_item'>Joana San Jose</li>
                <li className='films__content_actors-list_item'>Mauro Nunes</li>
                <li className='films__content_actors-list_item'>Sofia Salgado</li>
              </ul>
              <div className='films__content_additionally'>
                <div className='films__content_additionally_countries'>
                  <p className='films__content_placeholder'>Countries of origin</p>
                  <p className='films__content_countries'>China</p>
                </div>
                <div className='films__content_additionally_runtime'>
                  <p className='films__content_placeholder'>Runtime</p>
                  <p className='films__content_runtime'>5 min</p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {width > 1024 ?
          <>
          <SwiperSlide className='swiper-slide__films' style={{backgroundColor: 'transparent'}}>
            <div className='films__content_left'>
              <a className='films__content_link'
                 href="https://drive.google.com/drive/folders/1YqG8QQhg9nUbEOSfhXnj86736sbMvx6h?fbclid=IwAR2yyrpioyfmDRjUicb-V8gIG87YTlIX96GRuqyKOP-Qa_hAPMS002SlUrY"
                >
                <div className='films__content_snapshot films__content_snapshot_2'/>
              </a>
              <div className='films__content_logo_block'>
                <p className='films__content_logo_caption'>Distributed on</p>
                <a href="https://watchargo.com/" className='films__content_logo_link'>
                  <img className='films__content_logo_img' src={FilmsLogo} alt={FilmsLogo}/>
                </a>
              </div>
            </div>

            <div className='films__content_right'>
              <a className='films__content_link'
                 href="https://drive.google.com/drive/folders/1YqG8QQhg9nUbEOSfhXnj86736sbMvx6h?fbclid=IwAR2yyrpioyfmDRjUicb-V8gIG87YTlIX96GRuqyKOP-Qa_hAPMS002SlUrY"
                >
              <h3 className='films__content_heading'>Immigrant Holdem</h3>
              </a>
              <p className='films__content_description'>Russian FSB agent arrives to USA to meet CIA agents to exchange national secret files for US passport. </p>
              <p className='films__content_placeholder'>Director</p>
              <p className='films__content_director-name'>Slava Babenkov</p>
              <p className='films__content_placeholder'>Actors</p>
              <ul className='films__content_actors-list'>
                <li className='films__content_actors-list_item'>Slava Babenkov</li>
                <li className='films__content_actors-list_item'>Oleg Karpenko</li>
                {/* <li className='films__content_actors-list_item'>Rob Feldman</li> */}
                <li className='films__content_actors-list_item'>Olga Safronova</li>
              </ul>
              <div className='films__content_additionally'>
                <div className='films__content_additionally_countries'>
                  <p className='films__content_placeholder'>Countries of origin</p>
                  <p className='films__content_countries'>Ukraine</p>
                </div>
                <div className='films__content_additionally_runtime'>
                  <p className='films__content_placeholder'>Runtime</p>
                  <p className='films__content_runtime'>15 min</p>
                </div>
              </div>
          </div>
          </SwiperSlide>

          <SwiperSlide className='swiper-slide__films' style={{backgroundColor: 'transparent'}}>
            <div className='films__content_left'>
              <a className='films__content_link'
                 href="https://drive.google.com/file/d/16YaEPINvmHA0cdWRIu9D8JU9g2R6wS6f/view"
                >
                <div className='films__content_snapshot films__content_snapshot_7'/>
              </a>
              <div className='films__content_logo_block'>
                <p className='films__content_logo_caption'>Distributed on</p>
                <a href="https://watchargo.com/" className='films__content_logo_link'>
                  <img className='films__content_logo_img' src={FilmsLogo} alt={FilmsLogo}/>
                </a>
              </div>
            </div>

            <div className='films__content_right'>
              <a className='films__content_link'
                 href="https://drive.google.com/file/d/16YaEPINvmHA0cdWRIu9D8JU9g2R6wS6f/view"
                >
                <h3 className='films__content_heading'>Mises en Abime</h3>
              </a>
              <p className='films__content_description'>A team of friends is chilling and talking on their new year resolutions for 2020, that's never gonna happen.</p>
              <p className='films__content_placeholder'>Director</p>
              <p className='films__content_director-name'>Yohann Chopin</p>
              <p className='films__content_placeholder'>Actors</p>
              <ul className='films__content_actors-list'>
                <li className='films__content_actors-list_item'>Yohann Chopin</li>
                <li className='films__content_actors-list_item'>Ramzi Abdul-Khalek</li>
                <li className='films__content_actors-list_item'>Magui Khazaal</li>
              </ul>
              <div className='films__content_additionally'>
                <div className='films__content_additionally_countries'>
                  <p className='films__content_placeholder'>Countries of origin</p>
                  <p className='films__content_countries'>France</p>
                </div>
                <div className='films__content_additionally_runtime'>
                  <p className='films__content_placeholder'>Runtime</p>
                  <p className='films__content_runtime'>4 min</p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='swiper-slide__films' style={{backgroundColor: 'transparent'}}>
            <div className='films__content_left'>
              <a className='films__content_link'
                 href="https://vimeo.com/243548356?fbclid=IwAR3olDh_x6RaT5x_-21Vodmp-5ndFGt2BpO8pD2kv24lZQYnvBEB5qpyvRk"
                >
                <div className='films__content_snapshot films__content_snapshot_8'/>
              </a>
              <div className='films__content_logo_block'>
                <p className='films__content_logo_caption'>Distributed on</p>
                <a href="https://watchargo.com/" className='films__content_logo_link'>
                  <img className='films__content_logo_img' src={FilmsLogo} alt={FilmsLogo}/>
                </a>
              </div>
            </div>

            <div className='films__content_right'>
              <a className='films__content_link'
                 href="https://vimeo.com/243548356?fbclid=IwAR3olDh_x6RaT5x_-21Vodmp-5ndFGt2BpO8pD2kv24lZQYnvBEB5qpyvRk"
                >
                <h3 className='films__content_heading'>Nail it (Gvozd)</h3>
              </a>
              <p className='films__content_description'>A dark comedy short explaining why it is not a good idea to disturb your neighbour even if it’s a nice granny.</p>
              <p className='films__content_placeholder'>Director</p>
              <p className='films__content_director-name'>Railya Karimova</p>
              <p className='films__content_placeholder'>Actors</p>
              <ul className='films__content_actors-list'>
                <li className='films__content_actors-list_item'>Lyudmila Ershova</li>
                <li className='films__content_actors-list_item'>Maksim Lagashkin</li>
                <li className='films__content_actors-list_item'>Liliya Egorova</li>
              </ul>
              <div className='films__content_additionally'>
                <div className='films__content_additionally_countries'>
                  <p className='films__content_placeholder'>Countries of origin</p>
                  <p className='films__content_countries'>Russia</p>
                </div>
                <div className='films__content_additionally_runtime'>
                  <p className='films__content_placeholder'>Runtime</p>
                  <p className='films__content_runtime'>2 min</p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide className='swiper-slide__films' style={{backgroundColor: 'transparent'}}>
            <div className='films__content_left'>
              <a className='films__content_link'
                 href="https://drive.google.com/file/d/11_ZRdwwUkaC7vvUp4rG0180CXl7h_rbc/view"
                >
                <div className='films__content_snapshot films__content_snapshot_9'/>
              </a>
              <div className='films__content_logo_block'>
                <p className='films__content_logo_caption'>Distributed on</p>
                <a href="https://watchargo.com/" className='films__content_logo_link'>
                  <img className='films__content_logo_img' src={FilmsLogo} alt={FilmsLogo}/>
                </a>
              </div>
            </div>

            <div className='films__content_right'>
              <a className='films__content_link'
                 href="https://drive.google.com/file/d/11_ZRdwwUkaC7vvUp4rG0180CXl7h_rbc/view"
                >
                <h3 className='films__content_heading'>Unknown art</h3>
              </a>
              <p className='films__content_description'>Art is her life, she is looking for a way to express herself in design, becoming a true artist.</p>
              <p className='films__content_placeholder'>Director</p>
              <p className='films__content_director-name'>Alena Shkuratava</p>
              <p className='films__content_placeholder'>Actors</p>
              <ul className='films__content_actors-list'>
                <li className='films__content_actors-list_item'>Oxana Masharo</li>
              </ul>
              <div className='films__content_additionally'>
                <div className='films__content_additionally_countries'>
                  <p className='films__content_placeholder'>Countries of origin</p>
                  <p className='films__content_countries'>Belarus</p>
                </div>
                <div className='films__content_additionally_runtime'>
                  <p className='films__content_placeholder'>Runtime</p>
                  <p className='films__content_runtime'>4,5 min</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          </> : null }

          </Swiper>
        </div>
        {width > 0 ? <div className="films__print_big_bottom"/> : null}
      </div>
    </section>
  )
}

export default Films
