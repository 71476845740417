/* eslint-disable no-sequences */
import React from 'react';
import { useLocation } from 'react-router-dom';
import jasmine from '../../images/castDir/director_jasmine.jpg';
import zachary from '../../images/castDir/director_zachary.jpg';
import marina from '../../images/castDir/director_marina.jpg';

import './Actors.css';

import { Swiper, SwiperSlide } from "swiper/react";
import '../MainOffer/MySwiper.css';
import 'swiper/css';
import SwiperCore, { Navigation, Autoplay, Scrollbar } from 'swiper';

SwiperCore.use([Navigation, Autoplay, Scrollbar]);

const Actors = () => {
  const location = useLocation();
  let width = window.innerWidth;

  return (
    <section className={`actors ${location.pathname === '/castingdirector' && "actors_bg_blue"}`}>
      <div className="actors__container">
        <div className="actors__heading">
          <h2 className={location.pathname === '/castingdirector' ? "actors__title_casting" : "actors__title"}>{location.pathname === '/castingdirector' ? "Our actors" : "Some of our actors"}</h2>
          {width > 1024 ?
          <div className="actors__buttons">
            <button type="button" className="slider__button slider__button_prev"/>
            <button type="button" className="slider__button slider__button_next"/>
          </div>
          : null }
        </div>

        <Swiper
          style={{"paddingTop":"60px"}}
          slidesPerView={width > 1024 ? 3 : 1}
          spaceBetween={width > 1024 ? 15 : 30}
          centeredSlides={true}
          slidesPerGroup={1}
          loop={true}
          autoplay={{
            "delay": 2000,
            "disableOnInteraction": false,
          }}
          speed={900}
          navigation={
            {
              nextEl: '.slider__button_next',
              prevEl: '.slider__button_prev',
            }
          }
        >
            <SwiperSlide className='swiper-slide_actors' style={{backgroundColor: 'transparent'}}>
              <img src={marina} alt="app-illustration" className="actors__image"/>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide_actors' style={{backgroundColor: 'transparent'}}>
              <img src={jasmine} alt="app-illustration" className="actors__image"/>
            </SwiperSlide>
            <SwiperSlide className='swiper-slide_actors' style={{backgroundColor: 'transparent'}}>
              <img src={zachary} alt="app-illustration" className="actors__image"/>
            </SwiperSlide>

        </Swiper>

        {location.pathname !== '/castingdirector' && <div style={{"margin": "0 auto"}}>
          <a  className="actors__button actors__button_type_join" href='https://whatis.castingform.com/auth/sign-up/email'>Join</a>
        </div>}
      </div>

    </section>
  )
}

export default Actors;
