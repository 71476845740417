import React, { useState } from 'react';

import './FaqListItem.css';


const FaqListItem = ({ question, answer, paragraph, children}) => {
  const [isOpened, setIsOpened] = useState(false);

  const handleClick = () => {
    setIsOpened(!isOpened);
  }

  return (
    <div className="list-item">
      <div className="list-item__question-container" onClick={handleClick}>
        <p className="list-item__question"  >{question}</p>
        <span className={`list-item__span ${isOpened && "list-item__span_opened"}`}/>
      </div>
      <div className={`list-item__answer-container ${isOpened && "list-item__answer-container_opened"}`}>
        <p className="list-item__answer">
          {answer}
        </p>
        <p className="list-item__answer">
          {paragraph}
        </p>
        {children}
      </div>
    </div>
  )
}

export default FaqListItem;
