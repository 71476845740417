import photoBlack from '../../images/photo-ok.png';
import photoUnderExpose from '../../images/photo-underexp.png';
import photoOverexpose from '../../images/photo-overexpose.png';
import photoShadowed from '../../images/photo-shadowed.png';
import photoBlurry from '../../images/photo-burry.png';
import photoHand from '../../images/photo-hand.png';
import photoBulky from '../../images/photo-burky.png';
import photoHat from '../../images/photo-hat.png';
import photoSun from '../../images/photo-sunglasses.png';


export const photos = [
  {
    link: photoUnderExpose,
    text: 'Underexpose'
  },

  {
    link: photoOverexpose,
    text: 'Overexpose'
  },
  {
    link: photoShadowed,
    text: 'Shadowed'
  },
  {
    link: photoBlack,
    text: 'Black&White'
  },
  {
    link: photoBlurry,
    text: 'Blurry'
  },
  {
    link: photoHand,
    text: 'Hand cover'
  },
  {
    link: photoBulky,
    text: 'Bulky wear'
  },
  {
    link: photoHat,
    text: 'Hats'
  },
  {
    link: photoSun,
    text: 'Sunglasses'
  }
]
